export function throwIfNullish<T>(
  value: T | null | undefined,
  message?: string,
): T {
  if (value == null) {
    throw new Error(message ?? 'Value was unexpectedly null or undefined');
  }
  return value;
}

export function assertNonNullish<T>(
  value: T,
  message?: string,
): asserts value is NonNullable<T> {
  if (value == null) {
    throw new Error(message ?? 'Value was unexpectedly null or undefined');
  }
}

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export function assertUnreachable(
  unhandledValue: never,
  throwException = false,
) {
  const errorStr = `Encountered unhandled value: ${unhandledValue}`;
  if (throwException) {
    throw new Error(errorStr);
  } else {
    console.error(errorStr);
  }
}

export function unknownHasProperty<TProperty extends string>(
  u: unknown,
  property: TProperty,
): u is { [K in TProperty]: unknown } {
  if (u == null) return false;
  if (typeof u !== 'object') return false;
  return Object.hasOwn(u, property);
}

export type MakeNonNullishFieldsNullable<T> = {
  [K in keyof T]: T[K] | null;
};

export function isEnumValue<T extends { [key: string]: string }>(
  enumObj: T,
  value: string,
): value is T[keyof T] {
  return Object.values(enumObj).includes(value as T[keyof T]);
}

export function assertEnumValue<T extends { [key: string]: string }>(
  enumObj: T,
  value: string,
): asserts value is T[keyof T] {
  if (!isEnumValue(enumObj, value)) {
    throw new Error(`Invalid enum value: ${value}`);
  }
}
