import { getBillingInfoFromItem } from '@lupa/utils/billingUtils';
import { DISCOUNT_TYPE } from '@lupa/utils/enums';
import {
  RemainingAllowanceQuantityDataType,
  calculateAllowanceDiscount,
} from '@lupa/utils/healthplanUtils';
import { TrpcRouterOutputs } from '@lupa/work/lib/trpc';

import { UseFormReturn } from 'react-hook-form';
import { match } from 'ts-pattern';

import type { UpsertBillingItemsFormType } from './UpsertBillingItemsForm';

type BillingItemsFormType = UseFormReturn<UpsertBillingItemsFormType>;

const billingItemsDataUtils = {
  onServiceQuantityChange: ({
    form,
    serviceIdx,
    newQuantity,
  }: {
    form: BillingItemsFormType;
    serviceIdx: number;
    newQuantity: number | null;
  }) => {
    form.setValue(`services.${serviceIdx}.quantity`, newQuantity ?? 0);

    billingItemsDataUtils.onServiceChange({
      form,
      serviceIdx,
    });
  },

  onServiceDiscountChange: ({
    form,
    serviceIdx,
    newDiscount,
  }: {
    form: BillingItemsFormType;
    serviceIdx: number;
    newDiscount: {
      amount: number | null;
      option: DISCOUNT_TYPE | undefined;
    };
  }) => {
    form.setValue(`services.${serviceIdx}.discount`, newDiscount.amount ?? 0);
    form.setValue(
      `services.${serviceIdx}.discount_type`,
      newDiscount.option ?? DISCOUNT_TYPE.PERCENTAGE,
    );

    billingItemsDataUtils.onServiceChange({
      form,
      serviceIdx,
    });
  },

  onProductQuantityChange: ({
    form,
    productIdx,
    newMeasureUnit,
    newQuantity,
  }: {
    form: BillingItemsFormType;
    productIdx: number;
    newMeasureUnit: string | undefined;
    newQuantity: number | null;
  }) => {
    // Check if the measure unit has changed, if so update the relevant fields
    if (
      newMeasureUnit != null &&
      newMeasureUnit !== form.getValues(`products.${productIdx}.measure_unit`)
    ) {
      // Update unit price based on measure unit - if subunit, multiply, if unit, divide
      const multiplier =
        form.getValues(`products.${productIdx}.subunit_multiplier`) ?? 1;
      const currentUnit = form.getValues(`products.${productIdx}.unit`);
      const currentUnitPrice = form.getValues(
        `products.${productIdx}.unit_price`,
      );

      const newUnitPrice =
        newMeasureUnit === currentUnit
          ? currentUnitPrice * multiplier
          : currentUnitPrice / multiplier;

      form.setValue(`products.${productIdx}.unit_price`, newUnitPrice);
      form.setValue(`products.${productIdx}.measure_unit`, newMeasureUnit);
    }

    form.setValue(`products.${productIdx}.quantity`, newQuantity ?? 0);

    billingItemsDataUtils.onProductChange({
      form,
      productIdx,
    });
  },

  onProductDiscountChange: ({
    form,
    productIdx,
    newDiscount,
  }: {
    form: BillingItemsFormType;
    productIdx: number;
    newDiscount: {
      amount: number | null;
      option: DISCOUNT_TYPE | undefined;
    };
  }) => {
    form.setValue(`products.${productIdx}.discount`, newDiscount.amount ?? 0);
    form.setValue(
      `products.${productIdx}.discount_type`,
      newDiscount.option ?? DISCOUNT_TYPE.PERCENTAGE,
    );

    const billingInfo = getBillingInfoFromItem({
      ...form.getValues(`products.${productIdx}`),
    });

    form.setValue(`products.${productIdx}.price`, billingInfo.itemTotalPrice);
  },

  onProductBatchQuantityChange: ({
    form,
    productIdx,
    batchIdx,
    newMeasureUnit,
    newQuantity,
  }: {
    form: BillingItemsFormType;
    productIdx: number;
    batchIdx: number;
    newMeasureUnit: string | undefined;
    newQuantity: number | null;
  }) => {
    // The measure unit is saved at the product level, not at the batch level, but
    // it can still be changed from the batch row for the whole product.
    // Check if the measure unit has changed, if so update the relevant fields
    if (
      newMeasureUnit != null &&
      newMeasureUnit !== form.getValues(`products.${productIdx}.measure_unit`)
    ) {
      // Update unit price based on measure unit - if subunit, multiply, if unit, divide
      const multiplier =
        form.getValues(`products.${productIdx}.subunit_multiplier`) ?? 1;
      const currentUnit = form.getValues(`products.${productIdx}.unit`);
      const currentUnitPrice = form.getValues(
        `products.${productIdx}.unit_price`,
      );

      const newUnitPrice =
        newMeasureUnit === currentUnit
          ? currentUnitPrice * multiplier
          : currentUnitPrice / multiplier;

      form.setValue(`products.${productIdx}.unit_price`, newUnitPrice);
      form.setValue(`products.${productIdx}.measure_unit`, newMeasureUnit);
    }

    form.setValue(
      `products.${productIdx}.batches.${batchIdx}.quantity`,
      newQuantity ?? 0,
    );

    const totalProductQuantity =
      form
        .getValues(`products.${productIdx}.batches`)
        ?.reduce((acc, batch) => acc + batch.quantity, 0) ?? 0;

    form.setValue(`products.${productIdx}.quantity`, totalProductQuantity);

    billingItemsDataUtils.onProductChange({
      form,
      productIdx,
    });
  },

  onProductAddBatch: ({
    form,
    productIdx,
    productId,
  }: {
    form: BillingItemsFormType;
    productIdx: number;
    productId: string;
  }) => {
    const currentBatches =
      form.getValues(`products.${productIdx}.batches`) ?? [];

    form.setValue(`products.${productIdx}.batches`, [
      ...currentBatches,
      {
        product_id: productId,
        batch_number: '',
        expiry_date: '',
        quantity: 1,
      },
    ]);
  },

  onProductBatchSelected: ({
    form,
    productIdx,
    batchIdx,
    productId,
    item,
  }: {
    form: BillingItemsFormType;
    productIdx: number;
    batchIdx: number;
    productId: Nullish<string>;
    item: NonNullable<
      TrpcRouterOutputs['products']['searchProductBatches']
    >[number];
  }) => {
    form.setValue(`products.${productIdx}.batches.${batchIdx}`, {
      batch_id: item?.id,
      product_id: productId ?? '',
      batch_number: item?.batch_number,
      expiry_date: item?.expiry_date,
      quantity: 1,
    });
  },

  onProductRemoveBatch: ({
    form,
    productIdx,
    batchIdx,
  }: {
    form: BillingItemsFormType;
    productIdx: number;
    batchIdx: number;
  }) => {
    const currentBatches =
      form.getValues(`products.${productIdx}.batches`) ?? [];

    form.setValue(
      `products.${productIdx}.batches`,
      currentBatches.filter((_, i) => i !== batchIdx),
    );
  },

  onItemAllowanceSelected: ({
    form,
    identifier,
    newAllowance,
  }: {
    form: BillingItemsFormType;
    identifier: {
      type: 'service' | 'product';
      idx: number;
    };
    newAllowance: RemainingAllowanceQuantityDataType;
  }) => {
    const formAllowance = {
      id: crypto.randomUUID(),
      allowance_id: newAllowance.allowance_id,
      subscription_id: newAllowance.subscription_id,
      quantity: 0,
      allowance: {
        item: newAllowance.item,
        item_type: newAllowance.item_type,
        config: newAllowance.config,
        service: newAllowance.service,
        product: newAllowance.product,
      },
    };

    if (identifier.type === 'service') {
      const discountUpdate = calculateAllowanceDiscount(
        form.getValues(`services.${identifier.idx}`),
        formAllowance,
      );

      form.setValue(
        `services.${identifier.idx}.allowance_usage`,
        discountUpdate.allowance_usage,
      );
      form.setValue(
        `services.${identifier.idx}.discount`,
        discountUpdate.discount,
      );
      form.setValue(
        `services.${identifier.idx}.discount_type`,
        discountUpdate.discount_type,
      );
      form.setValue(`services.${identifier.idx}.allowance_usage`, [
        formAllowance,
      ]);

      billingItemsDataUtils.onServiceChange({
        form,
        serviceIdx: identifier.idx,
      });
    }

    if (identifier.type === 'product') {
      const discountUpdate = calculateAllowanceDiscount(
        form.getValues(`products.${identifier.idx}`),
        formAllowance,
      );

      form.setValue(
        `products.${identifier.idx}.allowance_usage`,
        discountUpdate.allowance_usage,
      );
      form.setValue(
        `products.${identifier.idx}.discount`,
        discountUpdate.discount,
      );
      form.setValue(
        `products.${identifier.idx}.discount_type`,
        discountUpdate.discount_type,
      );
      form.setValue(`products.${identifier.idx}.allowance_usage`, [
        formAllowance,
      ]);

      billingItemsDataUtils.onProductChange({
        form,
        productIdx: identifier.idx,
      });
    }
  },

  onItemAllowanceRemoved: ({
    form,
    identifier,
  }: {
    form: BillingItemsFormType;
    identifier: {
      type: 'service' | 'product';
      idx: number;
    };
  }) => {
    if (identifier.type === 'service') {
      form.setValue(`services.${identifier.idx}.allowance_usage`, []);
      form.setValue(`services.${identifier.idx}.discount`, 0);

      billingItemsDataUtils.onServiceChange({
        form,
        serviceIdx: identifier.idx,
      });
    }

    if (identifier.type === 'product') {
      form.setValue(`products.${identifier.idx}.allowance_usage`, []);
      form.setValue(`products.${identifier.idx}.discount`, 0);

      billingItemsDataUtils.onProductChange({
        form,
        productIdx: identifier.idx,
      });
    }
  },

  calculateItemAllowanceLimit: ({
    form,
    identifier,
    allowancesOptions,
    allowanceId,
  }: {
    form: BillingItemsFormType;
    identifier: {
      type: 'service' | 'product';
      idx: number;
    };
    allowancesOptions: RemainingAllowanceQuantityDataType[];
    allowanceId: string;
  }): number => {
    const allowance = allowancesOptions.find(
      (o) => o.allowance_id === allowanceId,
    );

    if (allowance == null) {
      return 0;
    }

    const items = match(identifier)
      .with({ type: 'service' }, () => form.getValues('services'))
      .with({ type: 'product' }, () => form.getValues('products'))
      .exhaustive();

    if (items == null) {
      return allowance.remainingQuantity;
    }

    const allowanceUsageByItems = items
      .filter((_, idx) => idx !== identifier.idx)
      .reduce((acc, item) => {
        const allowanceUsage = item.allowance_usage?.find(
          (a) => a.allowance_id === allowanceId,
        );
        return acc + (allowanceUsage?.quantity ?? 0);
      }, 0);

    return allowance.remainingQuantity - allowanceUsageByItems;
  },

  onItemAllowanceQuantityChange: ({
    form,
    identifier,
    newQuantity,
  }: {
    form: BillingItemsFormType;
    identifier: {
      type: 'service' | 'product';
      idx: number;
    };
    newQuantity: number;
  }) => {
    const currentAllowance = match(identifier)
      .with({ type: 'service' }, () =>
        form.getValues(`services.${identifier.idx}.allowance_usage`),
      )
      .with({ type: 'product' }, () =>
        form.getValues(`products.${identifier.idx}.allowance_usage`),
      )
      .exhaustive();

    if (!currentAllowance || !currentAllowance[0]) {
      return;
    }

    const newAllowance = {
      ...currentAllowance[0],
      quantity: newQuantity,
    };

    if (identifier.type === 'service') {
      const discountUpdate = calculateAllowanceDiscount(
        form.getValues(`services.${identifier.idx}`),
        newAllowance,
      );

      form.setValue(
        `services.${identifier.idx}.allowance_usage`,
        discountUpdate.allowance_usage,
      );
      form.setValue(
        `services.${identifier.idx}.discount`,
        discountUpdate.discount,
      );
      form.setValue(
        `services.${identifier.idx}.discount_type`,
        discountUpdate.discount_type,
      );
      form.setValue(`services.${identifier.idx}.allowance_usage`, [
        newAllowance,
      ]);

      billingItemsDataUtils.onServiceChange({
        form,
        serviceIdx: identifier.idx,
      });
    }

    if (identifier.type === 'product') {
      const discountUpdate = calculateAllowanceDiscount(
        form.getValues(`products.${identifier.idx}`),
        newAllowance,
      );

      form.setValue(
        `products.${identifier.idx}.allowance_usage`,
        discountUpdate.allowance_usage,
      );
      form.setValue(
        `products.${identifier.idx}.discount`,
        discountUpdate.discount,
      );
      form.setValue(
        `products.${identifier.idx}.discount_type`,
        discountUpdate.discount_type,
      );
      form.setValue(`products.${identifier.idx}.allowance_usage`, [
        newAllowance,
      ]);

      billingItemsDataUtils.onProductChange({
        form,
        productIdx: identifier.idx,
      });
    }
  },

  onServiceChange: ({
    form,
    serviceIdx,
  }: {
    form: BillingItemsFormType;
    serviceIdx: number;
  }) => {
    const billingInfo = getBillingInfoFromItem({
      ...form.getValues(`services.${serviceIdx}`),
    });

    form.setValue(`services.${serviceIdx}.price`, billingInfo.itemTotalPrice);
  },

  onProductChange: ({
    form,
    productIdx,
  }: {
    form: BillingItemsFormType;
    productIdx: number;
  }) => {
    const billingInfo = getBillingInfoFromItem({
      ...form.getValues(`products.${productIdx}`),
    });

    form.setValue(`products.${productIdx}.price`, billingInfo.itemTotalPrice);
  },
};

export default billingItemsDataUtils;
