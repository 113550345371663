import { useState } from 'react';

import DateInput from '@lupa/ui/components/form/DateInput';
import TextInput from '@lupa/ui/components/form/TextInput';
import { Button } from '@lupa/ui/components/shadcn/button';
import { FormField } from '@lupa/ui/components/shadcn/form';
import { clearErrorAndSetValue } from '@lupa/ui/utils/formikUtils';
import { PET_SEX, PET_SPECIES } from '@lupa/utils/enums';
import { UpsertAction } from '@lupa/utils/types/common.types';
import { createAppointmentValidatorData } from '@lupa/utils/validators/appointmentValidators';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';

import { IconNotesOff } from '@tabler/icons-react';

import { UseFormReturn } from 'react-hook-form';

import SearchEmployees from '../../search/SearchEmployees';
import SearchPets from '../../search/SearchPets';
import SearchRooms from '../../search/SearchRooms';
import SearchVisitTypes from '../../search/SearchVisitTypes';
import type { NewAppointmentCreateType } from './NewAppointmentCreate';

const createAppointmentSchema = createAppointmentValidatorData.innerType();

type NewAppointmentUpsertDetailsSectionProps = {
  form: UseFormReturn<NewAppointmentCreateType>;
  action: UpsertAction;
  setActiveStep: (step: number) => void;
};

export default function NewAppointmentUpsertDetailsSection({
  form,
  action,
  setActiveStep,
}: NewAppointmentUpsertDetailsSectionProps) {
  const [shouldCreatePet, setShouldCreatePet] = useState(false);
  const clientNotes = form.getValues('appointment.client_notes');

  return (
    <div className='flex flex-col gap-4'>
      {action === 'update' && (
        <div className='flex w-full flex-col items-start rounded-lg border border-white/30 p-2'>
          <h6 className='mb-2 text-white'>Client Booking Notes</h6>

          {clientNotes ? (
            <p className='text-sm text-white/80'>{clientNotes}</p>
          ) : (
            <div className='flex flex-row items-center gap-1 text-sm text-white/80'>
              <IconNotesOff stroke={1.5} />
              <p>No Notes</p>
            </div>
          )}
        </div>
      )}

      <div className='space-y-4 rounded-md bg-white p-4'>
        <FormField
          control={form.control}
          name='pet'
          render={({ field: { ref: _ref, ...fieldProps } }) => {
            return (
              <SearchPets
                {...fieldProps}
                value={
                  fieldProps.value
                    ? {
                        id: fieldProps.value.id,
                        // TODO: Remove empty string once we move to new UI
                        label: fieldProps.value.name ?? '',
                      }
                    : null
                }
                onChange={(v) => {
                  clearErrorAndSetValue(form, 'pet', {
                    id: v.id,
                    name: v.label,
                  });
                  clearErrorAndSetValue(form, 'client', v.owner[0].client);
                }}
                disabled={action === 'update' || shouldCreatePet}
                label='Pet'
                required={!createAppointmentSchema.shape.pet.isNullable()}
                error={form.formState.errors.pet?.message}
                extraButton={
                  action === 'create'
                    ? {
                        title: 'Add new pet',
                        props: {
                          onClick: () => {
                            form.setValue('newPet', {
                              name: '',
                              species: PET_SPECIES.DOG,
                              breed: '',
                              sex: PET_SEX.UNKNOWN,
                              weight: null,
                              dob: null,
                            });
                            form.setValue('pet', null);
                            setShouldCreatePet(true);
                          },
                        },
                      }
                    : undefined
                }
              />
            );
          }}
        />

        <FormField
          control={form.control}
          name='appointment.visit_type'
          render={({ field: { ref: _ref, ...fieldProps } }) => {
            return (
              <SearchVisitTypes
                {...fieldProps}
                value={
                  fieldProps.value?.id
                    ? {
                        id: fieldProps.value.id,
                        label: fieldProps.value.name,
                      }
                    : null
                }
                onChange={(v) => {
                  clearErrorAndSetValue(form, 'appointment.visit_type', {
                    id: v.id,
                    name: v.label,
                    category: v.category,
                  });
                }}
                onOptionSelected={(v) => {
                  form.setValue('appointment.title', v.label);
                }}
                label='Appointment Type'
                required={true}
                error={form.formState.errors.appointment?.visit_type?.message}
              />
            );
          }}
        />

        <FormField
          control={form.control}
          name='employee'
          render={({ field: { ref: _ref, ...fieldProps } }) => {
            return (
              <SearchEmployees
                {...fieldProps}
                value={
                  fieldProps.value
                    ? {
                        id: fieldProps.value.id,
                        // TODO: Remove empty string once we move to new UI
                        label: fieldProps.value.full_name ?? '',
                      }
                    : null
                }
                onChange={(v) => {
                  clearErrorAndSetValue(form, 'employee', {
                    id: v.id,
                    full_name: v.label,
                  });
                }}
                label='Employee'
                required={true}
                error={form.formState.errors.employee?.message}
              />
            );
          }}
        />

        <FormField
          control={form.control}
          name='appointment.title'
          render={({ field: { ref: _ref, ...fieldProps } }) => (
            <TextInput
              {...fieldProps}
              label='Title'
              required={true}
              error={form.formState.errors.appointment?.title?.message}
            />
          )}
        />

        <FormField
          control={form.control}
          name='appointment.location'
          render={({ field: { ref: _ref, ...fieldProps } }) => (
            <TextInput
              {...fieldProps}
              label='Location'
              required={false}
              error={form.formState.errors.appointment?.location?.message}
            />
          )}
        />

        {globalSingleton.isTestUser && (
          <FormField
            control={form.control}
            name='room'
            render={({ field: { ref: _ref, ...fieldProps } }) => {
              return (
                <SearchRooms
                  {...fieldProps}
                  value={
                    fieldProps.value?.id
                      ? {
                          id: fieldProps.value.id,
                          label: fieldProps.value.name,
                        }
                      : null
                  }
                  onChange={(v) => {
                    if (v == null) {
                      clearErrorAndSetValue(form, 'room', null);
                      return;
                    }

                    clearErrorAndSetValue(form, 'room', {
                      id: v.id,
                      name: v.label,
                    });
                  }}
                  clearable
                  label='Room'
                  required={false}
                  error={form.formState.errors.room?.message}
                />
              );
            }}
          />
        )}

        <FormField
          control={form.control}
          name='appointment.start'
          render={({ field: { ref: _ref, ...fieldProps } }) => {
            return (
              <DateInput
                {...fieldProps}
                granularity='minute'
                label='Start time'
                required={true}
                error={form.formState.errors.appointment?.start?.message}
              />
            );
          }}
        />

        <FormField
          control={form.control}
          name='appointment.end'
          render={({ field: { ref: _ref, ...fieldProps } }) => {
            return (
              <DateInput
                {...fieldProps}
                granularity='minute'
                label='End time'
                required={true}
                error={form.formState.errors.appointment?.end?.message}
              />
            );
          }}
        />
      </div>

      <div className='flex flex-row items-center justify-end gap-2'>
        <Button
          onClick={async () => {
            const isValid = await form.trigger([
              'pet',
              'client',
              'appointment.visit_type',
              'employee',
              'appointment.title',
              'appointment.location',
              'appointment.start',
              'appointment.end',
            ]);

            if (isValid) {
              setActiveStep(1);
            }
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
