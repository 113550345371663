import React from 'react';

import BasicDetails from '@lupa/ui/components/BasicDetails';
import { CollapsibleCardWithAction } from '@lupa/ui/components/CollapsibleCard';
import { HEALTH_PLAN_SUBSCRIPTION_STATUS } from '@lupa/utils/enums';
import HealthPlanSubscriptionsHistory from '@lupa/work/components/healthplans/HealthPlanSubscriptionsHistory';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import { throwIfNullish } from '@lupa/work/utils/type-utils';

import { IconEdit } from '@tabler/icons-react';

import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../paths';
import { calculateAge } from '../../utils/calculate-age';
import PetInternalNotes from './PetInternalNotes';
import PetWeightTracking from './PetWeightTracking';

type PetDetailsSectionProps = {
  pet: TrpcRouterOutputs['appointments']['getAppointment']['pet'];
  clientId: string;
  petNotes: Nullish<string>;
  registeredAt: string;
  showPrimaryVetDetails?: boolean;
};

export default function PetDetailsSection({
  pet,
  clientId,
  petNotes,
  registeredAt,
  showPrimaryVetDetails,
}: PetDetailsSectionProps) {
  const navigate = useNavigate();

  const {
    data: healthPlanSubscriptions,
    isPending: isPendingSubscriptions,
    isError: isErrorLoadingPetSubscriptions,
  } = trpc.healthPlans.getPetSubscriptions.useQuery(
    {
      petId: throwIfNullish(pet.id),
    },
    {
      enabled:
        globalSingleton.currentStore.features?.health_plan_enabled ?? false,
    },
  );

  return (
    <CollapsibleCardWithAction
      title='Pet Details'
      storageKey='pet_details'
      actions={[
        {
          icon: <IconEdit />,
          tooltip: 'Edit pet details',
          onClick: () => {
            navigate(paths.pets.edit(pet.id));
          },
        },
      ]}
    >
      <div className='@container'>
        <div className='@lg:grid-cols-12 grid grid-cols-7 gap-2'>
          <div className='@lg:col-span-7 col-span-12'>
            <BasicDetails
              itemsPerColumn={4}
              elevation={0}
              name={pet.name}
              species={pet.species}
              breed={pet.breed}
              sex={pet.sex}
              neutered={pet.neutered ? 'Yes' : 'No'}
              age={calculateAge(pet.dob)}
              dateOfBirth={
                pet.dob ? format(new Date(pet.dob), 'dd MMM yyyy') : 'N/A'
              }
              colour={pet.colour}
              deceased={pet.deceased ? 'Yes' : 'No'}
              microchipNumber={pet.microchip}
              passportNumber={pet.passport_number}
              registeredAt={format(new Date(registeredAt), 'dd MMM yyyy')}
            />
            <div className='flex'>
              <BasicDetails
                title='Insurance'
                elevation={0}
                insurer={pet.insurer}
                policyNumber={pet.policy_holder_number}
              />
              {globalSingleton.currentStore.features?.health_plan_enabled &&
                healthPlanSubscriptions != null &&
                healthPlanSubscriptions.length > 0 && (
                  <BasicDetails
                    title='Subscription Plan(s)'
                    elevation={0}
                    activePlanNames={[
                      healthPlanSubscriptions
                        .filter(
                          (sub) =>
                            sub.subscription_status ===
                            HEALTH_PLAN_SUBSCRIPTION_STATUS.ACTIVE,
                        )
                        .map((sub) => sub.health_plan.name)
                        .join(', '),
                    ]}
                  />
                )}
            </div>
          </div>

          <div className='@lg:col-span-5 col-span-12 flex h-fit flex-col gap-2 p-1'>
            {showPrimaryVetDetails && (
              <BasicDetails
                title='Primary Vet'
                onEdit={() => navigate(paths.pets.edit(pet.id))}
                clinicName={pet.primary_vet?.practice_name}
                name={pet.primary_vet?.name}
                email={pet.primary_vet?.email}
                phone={pet.primary_vet?.phone}
              />
            )}
            <PetInternalNotes
              petNotes={petNotes}
              petId={pet.id}
              clientId={clientId}
            />
            <PetWeightTracking petId={pet.id} weights={pet.weights} />
          </div>

          {globalSingleton.currentStore.features?.health_plan_enabled &&
            !isPendingSubscriptions &&
            !isErrorLoadingPetSubscriptions &&
            healthPlanSubscriptions != null &&
            healthPlanSubscriptions.length > 0 && (
              <div className='col-span-12 p-2'>
                <HealthPlanSubscriptionsHistory
                  subscriptions={healthPlanSubscriptions}
                />
              </div>
            )}
        </div>
      </div>
    </CollapsibleCardWithAction>
  );
}
