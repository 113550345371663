import { useState } from 'react';

import { Button } from '@lupa/ui/components/shadcn/button';
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@lupa/ui/components/shadcn/command';
import { DialogTitle } from '@lupa/ui/components/shadcn/dialog';
import { Skeleton } from '@lupa/ui/components/shadcn/skeleton';
import {
  ToggleGroup,
  ToggleGroupItem,
} from '@lupa/ui/components/shadcn/toggle-group';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';
import { formatCurrency } from '@lupa/work/utils/i18n';

import {
  IconListCheck,
  IconPill,
  IconPlus,
  IconReportMedical,
} from '@tabler/icons-react';
import { useDebounce } from '@uidotdev/usehooks';

import { CommandLoading } from 'cmdk';

interface SearchInvoiceItemsProps {
  onSelect: (
    item:
      | {
          type: 'bundle';
          data: TrpcRouterOutputs['invoices']['searchInvoiceItems']['bundles'][number];
        }
      | {
          type: 'service';
          data: TrpcRouterOutputs['invoices']['searchInvoiceItems']['services'][number];
        }
      | {
          type: 'product';
          data: TrpcRouterOutputs['invoices']['searchInvoiceItems']['products'][number];
        },
  ) => void;
  shouldShowBundles?: boolean;
}

export default function SearchInvoiceItems({
  onSelect,
  shouldShowBundles = true,
}: SearchInvoiceItemsProps) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [selectedItemTypes, setSelectedItemTypes] = useState<
    Array<'services' | 'products' | 'bundles'>
  >(
    shouldShowBundles
      ? ['services', 'products', 'bundles']
      : ['services', 'products'],
  );
  const queryDebounced = useDebounce(query, 300);

  const { data, isLoading } = trpc.invoices.searchInvoiceItems.useQuery(
    {
      query: queryDebounced,
      itemTypes: selectedItemTypes,
      shouldShowBundles,
    },
    {
      enabled: open,
    },
  );

  return (
    <>
      <Button
        variant='outline'
        size='sm'
        className='w-48'
        onClick={() => setOpen(true)}
      >
        <IconPlus size={16} strokeWidth={2} aria-hidden='true' />
        Add Item
      </Button>

      <CommandDialog
        open={open}
        onOpenChange={setOpen}
        shouldFilter={false}
        contentClassName='min-w-[600px]'
        // TODO: Remove when we refactor AppointmentUpsert Dialog
        maxZIndex={true}
      >
        <DialogTitle />

        <CommandInput
          placeholder='Type a command or search...'
          value={query}
          onChangeCapture={(e: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(e.target.value);
          }}
        />

        <CommandList>
          <ToggleGroup
            type='multiple'
            size='sm'
            value={selectedItemTypes}
            onValueChange={(newValue) => {
              setSelectedItemTypes(
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                newValue as Array<'services' | 'products' | 'bundles'>,
              );
            }}
            className='py-1'
          >
            <ToggleGroupItem value='services'>Services</ToggleGroupItem>
            <ToggleGroupItem value='products'>Products</ToggleGroupItem>
            {shouldShowBundles && (
              <ToggleGroupItem value='bundles'>Bundles</ToggleGroupItem>
            )}
          </ToggleGroup>

          {isLoading && (
            <CommandLoading>
              {Array.from({ length: 5 }).map((_, index) => (
                <Skeleton key={index} className='m-1 h-8 rounded-md' />
              ))}
            </CommandLoading>
          )}

          <CommandEmpty>No results found.</CommandEmpty>

          {data?.bundles && data?.bundles.length > 0 && (
            <>
              <CommandGroup heading='Bundles'>
                {data?.bundles.map((bundle) => (
                  <CommandItem
                    key={bundle.id}
                    value={bundle.id}
                    onSelect={() => {
                      onSelect({
                        type: 'bundle',
                        data: bundle,
                      });
                      setOpen(false);
                    }}
                  >
                    <IconListCheck
                      size={16}
                      strokeWidth={2}
                      aria-hidden='true'
                    />
                    <span>{bundle.name}</span>
                    <span className='text-muted-foreground ml-auto text-xs tracking-widest'>
                      {formatCurrency(bundle.price)}
                    </span>
                  </CommandItem>
                ))}
              </CommandGroup>

              <CommandSeparator />
            </>
          )}

          {data?.services && data?.services.length > 0 && (
            <>
              <CommandGroup heading='Services'>
                {data?.services.map((service) => (
                  <CommandItem
                    key={service.id}
                    value={service.id}
                    keywords={service.name.split(' ')}
                    onSelect={() => {
                      onSelect({
                        type: 'service',
                        data: service,
                      });
                      setOpen(false);
                    }}
                  >
                    <IconReportMedical
                      size={16}
                      strokeWidth={2}
                      aria-hidden='true'
                    />
                    <span>{service.name}</span>
                    <span className='text-muted-foreground ml-auto text-xs tracking-widest'>
                      {formatCurrency(service.price)}
                    </span>
                  </CommandItem>
                ))}
              </CommandGroup>

              <CommandSeparator />
            </>
          )}

          {data?.products && data?.products.length > 0 && (
            <CommandGroup heading='Products'>
              {data?.products.map((product) => (
                <CommandItem
                  key={product.id}
                  value={product.id}
                  keywords={product.name.split(' ')}
                  onSelect={() => {
                    onSelect({
                      type: 'product',
                      data: product,
                    });
                    setOpen(false);
                  }}
                >
                  <IconPill size={16} strokeWidth={2} aria-hidden='true' />

                  <div className='flex flex-col gap-0.5'>
                    {product.name}

                    {product.subtitle && (
                      <span className='text-muted-foreground text-xs'>
                        {product.subtitle}
                      </span>
                    )}
                  </div>

                  <span className='text-muted-foreground ml-auto text-xs tracking-widest'>
                    {formatCurrency(product.price)}
                  </span>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
        </CommandList>
      </CommandDialog>
    </>
  );
}
