import React from 'react';

import { AutocompleteSearch } from '@lupa/ui/components/AutocompleteSearch';
import { BillingProductValidationSchemaType } from '@lupa/utils/validators/invoiceValidators';
import NumberInput from '@lupa/work/components/ui/NumberInput';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';

import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { IconCertificate, IconTrash } from '@tabler/icons-react';

import { P, match } from 'ts-pattern';

interface PrescribedProductsListProps {
  products: BillingProductValidationSchemaType[];
  onProductChange: (
    index: number,
    prescribedProduct: Partial<BillingProductValidationSchemaType>,
  ) => void;
  onProductRemove: (index: number) => void;
  onPrescriptionClick: (index: number) => void;
}

export default function AppointmentsInvoicePrescribedProductsList({
  products,
  onProductChange,
  onProductRemove,
  onPrescriptionClick,
}: PrescribedProductsListProps) {
  return (
    <Table size='small' sx={{ width: '100%' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: 300 }}>Product</TableCell>
          <TableCell align='center'>Quantity</TableCell>
          <TableCell align='right'>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map((product, idx) => (
          <TableRow key={product.id}>
            <TableCell>
              <AutocompleteSearch<{
                ItemType: TrpcRouterOutputs['products']['searchProducts'][0];
                FreeSolo: true;
              }>
                trpcFunction={trpc.products.searchProducts}
                label='Product'
                getOptionLabel={(option) =>
                  match(option)
                    .with(P.string, (str) => str)
                    .with({ name: P.string }, (obj) => obj.name)
                    .exhaustive()
                }
                value={product}
                showTooltip
                onSelect={(item) => {
                  if (item != null) {
                    onProductChange(idx, {
                      name: item.name,
                      product_id: item.id,
                    });
                  }
                }}
                freeSolo
                freeSoloOnTextChange={(text) => {
                  onProductChange(idx, { name: text, product_id: null });
                }}
              />
            </TableCell>
            <TableCell align='center'>
              <NumberInput
                value={product.quantity}
                showArrows
                onChange={(value) => {
                  onProductChange(idx, { quantity: value });
                }}
                style={{ width: 60 }}
              />
            </TableCell>
            <TableCell align='right'>
              <Stack direction='row' justifyContent='flex-end'>
                <Tooltip title='Prescription' placement='top'>
                  <span>
                    <IconButton
                      disabled={!product.name}
                      color={
                        product.prescription_id != null ? 'primary' : 'default'
                      }
                      onClick={() => onPrescriptionClick(idx)}
                    >
                      <IconCertificate />
                    </IconButton>
                  </span>
                </Tooltip>
                <IconButton color='error' onClick={() => onProductRemove(idx)}>
                  <IconTrash />
                </IconButton>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
