import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@lupa/ui/components/shadcn/card';

import {
  IconClock,
  IconGlobe,
  IconMail,
  IconMap,
  IconPhone,
} from '@tabler/icons-react';

export default function SiteInfo({
  name,
  description,
  address,
  phone,
  email,
  website,
  businessHours,
}: {
  name: string;
  description?: string;
  address?: string;
  phone?: string;
  email?: string;
  website?: string;
  businessHours?: {
    day: string;
    hours: string;
  }[];
}) {
  return (
    <Card className='min-h-[400px] p-6'>
      <CardHeader>
        <CardTitle>{name}</CardTitle>
        {description && <CardDescription>{description}</CardDescription>}
      </CardHeader>
      <CardContent className='grid gap-6'>
        {address && (
          <div className='grid gap-4 md:grid-cols-2'>
            <div className='space-y-4'>
              <div className='flex items-center gap-2'>
                <IconMap className='text-muted-foreground h-4 w-4' />
                <span>{address}</span>
              </div>
              <div className='flex items-center gap-2'>
                <IconPhone className='text-muted-foreground h-4 w-4' />
                <span>{phone}</span>
              </div>
              <div className='flex items-center gap-2'>
                <IconMail className='text-muted-foreground h-4 w-4' />
                <span>{email}</span>
              </div>
              <div className='flex items-center gap-2'>
                <IconGlobe className='text-muted-foreground h-4 w-4' />
                <span>{website}</span>
              </div>
            </div>
          </div>
        )}

        {businessHours && (
          <div className='space-y-4'>
            <h3 className='font-semibold'>Business Hours</h3>
            <div className='grid gap-2'>
              {businessHours.map(({ day, hours }) => (
                <div className='flex items-center gap-2'>
                  <IconClock className='text-muted-foreground h-4 w-4' />
                  <span className='flex w-full justify-between'>
                    <span>{day}</span>
                    <span>{hours}</span>
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
