import React, { useMemo } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Tooltip } from '@mui/material';

import { FormikProps, FormikValues } from 'formik';

interface FormSaveButtonProps<Values> {
  formik: FormikProps<Values>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  testId?: string;
  shouldBeDisabledIfNotDirty?: boolean;
}

export default function FormSaveButton<Values extends FormikValues>({
  formik,
  onClick,
  type,
  loading = false,
  disabled,
  children,
  testId,
  shouldBeDisabledIfNotDirty = true,
}: FormSaveButtonProps<Values>) {
  const tooltipTitle = useMemo(() => {
    const allErrors = new Set<string>();

    const extractErrors = (errors: typeof formik.errors) => {
      return Object.values(errors).forEach((val) => {
        if (typeof val === 'string') {
          allErrors.add(val);
        } else if (typeof val === 'object' && val !== null) {
          extractErrors(val);
        }
      });
    };

    extractErrors(formik.errors);
    return Array.from(allErrors).join(', ');
  }, [formik.errors]);

  const isDisabled = useMemo(() => {
    if (loading) {
      return true;
    }

    if (disabled != null) {
      return disabled;
    }

    if (!formik.isValid) {
      return true;
    }

    if (shouldBeDisabledIfNotDirty && !formik.dirty) {
      return true;
    }

    return false;
  }, [
    disabled,
    loading,
    formik.isValid,
    formik.dirty,
    shouldBeDisabledIfNotDirty,
  ]);

  return (
    <Tooltip title={tooltipTitle} placement='top'>
      <span>
        <LoadingButton
          variant='contained'
          disabled={isDisabled}
          onClick={onClick}
          loading={loading}
          type={type}
          data-testid={testId}
        >
          {children}
        </LoadingButton>
      </span>
    </Tooltip>
  );
}
