import React from 'react';

import { Input } from '@lupa/ui/components/shadcn/input';
import { cn } from '@lupa/ui/lib/utils';

import { FormItem } from '../shadcn/form';
import FormHelperText from './FormHelperText';
import FormLabel from './FormLabel';
import InputBaseProps from './InputBaseProps';

interface TextInputProps
  extends InputBaseProps<string | null | undefined, string | null>,
    Omit<
      React.ComponentProps<'input'>,
      'onBlur' | 'onChange' | 'required' | 'value'
    > {}

export default function TextInput({
  className,
  placeholder,
  error,
  required,
  label,
  helperText,
  value,
  onChange,
  afterValueChange,
  hint,
  containerClassName,
  ...props
}: TextInputProps) {
  return (
    <FormItem className={cn('space-y-1', containerClassName)}>
      <FormLabel
        htmlFor={label}
        label={label}
        required={required}
        hint={hint}
      />

      <Input
        id={label}
        placeholder={placeholder ?? label}
        className={cn(
          className,
          !!error &&
            'border-destructive/80 text-destructive focus-visible:border-destructive/80 focus-visible:ring-destructive/20',
        )}
        value={value ?? ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newValueCast = e.target.value === '' ? null : e.target.value;
          onChange(newValueCast);
          afterValueChange?.(newValueCast);
        }}
        {...props}
      />

      <FormHelperText error={error} helperText={helperText} />
    </FormItem>
  );
}
