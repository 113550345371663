import React from 'react';

import Loading from '@lupa/ui/components/Loading';
import { PRESCRIPTION_STATUS } from '@lupa/utils/enums';
import { BillingProductValidationSchemaType } from '@lupa/utils/validators/invoiceValidators';
import { trpc } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import { zIsoDateString } from '@lupa/work/utils/time-utils';

import { Alert } from '@mui/material';
import { skipToken } from '@tanstack/react-query';

import { format } from 'date-fns';
import { match } from 'ts-pattern';

import PrescriptionForm, {
  PrescriptionAndDispensesFormDataType,
  PrescriptionFormProps,
} from './PrescriptionForm';

interface PrescriptionUpsertProps {
  prescription:
    | { type: 'existing'; id: string }
    | {
        type: 'new';
        sourceProduct: BillingProductValidationSchemaType;
        petId: string;
        clientId: string;
      };
  onSaved: PrescriptionFormProps['onSaved'];
  handleClose: () => void;
  billingProductContext?: PrescriptionFormProps['billingProductContext'] & {
    prescription_dispense_ids?: string[];
  };
}

const useGetInitialValues = ({
  prescription: prescriptionSpec,
}: {
  prescription: PrescriptionUpsertProps['prescription'];
}):
  | {
      state: 'loaded';
      initialValues: PrescriptionAndDispensesFormDataType;
    }
  | {
      state: 'loading';
    }
  | { state: 'error' } => {
  const enableQuery = prescriptionSpec.type === 'existing';
  const {
    data: existingPrescription,
    isPending,
    isError,
  } = trpc.prescriptions.getById.useQuery(
    {
      id: enableQuery ? prescriptionSpec.id : 'skip',
    },
    { enabled: enableQuery, staleTime: 0 },
  );

  const sourceProductId = match(prescriptionSpec)
    .with({ type: 'new' }, (p) => p.sourceProduct.product_id)
    .with({ type: 'existing' }, () => undefined)
    .exhaustive();

  const {
    data: product,
    isPending: isProductLoading,
    isError: isProductError,
  } = trpc.products.getProduct.useQuery(
    sourceProductId != null
      ? {
          productId: sourceProductId,
        }
      : skipToken,
  );

  if (enableQuery) {
    if (isPending) return { state: 'loading' };
    if (isError) return { state: 'error' };

    const { dispenses } = existingPrescription;
    return {
      state: 'loaded',
      initialValues: {
        prescription: existingPrescription,
        dispenses: {
          initial: dispenses,
          modified: {},
          created: [],
        },
        formOptions: {
          notifyReadyForPickup: null,
        },
      },
    };
  }

  if (sourceProductId != null) {
    if (isProductLoading) return { state: 'loading' };
    if (isProductError) return { state: 'error' };
  }

  return {
    state: 'loaded',
    initialValues: {
      prescription: {
        id: undefined,
        pet_id: prescriptionSpec.petId,
        client_id: prescriptionSpec.clientId,
        is_prescribed_only: false,
        product_name: prescriptionSpec.sourceProduct.name,
        prescriber_employee_id: globalSingleton.currentEmployee.id,
        quantity: prescriptionSpec.sourceProduct.quantity,
        unit: prescriptionSpec.sourceProduct.unit ?? '',
        dosage_specification: {
          type: 'structured',
          quantity: prescriptionSpec.sourceProduct.quantity,
          frequency: {
            intervalNumber: 1,
            intervalUnit: 'days',
            timesPerInterval: 1,
          },
          startDate: zIsoDateString.parse(format(new Date(), 'yyyy-MM-dd')),
          duration: {
            type: 'for-duration',
            durationNumber: 7,
            durationUnit: 'days',
          },
        },
        refill_limit: 0,
        refills_permitted_until: null,
        next_refill_due: null,
        status: PRESCRIPTION_STATUS.PENDING,
        source_product_id: prescriptionSpec.sourceProduct.product_id,
        created_at: undefined,
        qr_code_link: product?.qr_code_link,
      },
      dispenses: {
        initial: [],
        modified: {},
        created: [],
      },
      formOptions: {
        notifyReadyForPickup: null,
      },
    },
  };
};

export default function PrescriptionUpsert({
  prescription,
  billingProductContext,
  onSaved,
  handleClose,
}: PrescriptionUpsertProps) {
  const state = useGetInitialValues({
    prescription,
  });

  return match(state)
    .with({ state: 'loading' }, () => <Loading />)
    .with({ state: 'error' }, () => (
      <Alert severity='error'>Error loading prescription</Alert>
    ))
    .with({ state: 'loaded' }, ({ initialValues }) => (
      <PrescriptionForm
        initialValues={initialValues}
        onSaved={onSaved}
        handleClose={handleClose}
        billingProductContext={billingProductContext}
      />
    ))
    .exhaustive();
}
