import TooltipButton from '@lupa/ui/components/TooltipButton';
import NumberInput from '@lupa/ui/components/form/NumberInput';
import NumberWithOptionsInput from '@lupa/ui/components/form/NumberWithOptionsInput';
import { Badge } from '@lupa/ui/components/shadcn/badge';
import { Button } from '@lupa/ui/components/shadcn/button';
import { FormField } from '@lupa/ui/components/shadcn/form';
import { TableCell, TableRow } from '@lupa/ui/components/shadcn/table';
import { cn } from '@lupa/ui/lib/utils';
import { DISCOUNT_TYPE } from '@lupa/utils/enums';
import { RemainingAllowanceQuantityDataType } from '@lupa/utils/healthplanUtils';
import { formatCurrency } from '@lupa/work/utils/i18n';

import { IconCertificate, IconTrash } from '@tabler/icons-react';

import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import UpsertBillingItemAllowanceUsageForm from './UpsertBillingItemAllowanceUsageForm';
import type { UpsertBillingItemsFormType } from './UpsertBillingItemsForm';
import UpsertBillingProductBatchesForm from './UpsertBillingProductBatchesForm';
import billingItemsDataUtils from './upsertBillingItemsFormUtils';

export type ProductField = UseFieldArrayReturn<
  UpsertBillingItemsFormType,
  'products'
>['fields'][number];

interface UpsertBillingProductFormProps {
  form: UseFormReturn<UpsertBillingItemsFormType>;
  product: {
    type: 'product';
    idx: number;
    data: ProductField;
  };
  onDelete: (idx: number) => void;
  showPrescriptionDetails: boolean;
  onPrescriptionClick: (index: number) => void;
  availableAllowances: Record<string, RemainingAllowanceQuantityDataType[]>;
  hasDiscountsPermission: boolean;
}

export default function UpsertBillingProductForm({
  form,
  product,
  onDelete,
  showPrescriptionDetails = false,
  onPrescriptionClick,
  availableAllowances,
  hasDiscountsPermission,
}: UpsertBillingProductFormProps) {
  const batchesWatch = form.watch(`products.${product.idx}.batches`);
  const isBatchProduct = batchesWatch != null && batchesWatch.length > 0;
  const watchProductAllowanceUsage = form.watch(
    `products.${product.idx}.allowance_usage`,
  );

  return (
    <>
      <TableRow key={product.data.id}>
        <TableCell className='font-medium'>
          <div className='flex flex-col items-start'>
            <p>{product.data.name}</p>
            <Badge variant='outline'>Product</Badge>
          </div>
        </TableCell>

        <TableCell>
          {form.watch(`products.${product.idx}.has_subunit`) ? (
            <NumberWithOptionsInput
              value={{
                amount: form.watch(`products.${product.idx}.quantity` as const),
                option: form.watch(
                  `products.${product.idx}.measure_unit` as const,
                ),
              }}
              options={[
                product.data.unit ?? '',
                product.data.subunit ?? '',
              ].map((type) => ({
                label: type,
                value: type,
              }))}
              onChange={(measureUnit) => {
                billingItemsDataUtils.onProductQuantityChange({
                  form,
                  productIdx: product.idx,
                  newMeasureUnit: measureUnit.option,
                  newQuantity: measureUnit.amount,
                });
              }}
              disabled={isBatchProduct}
              onBlur={() => {
                form.trigger(`products.${product.idx}.measure_unit`);
              }}
              error={
                form.formState.errors.products?.[product.idx]?.measure_unit
                  ?.message
              }
              required={true}
            />
          ) : (
            <FormField
              control={form.control}
              name={`products.${product.idx}.quantity` as const}
              render={({ field: { ref: _ref, ...fieldProps } }) => {
                return (
                  <NumberInput
                    {...fieldProps}
                    required={true}
                    disabled={isBatchProduct}
                    afterValueChange={(newQuantity) => {
                      billingItemsDataUtils.onProductQuantityChange({
                        form,
                        productIdx: product.idx,
                        newMeasureUnit: undefined,
                        newQuantity,
                      });
                    }}
                    error={
                      form.formState.errors.products?.[product.idx]?.quantity
                        ?.message
                    }
                    className='w-[120px]'
                  />
                );
              }}
            />
          )}
        </TableCell>

        <TableCell>
          <NumberWithOptionsInput
            value={{
              amount: form.watch(`products.${product.idx}.discount` as const),
              option: form.watch(
                `products.${product.idx}.discount_type` as const,
              ),
            }}
            options={Object.values(DISCOUNT_TYPE).map((type) => ({
              label: type,
              value: type,
            }))}
            onChange={(newDiscount) => {
              billingItemsDataUtils.onProductDiscountChange({
                form,
                productIdx: product.idx,
                newDiscount,
              });
            }}
            onBlur={() => {
              form.trigger(`products.${product.idx}.discount`);
            }}
            error={
              form.formState.errors.products?.[product.idx]?.discount?.message
            }
            required={true}
            disabled={
              !hasDiscountsPermission ||
              (watchProductAllowanceUsage?.length != null &&
                watchProductAllowanceUsage?.length > 0)
            }
          />
        </TableCell>

        <TableCell className='text-center'>
          <p>
            {formatCurrency(
              form.watch(`products.${product.idx}.price` as const),
            )}
          </p>
        </TableCell>

        <TableCell className='text-right'>
          <div className='flex flex-row justify-end gap-1'>
            {showPrescriptionDetails && (
              <TooltipButton
                tooltip='Prescription'
                variant='ghost'
                size='icon'
                onClick={() => {
                  onPrescriptionClick(product.idx);
                }}
              >
                <IconCertificate
                  className={cn({
                    'text-primary':
                      form.watch(`products.${product.idx}.prescription_id`) !=
                      null,
                  })}
                />
              </TooltipButton>
            )}

            <Button
              variant='destructiveGhost'
              size='icon'
              onClick={() => {
                onDelete(product.idx);
              }}
            >
              <IconTrash />
            </Button>
          </div>
        </TableCell>
      </TableRow>

      <UpsertBillingProductBatchesForm form={form} product={product} />

      <UpsertBillingItemAllowanceUsageForm
        form={form}
        identifier={product}
        availableAllowances={availableAllowances}
      />
    </>
  );
}
