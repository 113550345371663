'use client';

import { useId } from 'react';

import { Button } from '@lupa/ui/components/shadcn/button';
import { Label } from '@lupa/ui/components/shadcn/label';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from '@lupa/ui/components/shadcn/pagination';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@lupa/ui/components/shadcn/select';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@lupa/ui/components/shadcn/table';
import { cn } from '@lupa/ui/lib/utils';

import {
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  ChevronFirst,
  ChevronLast,
  ChevronLeft,
  ChevronRight,
} from 'lucide-react';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  getRowCanExpand?: (row: Row<TData>) => boolean;
  className?: string;
  rowClassName?: string;
  theaderClassName?: string;
  pageSize?: number;
  pagination?: boolean;
  pageSizeOptions?: number[];
}

export function DataTable<TData, TValue>({
  columns,
  data,
  getRowCanExpand,
  className = '',
  rowClassName = '',
  theaderClassName = '',
  pageSize = 10,
  pagination = false,
  pageSizeOptions = [5, 10, 25, 50],
}: DataTableProps<TData, TValue>) {
  const id = useId();

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    ...(pagination && {
      getPaginationRowModel: getPaginationRowModel(),
      initialState: {
        pagination: {
          pageSize: pageSize ?? 10,
        },
      },
    }),
  });

  return (
    <div>
      <Table className={cn(className)}>
        <TableHeader className={cn(theaderClassName)}>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className={cn(rowClassName)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className='h-24 text-center'>
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {pagination && (
        <div className='flex items-center justify-between gap-8 py-4'>
          {/* Results per page */}
          <div className='flex items-center gap-3'>
            <Label htmlFor={id} className='max-sm:sr-only'>
              Rows per page
            </Label>
            <Select
              value={table.getState().pagination.pageSize.toString()}
              onValueChange={(value) => {
                table.setPageSize(Number(value));
              }}
            >
              <SelectTrigger id={id} className='w-fit whitespace-nowrap'>
                <SelectValue placeholder='Select number of results' />
              </SelectTrigger>
              <SelectContent>
                {pageSizeOptions.map((size) => (
                  <SelectItem key={size} value={size.toString()}>
                    {size}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {/* Page number information */}
          <div className='text-muted-foreground flex grow justify-end whitespace-nowrap text-sm'>
            <p
              className='text-muted-foreground whitespace-nowrap text-sm'
              aria-live='polite'
            >
              <span className='text-foreground'>
                {table.getState().pagination.pageIndex *
                  table.getState().pagination.pageSize +
                  1}
                -
                {Math.min(
                  (table.getState().pagination.pageIndex + 1) *
                    table.getState().pagination.pageSize,
                  table.getRowCount(),
                )}
              </span>{' '}
              of{' '}
              <span className='text-foreground'>
                {table.getRowCount().toString()}
              </span>
            </p>
          </div>

          {/* Pagination buttons */}
          <div>
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <Button
                    size='icon'
                    variant='outline'
                    className='disabled:pointer-events-none disabled:opacity-50'
                    onClick={() => table.firstPage()}
                    disabled={!table.getCanPreviousPage()}
                    aria-label='Go to first page'
                  >
                    <ChevronFirst
                      size={16}
                      strokeWidth={2}
                      aria-hidden='true'
                    />
                  </Button>
                </PaginationItem>
                <PaginationItem>
                  <Button
                    size='icon'
                    variant='outline'
                    className='disabled:pointer-events-none disabled:opacity-50'
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                    aria-label='Go to previous page'
                  >
                    <ChevronLeft size={16} strokeWidth={2} aria-hidden='true' />
                  </Button>
                </PaginationItem>
                <PaginationItem>
                  <Button
                    size='icon'
                    variant='outline'
                    className='disabled:pointer-events-none disabled:opacity-50'
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                    aria-label='Go to next page'
                  >
                    <ChevronRight
                      size={16}
                      strokeWidth={2}
                      aria-hidden='true'
                    />
                  </Button>
                </PaginationItem>
                <PaginationItem>
                  <Button
                    size='icon'
                    variant='outline'
                    className='disabled:pointer-events-none disabled:opacity-50'
                    onClick={() => table.lastPage()}
                    disabled={!table.getCanNextPage()}
                    aria-label='Go to last page'
                  >
                    <ChevronLast size={16} strokeWidth={2} aria-hidden='true' />
                  </Button>
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        </div>
      )}
    </div>
  );
}
