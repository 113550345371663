import React, { useEffect } from 'react';

import Loading from '@lupa/ui/components/Loading';
import { trpc } from '@lupa/work/lib/trpc';

import { UseFormReturn } from 'react-hook-form';

import type { UpsertBillingItemsFormType } from '../../invoices/UpsertBillingItemsForm';
import UpsertBillingItemsForm from '../../invoices/UpsertBillingItemsForm';
import type { NewAppointmentCreateType } from './NewAppointmentCreate';

export default function NewAppointmentUpsertInvoicingView({
  form,
  billingItemsForm,
  autofilledAppointmentId,
}: {
  form: UseFormReturn<NewAppointmentCreateType>;
  billingItemsForm: UseFormReturn<UpsertBillingItemsFormType>;
  autofilledAppointmentId?: string;
}) {
  const { data, isPending } =
    trpc.appointments.getAppointmentBillingItems.useQuery(
      {
        appointmentId: form.getValues('appointment.id'),
        visitTypeId: form.getValues('appointment.visit_type.id'),
        autofilledAppointmentId,
      },
      {
        enabled:
          !!form.getValues('appointment.id') ||
          !!form.getValues('appointment.visit_type.id') ||
          !!autofilledAppointmentId,
      },
    );

  useEffect(() => {
    if (data) {
      billingItemsForm.setValue('services', data.services);

      billingItemsForm.setValue('products', data.products);

      //   billingItemsForm.setValue(
      //     'prescribed_products',
      //     data.prescribed_products,
      //   );
    }
  }, [data]);

  if (isPending) {
    return <Loading />;
  }

  return (
    <div className='h-full overflow-x-auto p-2'>
      <UpsertBillingItemsForm form={billingItemsForm} />
    </div>
  );
}
