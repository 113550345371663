import { useMemo } from 'react';

import Loading from '@lupa/ui/components/Loading';
import { Alert, AlertDescription } from '@lupa/ui/components/shadcn/alert';
import { SidebarProvider } from '@lupa/ui/components/shadcn/sidebar';
import { CompanyNode } from '@lupa/utils/validators/enterpriseValidators';
import { EnterpriseSelectionType } from '@lupa/utils/validators/enterpriseValidators';
import EnterpriseMainContent from '@lupa/work/components/enterprise/EnterpriseMainContent';
import EnterpriseSidebar from '@lupa/work/components/enterprise/EnterpriseSidebar';
import { trpc } from '@lupa/work/lib/trpc';
import { paths } from '@lupa/work/paths';

import { useParams } from 'react-router-dom';

const findEntityName = (
  companies: CompanyNode[],
  targetId: string,
  entityType: EnterpriseSelectionType,
): string => {
  for (const company of companies) {
    if (
      entityType === EnterpriseSelectionType.COMPANY &&
      company.id === targetId
    ) {
      return company.name;
    }

    if (entityType === EnterpriseSelectionType.STORE) {
      const store = company.stores.find(
        (s: { id: string }) => s.id === targetId,
      );

      if (store) {
        return store.name;
      }
    }

    const foundInChildren = findEntityName(
      company.childrenCompanies,
      targetId,
      entityType,
    );

    if (foundInChildren) {
      return foundInChildren;
    }
  }

  return '';
};

export default function EnterpriseLayout() {
  const { data: companyTree, isFetching } =
    trpc.enterprise.getCompanySubTree.useQuery();
  const params = useParams();
  const entityId = params.id || '';
  const selectedEntityType = window.location.pathname.includes(
    paths.admin.store(entityId),
  )
    ? EnterpriseSelectionType.STORE
    : EnterpriseSelectionType.COMPANY;

  const entityName = useMemo(() => {
    if (companyTree != null) {
      return findEntityName([companyTree], entityId, selectedEntityType);
    }

    return '';
  }, [entityId, companyTree]);

  if (isFetching) {
    return <Loading />;
  }

  if (!companyTree) {
    return (
      <Alert variant='destructive'>
        <AlertDescription>No company tree found</AlertDescription>
      </Alert>
    );
  }

  return (
    <SidebarProvider>
      <div className='flex w-full'>
        <EnterpriseSidebar companyTree={companyTree} />
        <EnterpriseMainContent
          selectedEntityType={selectedEntityType}
          entityId={entityId}
          entityName={entityName}
        />
      </div>
    </SidebarProvider>
  );
}
