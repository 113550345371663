import FallbackAvatar from '@lupa/ui/components/FallbackAvatar';
import { Button } from '@lupa/ui/components/shadcn/button';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@lupa/ui/components/shadcn/tabs';
import { EnterpriseSelectionType } from '@lupa/utils/validators/enterpriseValidators';
import { lastLoadedStoreIdAtom } from '@lupa/work/atoms/atoms';
import AdminAnalyticsPage from '@lupa/work/pages/AdminAnalyticsPage';
import AdminSettingsPage from '@lupa/work/pages/AdminSettingsPage';
import { paths } from '@lupa/work/paths';

import { IconBuilding, IconBuildings } from '@tabler/icons-react';

import { useSetAtom } from 'jotai';

import PermissionsSettings from './PermissionsSettings';
import SiteInfo from './SiteInfo';

export default function EnterpriseMainContent({
  selectedEntityType,
  entityId,
  entityName,
}: {
  selectedEntityType: EnterpriseSelectionType;
  entityId: string;
  entityName: string;
}) {
  const setLastLoadedStoreId = useSetAtom(lastLoadedStoreIdAtom);

  return (
    <div className='flex-1 space-y-4 p-4'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center space-x-4'>
          <div className='bg-muted'>
            <FallbackAvatar
              src={null}
              fallbackIcon={
                selectedEntityType === EnterpriseSelectionType.COMPANY ? (
                  <IconBuildings size={16} />
                ) : (
                  <IconBuilding size={16} />
                )
              }
              name={entityName}
              size={28}
            />
          </div>
          <div className='space-y-1'>
            <h2 className='text-lg font-semibold'>{entityName}</h2>
          </div>
        </div>
        {selectedEntityType === EnterpriseSelectionType.STORE && (
          <Button
            onClick={() => {
              setLastLoadedStoreId(entityId);
              window.location.href = `${window.location.origin}${paths.index}`;
            }}
          >
            Open
          </Button>
        )}
      </div>
      <Tabs defaultValue='analytics'>
        <TabsList>
          <TabsTrigger value='analytics'>Analytics</TabsTrigger>
          <TabsTrigger value='settings'>Settings</TabsTrigger>
          <TabsTrigger value='permissions'>Permissions</TabsTrigger>
          <TabsTrigger value='general'>General</TabsTrigger>
        </TabsList>
        <TabsContent value='general' className='space-y-4'>
          <SiteInfo name={entityName} />
        </TabsContent>
        <TabsContent value='analytics' className='flex-1 overflow-auto'>
          <AdminAnalyticsPage selectedStoresId={entityId} />
        </TabsContent>
        <TabsContent value='settings' className='flex-1 overflow-auto'>
          <AdminSettingsPage selectedStoresId={entityId} />
        </TabsContent>
        <TabsContent value='permissions' className='flex-1 overflow-auto'>
          <PermissionsSettings />
        </TabsContent>
      </Tabs>
    </div>
  );
}
