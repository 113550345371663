import { Suspense, lazy } from 'react';

import Loading from '@lupa/ui/components/Loading';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@lupa/ui/components/shadcn/tabs';

import { Stack } from '@mui/material';

const ServicesBulkEdit = lazy(
  () => import('@lupa/work/components/ServicesBulkEdit'),
);

const InventoryBulkEdit = lazy(
  () => import('@lupa/work/components/inventory/InventoryBulkEdit'),
);

export default function AdminSettingsPage({
  selectedStoresId,
}: {
  selectedStoresId: string;
}) {
  return (
    <Stack direction='column'>
      <Tabs defaultValue='services'>
        <div className='flex w-full justify-center'>
          <TabsList>
            <TabsTrigger className='w-[160px]' value='services'>
              Services
            </TabsTrigger>
            <TabsTrigger className='w-[160px]' value='products'>
              Products
            </TabsTrigger>
          </TabsList>
        </div>

        <div className='flex w-full flex-col gap-1'>
          <TabsContent value='services'>
            <Suspense fallback={<Loading />}>
              <ServicesBulkEdit inputSelectedStoresIds={[selectedStoresId]} />
            </Suspense>
          </TabsContent>
          <TabsContent value='products'>
            <Suspense fallback={<Loading />}>
              <InventoryBulkEdit inputSelectedStoresIds={[selectedStoresId]} />
            </Suspense>
          </TabsContent>
        </div>
      </Tabs>
    </Stack>
  );
}
