import * as React from 'react';

import { cn } from '@lupa/ui/lib/utils';

import { type VariantProps, cva } from 'class-variance-authority';

const badgeVariants = cva(
  'inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'bg-primary/40 text-primary shadow border border-primary/60',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-red-50 text-red-600 border border-red-600/20 hover:bg-red-100',
        outline: 'bg-gray-50 text-gray-600 border border-gray-600/20',
        green: 'bg-green-50 text-green-600 border border-green-600/20',
        yellow: 'bg-yellow-50 text-yellow-600 border border-yellow-600/20',
        orange: 'bg-orange-50 text-amber-600 border border-orange-600/20',
        red: 'bg-red-50 text-red-600 border border-red-600/20',
        purple: 'bg-purple-50 text-purple-600 border border-purple-600/20',
        blue: 'bg-blue-50 text-blue-600 border border-blue-600/20',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
