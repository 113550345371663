import React from 'react';

import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';

import { cn } from '../lib/utils';

interface SortableListProps {
  items: { id: string; node: React.ReactNode }[];
  onDragEnd: (result: DropResult) => void;
}

export default function SortableList({ items, onDragEnd }: SortableListProps) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={cn(
              'flex w-full flex-col items-center justify-center space-y-1 overflow-hidden p-1',
              snapshot.isDraggingOver && 'bg-gray-100',
            )}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(draggableProvider) => (
                  <div
                    ref={draggableProvider.innerRef}
                    className='w-full cursor-grab rounded-md bg-gray-100 p-2'
                    {...draggableProvider.draggableProps}
                    {...draggableProvider.dragHandleProps}
                    style={{
                      ...draggableProvider.draggableProps.style,
                      position: 'static',
                      opacity: 1,
                    }}
                  >
                    {item.node}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
