import { TreeDataItem, TreeView } from '@lupa/ui/components/shadcn/tree-view';
import {
  CompanyNode,
  EnterpriseSelectionType,
} from '@lupa/utils/validators/enterpriseValidators';
import { useRouter } from '@lupa/work/hooks/use-router';
import { paths } from '@lupa/work/paths';

import { IconBuilding, IconBuildings } from '@tabler/icons-react';

function transformToTreeData({
  companyNode,
}: {
  companyNode: CompanyNode;
}): TreeDataItem {
  const childrenStores = companyNode.stores.map((store) => ({
    id: store.id,
    name: store.name,
    icon: () => <IconBuilding className='h-3.5 w-3.5' />,
    onClick: () => {},
    metadata: { type: EnterpriseSelectionType.STORE },
  }));

  const childrenCompanies = companyNode.childrenCompanies.map((node) =>
    transformToTreeData({ companyNode: node }),
  );

  return {
    id: companyNode.id,
    name: companyNode.name,
    icon: () => <IconBuildings className='h-3.5 w-3.5' />,
    metadata: { type: EnterpriseSelectionType.COMPANY },
    children: [...childrenStores, ...childrenCompanies],
  };
}

export default function CompanyTreeCard({
  companyTree,
}: {
  companyTree: CompanyNode;
}) {
  const router = useRouter();
  const treeData = transformToTreeData({ companyNode: companyTree });

  const handleClick = (item: TreeDataItem | undefined) => {
    if (!item) {
      return;
    }

    const path =
      item.metadata?.type === EnterpriseSelectionType.STORE
        ? paths.admin.store(item.id)
        : paths.admin.company(item.id);
    router.push(path);
  };

  return (
    <div className='p-4'>
      <TreeView data={[treeData]} onSelectChange={handleClick} />
    </div>
  );
}
