export const paths = {
  index: '/',
  calendar: {
    index: '/calendar',
    flowChart: '/calendar?tab=flowChart',
    hospitalization: '/calendar?tab=hospitalization',
  },
  store: {
    edit: '/store/edit',
  },
  apps: {
    index: '/apps',
  },
  pets: {
    details: (petId: string) => `/pets/${petId}`,
    edit: (petId: string) => `/pets/${petId}/edit`,
    create: '/pets/create',
  },
  appointments: {
    index: '/appointments',
    details: (appointmentId: string) => `/appointments/${appointmentId}`,
    detailsAddPayments: (appointmentId: string) =>
      `/appointments/${appointmentId}?add-payment=open`,
    create: '/appointments/create',
  },
  invoices: {
    index: '/invoices',
    details: (invoiceId: string) => `/invoices/${invoiceId}`,
  },
  creditNotes: {
    index: '/credit-notes',
    details: (creditNoteId: string) => `/credit-notes/${creditNoteId}`,
  },
  social: {
    index: '/social',
    profile: '/social/profile',
    feed: '/social/feed',
  },
  employees: {
    index: '/employees',
    details: (employeeId: string) => `/employees/${employeeId}`,
    edit: (employeeId: string) => `/employees/${employeeId}/edit`,
    create: '/employees/create',
  },
  clients: {
    clients: '/clients?tab=clients',
    pets: '/clients?tab=pets',
    clinics: '/clients?tab=clinics',
    details: (clientId: string) => `/clients/${clientId}`,
    detailsAddPayments: (clientId: string) =>
      `/clients/${clientId}?add-payment=open`,
    edit: (clientId: string) => `/clients/${clientId}/edit`,
    create: '/clients/create',
    clinicDetails: (clinicId: string) => `/clients/clinic/${clinicId}`,
    clinicEdit: (clinicId: string) => `/clinics/${clinicId}/edit`,
    clinicCreate: '/clinics/create',
  },
  auth: {
    login: '/auth/login',
    register: 'https://lupapets.com/contact',
    forgotPassword: '/auth/forgot-password',
    confirmRegister: '/auth/confirm-register',
  },
  chat: '/chat',
  inventory: {
    index: '/inventory',
    wholesale: '/inventory?tab=wholesale',
    basket: '/inventory?tab=basket',
    orders: '/inventory?tab=orders',
    bulkEdit: '/inventory?tab=bulk-edit',
    create: '/inventory/create',
    processOrder: (orderId: string) => `/inventory/process-order/${orderId}`,
    edit: (productId: string) => `/inventory/${productId}/edit`,
  },
  insurance: {
    claims: '/claims',
  },
  debts: {
    index: '/debts',
  },
  diagnostics: {
    index: '/diagnostics',
  },
  clientCommunications: {
    index: '/client-comms',
    reminders: '/client-comms?tab=reminders',
  },
  settings: {
    index: '/settings',
    user: '/settings?tab=user',
    store: '/settings?tab=details',
    permissions: '/settings?tab=permissions',
    hours: '/settings?tab=hours',
    bookingQuestions: '/settings?tab=bookingQuestions',
    bookingConfirmation: '/settings?tab=bookingConfirmation',
    rota: '/settings?tab=rota',
    bank: '/settings?tab=bank',
    financials: '/settings?tab=financials',
    services: '/settings?tab=services',
    bundles: '/settings?tab=bundles',
    appointmentTypes: '/settings?tab=appointmentTypes',
    staffQualifications: '/settings?tab=staffQualifications',
    images: '/settings?tab=images',
    healthPlans: '/settings?tab=healthPlans',
    templates: '/settings?tab=templates',
    employees: '/settings?tab=employees',
    payments: '/settings?tab=payments',
    flowChart: '/settings?tab=flowChart',
    rooms: '/settings?tab=rooms',
    onlineBookingSettings: '/settings?tab=onlineBookingSettings',
    bookingForm: '/settings?tab=bookingForm',
    bookingPolicy: '/settings?tab=bookingPolicy',
    updatePassword: '/settings/update-password',
    integrationsXero: '/settings?tab=integrationsXero',
    lupaBilling: '/settings?tab=lupaBilling',
    integrationsEmails: '/settings?tab=integrationsEmails',
    integrationsSuppliers: '/settings?tab=integrationsSuppliers',
    printing: '/settings?tab=printing',
  },
  docs: 'https://material-kit-pro-react-docs.devias.io',
  notFound: '/404',
  unauthorized: '/401',
  analytics: { index: '/analytics' },
  admin: {
    index: '/admin',
    company: (companyId: string) => `/admin/company/${companyId}`,
    store: (storeId: string) => `/admin/store/${storeId}`,
    calendar: '/admin/calendar',
    analytics: '/admin/analytics',
  },
};
