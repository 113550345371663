import z from 'zod';

import {
  APPOINTMENT_CREATION_SOURCE,
  APPOINTMENT_NOTIFICATION_REASON,
  APPOINTMENT_PAYMENT_STATUS,
  APPOINTMENT_TYPE,
  DISCOUNT_TYPE,
  PET_SEX,
  PET_SPECIES,
} from '../enums';
import {
  billingProductValidationSchema,
  billingServiceValidationSchema,
} from './invoiceValidators';

export const createAppointmentValidatorData = z
  .object({
    client: z
      .object({
        id: z.string().min(1, 'Client is required'),
        full_name: z.string().nullish(),
      })
      .nullish(),
    newClient: z
      .object({
        first_name: z.string().max(100).min(1, 'First name is required'),
        last_name: z.string().max(100).min(1, 'Last name is required'),
        email: z.union([z.literal(''), z.string().email()]).nullish(),
        phone: z.string().nullish(),
      })
      .nullish(),
    pet: z
      .object({
        id: z.string().min(1, 'Pet is required'),
        name: z.string().nullish(),
      })
      .nullish(),
    newPet: z
      .object({
        name: z.string().max(100).min(1, 'Pet name is required'),
        species: z.nativeEnum(PET_SPECIES, {
          message: 'Pet species is required',
        }),
        breed: z.string().max(100).min(1, 'Pet breed is required'),
        sex: z.nativeEnum(PET_SEX),
        weight: z.number().nullish(),
        dob: z.string().nullish(),
      })
      .nullish(),
    employee: z.object(
      {
        id: z.string().min(1, 'Employee is required'),
        full_name: z.string().nullish(),
      },
      {
        message: 'Employee is required',
      },
    ),
    appointment: z.object({
      id: z.string().min(1, 'Appointment is required').optional(),
      visit_type: z.object(
        {
          id: z.string().min(1, 'Appointment type is required'),
          name: z.string().min(1, 'Appointment name is required'),
          category: z.nativeEnum(APPOINTMENT_TYPE),
        },
        {
          message: 'Appointment type is required',
        },
      ),
      status: z.string(),
      title: z.string().min(1, 'Title is required'),
      location: z
        .string()
        .max(255, 'Location must be 255 characters or shorter')
        .nullish(),
      client_notes: z.string().nullish(),
      booking_notes: z.string().nullish(),
      start: z.string().datetime({
        offset: true,
        message: 'Start date is required',
      }),
      end: z.string().datetime({
        offset: true,
        message: 'End date is required',
      }),
      multi_id: z.string().uuid().nullable(),
      created_from: z.nativeEnum(APPOINTMENT_CREATION_SOURCE).optional(),
      should_show_employee: z.boolean().optional(),
      should_show_date: z.boolean().optional(),
      questionnaire_responses: z.array(z.any()).nullish(),
      payment_status: z.nativeEnum(APPOINTMENT_PAYMENT_STATUS).optional(),
      payment_intent_id: z.string().optional(),
    }),
    room: z
      .object({
        id: z.string().uuid(),
        name: z.string().min(1, 'Room name is required'),
      })
      .nullish(),
    totalPrice: z.number(),
    apply_discount: z.boolean(),
    discount: z.number(),
    discount_type: z.nativeEnum(DISCOUNT_TYPE),
    services: z.array(billingServiceValidationSchema),
    products: z.array(billingProductValidationSchema),
    prescribed_products: z.array(billingProductValidationSchema).optional(),
    notifyUser: z.boolean(),
    notificationReason: z.nativeEnum(APPOINTMENT_NOTIFICATION_REASON).nullish(),
  })
  .superRefine((data, ctx) => {
    if (!data.client && !data.newClient) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['client'],
        message: 'Client cannot be empty.',
      });
    }

    if (!data.pet && !data.newPet) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['pet'],
        message: 'Pet cannot be empty.',
      });
    }
  });

export type CreateAppointmentValidatorDataType = z.infer<
  typeof createAppointmentValidatorData
>;

export const updateAppointmentValidatorData = z.object({
  employee: z
    .object({
      id: z.string().min(1, 'Employee is required'),
    })
    .optional(),
  appointment: z.object({
    visit_type: z
      .object({
        id: z.string().min(1, 'Appointment type is required'),
        name: z.string().min(1, 'Appointment type is required'),
        category: z.nativeEnum(APPOINTMENT_TYPE),
      })
      .optional(),
    status: z.string().optional(),
    title: z.string().optional(),
    notes: z.string().nullish(),
    location: z
      .string()
      .max(255, 'Location must be 255 characters or shorter')
      .nullish(),
    client_notes: z.string().nullish(),
    booking_notes: z.string().nullish(),
    start: z
      .string()
      .datetime({
        offset: true,
      })
      .optional(),
    end: z
      .string()
      .datetime({
        offset: true,
      })
      .optional(),
  }),
  room: z
    .object({
      id: z.string().uuid(),
      name: z.string().min(1, 'Room name is required'),
    })
    .nullish(),
  totalPrice: z.number().optional(),
  apply_discount: z.boolean().nullish(),
  discount: z.number().optional(),
  discount_type: z.nativeEnum(DISCOUNT_TYPE).optional(),
  services: z.array(billingServiceValidationSchema).optional(),
  products: z.array(billingProductValidationSchema).optional(),
  prescribed_products: z.array(billingProductValidationSchema).optional(),
  notifyUser: z.boolean().nullish(),
  notificationReason: z.nativeEnum(APPOINTMENT_NOTIFICATION_REASON).nullish(),
});

export type UpdateAppointmentBillingValidatorDataType = z.infer<
  typeof updateAppointmentBillingValidatorData
>;

export const updateAppointmentBillingValidatorData = z.object({
  apply_discount: z.boolean(),
  discount: z.number(),
  discount_type: z.nativeEnum(DISCOUNT_TYPE),
  services: z.array(billingServiceValidationSchema),
  products: z.array(billingProductValidationSchema),
  prescribed_products: z.array(billingProductValidationSchema),
});

export type UpdateAppointmentValidatorDataType = z.infer<
  typeof updateAppointmentValidatorData
>;
