import { useMemo } from 'react';

import { DataTable } from '@lupa/ui/components/shadcn/data-table';
import { MultiSelect } from '@lupa/ui/components/shadcn/multi-select';

import { ColumnDef } from '@tanstack/react-table';

interface Employee {
  id: number;
  name: string;
  roles: string[];
}

interface EmployeeRolesTableProps {
  employees: Employee[];
  allRoles: string[];
  onUpdateEmployee: (employeeId: number, updatedRoles: string[]) => void;
}

export default function EmployeeRolesTable({
  employees,
  allRoles,
  onUpdateEmployee,
}: EmployeeRolesTableProps) {
  const columns: ColumnDef<Employee>[] = useMemo(
    () => [
      {
        id: 'name',
        header: () => <div>Employee</div>,
        cell: ({ row: { original: employee } }) => (
          <div className='font-medium'>{employee.name}</div>
        ),
      },
      {
        id: 'roles',
        header: () => <div>Roles</div>,
        cell: ({ row: { original: employee } }) => (
          <MultiSelect
            options={allRoles.map((role) => ({
              label: role,
              value: role,
            }))}
            onValueChange={(selectedRoles) =>
              onUpdateEmployee(employee.id, selectedRoles)
            }
            defaultValue={employee.roles}
            placeholder='Select roles'
            variant='inverted'
            animation={2}
          />
        ),
      },
    ],
    [allRoles, onUpdateEmployee],
  );

  return (
    <div className='w-full'>
      <DataTable
        columns={columns}
        data={employees}
        className='mt-2'
        rowClassName='border-b-0'
        theaderClassName='uppercase'
      />
    </div>
  );
}
