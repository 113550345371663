import { EMPLOYEES_WORKING_HOURS_STATUS } from '@lupa/utils/enums';
import { TrpcRouterOutputs } from '@lupa/work/lib/trpc';

import { blue, deepPurple, orange } from '@mui/material/colors';

import {
  addDays,
  differenceInMinutes,
  format,
  isBefore,
  parse,
} from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { ByWeekday, RRule } from 'rrule';

type TimeInterval = {
  open: string; // HH:mm
  close: string; // HH:mm
};

type DayWorkingHours = {
  start: string | null;
  breakTime: string | null;
  end: string | null;
  breakDuration: number | null;
};

export type EmployeeRota = Pick<
  TrpcRouterOutputs['employees']['getEmployeesRotas'][0],
  'id' | 'working_hours' | 'avatar_url' | 'full_name' | 'role'
>;

export type EmployeeRotaItem =
  TrpcRouterOutputs['employees']['getEmployeesRotas'][0]['working_hours'][0];

type OpeningTime = {
  day: string;
  times: TimeInterval[];
};

export const displayName = (fullName: string): string => {
  if (!fullName) {
    return '';
  }

  const [firstName, lastName] = fullName.split(/\s+/);

  const lastNameInitial = lastName?.[0];
  const lastNameInitialWithDot = lastNameInitial ? ` ${lastNameInitial}.` : '';

  return `${firstName}${lastNameInitialWithDot}`;
};

export const getWorkingHours = (
  timeIntervals: TimeInterval[],
): DayWorkingHours => {
  if (!timeIntervals || timeIntervals.length === 0) {
    return { start: null, breakTime: null, end: null, breakDuration: null };
  }

  const start = timeIntervals[0].open;
  const end = timeIntervals[timeIntervals.length - 1].close;

  let breakTime = null;
  let breakDuration = null;
  if (timeIntervals.length > 1) {
    breakTime = timeIntervals[0].close;
    const breakStart = parse(breakTime, 'HH:mm', new Date());
    const breakEnd = parse(timeIntervals[1].open, 'HH:mm', new Date());
    breakDuration = differenceInMinutes(breakEnd, breakStart);
  }

  return { start, breakTime, end, breakDuration };
};

export const getStoreWorkingHours = (
  storeOpeningTimes: {
    day: string;
    times: {
      open: string;
      close: string;
    }[];
  }[],
  dayName: string,
): string | DayWorkingHours => {
  const dayData = storeOpeningTimes.find((day) => day.day === dayName);
  if (!dayData || dayData.times.length === 0) {
    return 'closed';
  }

  return getWorkingHours(dayData.times);
};

export const getEmployeeExceptionOnDay = (
  employee: EmployeeRota,
  day: Date,
): Nullable<EmployeeRotaItem> => {
  if (!employee.working_hours) {
    return null;
  }

  const formattedDay = format(day, 'dd-MM-yyyy');
  const sortedWorkingHours = employee.working_hours.sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  for (const wh of sortedWorkingHours) {
    if (wh.rrule != null) {
      const rule = new RRule({
        freq: wh.rrule.freq,
        interval: wh.rrule.interval,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        byweekday: wh.rrule.byweekday as ByWeekday[],
        dtstart: toZonedTime(new Date(wh.rrule.dtstart), 'UTC'),
        until: toZonedTime(new Date(wh.rrule.until), 'UTC'),
      });

      const dates = rule.all();

      if (dates.some((date) => format(date, 'dd-MM-yyyy') === formattedDay)) {
        return wh;
      }
    } else if (
      wh.date &&
      format(new Date(wh.date), 'dd-MM-yyyy') === formattedDay
    ) {
      return wh;
    }
  }

  return null;
};

const parseTime = (timeStr: string): Date =>
  parse(timeStr, 'HH:mm', new Date());

// Given the working hours in a day, it flips them to return
// the non-working hours.
export const flipIntervalsOverDay = (
  intervals: TimeInterval[],
): TimeInterval[] => {
  const result: TimeInterval[] = [];
  const startOfDay = '00:00';
  const endOfDay = '23:59';

  // Edge cases
  if (intervals.length === 0) {
    return [{ open: startOfDay, close: endOfDay }];
  }

  if (
    intervals.length === 1 &&
    intervals[0].open === startOfDay &&
    intervals[0].close === endOfDay
  ) {
    return [];
  }

  let dayPointer = startOfDay;

  intervals.forEach((interval) => {
    const dayPointerTime = parseTime(dayPointer);

    if (isBefore(parseTime(interval.open), dayPointerTime)) {
      return result;
    }

    result.push({
      open: dayPointer,
      close: interval.open,
    });

    dayPointer = interval.close;
  });

  if (isBefore(parseTime(dayPointer), parseTime(endOfDay))) {
    result.push({
      open: dayPointer,
      close: endOfDay,
    });
  }

  return result;
};

export const getStoreEndOfWeekDate = (
  startOfWeek: Date,
  storeOpeningTimes: OpeningTime[],
): Date => {
  let lastOpenDayIndex = -1;

  storeOpeningTimes.forEach((day, index) => {
    if (day.times.length > 0) {
      lastOpenDayIndex = index;
    }
  });

  if (lastOpenDayIndex === -1) {
    return new Date();
  }

  return addDays(startOfWeek, lastOpenDayIndex);
};

export const dayNameToNumber = (dayName: string): number => {
  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return dayNames.indexOf(dayName);
};

export const getExceptionTypeColour = (
  type: EMPLOYEES_WORKING_HOURS_STATUS,
): string => {
  switch (type) {
    case EMPLOYEES_WORKING_HOURS_STATUS.AVAILABLE:
      return deepPurple[50];
    case EMPLOYEES_WORKING_HOURS_STATUS.VACATION:
      return blue[50];
    case EMPLOYEES_WORKING_HOURS_STATUS.SICK:
      return orange[50];
    case EMPLOYEES_WORKING_HOURS_STATUS.UNAVAILABLE:
      return 'white';
    default:
      return '#f2f2f2';
  }
};
