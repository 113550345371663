import { CompanyNode } from '@lupa/utils/validators/enterpriseValidators';

import CompanyTreeCard from './CompanyTreeCard';

interface EnterpriseSidebarProps {
  companyTree: CompanyNode;
}

export default function EnterpriseSidebar({
  companyTree,
}: EnterpriseSidebarProps) {
  return (
    <aside className='bg-background fixed inset-y-0 left-0 z-20 flex w-[368px] flex-col border-r transition-transform md:sticky'>
      <div className='flex items-center justify-between p-4'>
        <h2 className='text-lg font-semibold'>Enterprise</h2>
      </div>

      <div className='w-[368px] flex-1 overflow-auto'>
        <CompanyTreeCard companyTree={companyTree} />
      </div>
    </aside>
  );
}
