import { cn } from '@lupa/ui/lib/utils';
import { formatCurrency } from '@lupa/work/utils/i18n';

export default function BillingSummaryRow({
  label,
  sublabel,
  value,
  isDiscount = false,
  dashInsteadOfZero = false,
  isTotal = false,
}: {
  label: string;
  sublabel?: string;
  value: number;
  isDiscount?: boolean;
  dashInsteadOfZero?: boolean;
  isTotal?: boolean;
}) {
  let displayValue = '';
  if (dashInsteadOfZero && value === 0) {
    displayValue = '-';
  } else if (isDiscount) {
    displayValue = `-${formatCurrency(value)}`;
  } else {
    displayValue = formatCurrency(value);
  }

  return (
    <div className='flex items-center justify-between space-x-4 px-4'>
      <div className='flex flex-col'>
        <p className={cn('text-sm font-semibold', isTotal && 'text-primary')}>
          {label}
        </p>
        {sublabel && <p className='text-xs text-gray-500'>{sublabel}</p>}
      </div>
      <p className='text-sm font-semibold'>{displayValue}</p>
    </div>
  );
}
