import { Suspense, lazy, useEffect, useMemo, useState } from 'react';

import Loading from '@lupa/ui/components/Loading';
import DatePickerWithRange from '@lupa/ui/components/input/DatePickerWithRange';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@lupa/ui/components/shadcn/tabs';
import { PERMISSION_CATEGORY } from '@lupa/utils/enums';
import { trpc } from '@lupa/work/lib/trpc';
import { paths } from '@lupa/work/paths';
import { hasEmployeePermissions } from '@lupa/work/utils/store-utils';

import { Alert, AlertTitle, Button, Stack } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';

import { differenceInDays, format, subDays } from 'date-fns';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { analyticsDatesAtom, analyticsLimitedDatesAtom } from '../atoms/atoms';

const AnalyticsDashboards = lazy(
  () => import('@lupa/work/components/analytics/AnalyticsDashboards'),
);

const AnalyticsTables = lazy(
  () => import('@lupa/work/components/analytics/AnalyticsTables'),
);

interface AdminAnalyticsPageProps {
  selectedStoresId: string;
}

export default function AdminAnalyticsPage({
  selectedStoresId,
}: AdminAnalyticsPageProps) {
  const [storeIds, setStoreIds] = useState<string[]>([selectedStoresId]);
  const [dates, setDates] = useAtom(
    hasEmployeePermissions(PERMISSION_CATEGORY.ANALYTICS)
      ? analyticsDatesAtom
      : analyticsLimitedDatesAtom,
  );

  const navigate = useNavigate();
  const { data, isPending, isFetching } = trpc.store.getAnalytics.useQuery(
    {
      date: format(new Date(dates.start), 'dd-MM-yyyy'),
      endDate: format(new Date(dates.end), 'dd-MM-yyyy'),
      storeIds: storeIds,
    },
    {
      enabled: storeIds.length > 0,
      placeholderData: keepPreviousData,
    },
  );

  const usersPermission = useMemo(() => {
    if (hasEmployeePermissions(PERMISSION_CATEGORY.ANALYTICS)) {
      return 'allowed' as const;
    }

    if (hasEmployeePermissions(PERMISSION_CATEGORY.ANALYTICS_LIMITED)) {
      if (differenceInDays(dates.end, dates.start) > 7) {
        return 'limited_denied' as const;
      }

      return 'allowed' as const;
    }

    navigate(paths.unauthorized);
  }, [dates]);

  useEffect(() => {
    setStoreIds([selectedStoresId]);
  }, [selectedStoresId]);

  if (isPending || !data) {
    return <Loading />;
  }

  // TODO: Extract permission logic to a common utils between backend & frontend + build common UI for it
  if (usersPermission === 'limited_denied') {
    return (
      <Stack direction='column' alignItems='center' mt={2} gap={2}>
        <Alert severity='error'>
          <AlertTitle>Limited Access</AlertTitle>
          You do not have permission to view this data. Press the button below
          to view the allowed date (last 7 days).
        </Alert>

        <Button
          onClick={() => {
            setDates({ start: subDays(new Date(), 7), end: new Date() });
          }}
        >
          View Last 7 Days
        </Button>
      </Stack>
    );
  }

  return (
    <Stack direction='column'>
      <div className='flex w-full justify-end'>
        <DatePickerWithRange
          dates={{
            from: new Date(dates.start),
            to: new Date(dates.end),
          }}
          setDates={(value) => {
            setDates({
              start: value.from,
              end: value.to,
            });
          }}
        />
      </div>
      <Tabs defaultValue='tables'>
        <div className='flex w-full justify-center'>
          <TabsList>
            <TabsTrigger className='w-[160px]' value='dashboards'>
              Dashboards
            </TabsTrigger>
            <TabsTrigger className='w-[160px]' value='tables'>
              Tables
            </TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value='dashboards'>
          <div className='flex w-full flex-col gap-1'>
            <Suspense fallback={<Loading />}>
              <AnalyticsDashboards
                key={storeIds.join(',')}
                data={data}
                dates={dates}
                isFetching={isFetching}
                selectedStoresIds={storeIds}
              />
            </Suspense>
          </div>
        </TabsContent>

        <TabsContent value='tables'>
          <div className='flex w-full flex-col gap-1'>
            <Suspense fallback={<Loading />}>
              <AnalyticsTables key={storeIds.join(',')} data={data} />
            </Suspense>
          </div>
        </TabsContent>
      </Tabs>
    </Stack>
  );
}
