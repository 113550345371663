import TooltipButton from '@lupa/ui/components/TooltipButton';
import NumberInput from '@lupa/ui/components/form/NumberInput';
import { Badge } from '@lupa/ui/components/shadcn/badge';
import { Button } from '@lupa/ui/components/shadcn/button';
import { FormField } from '@lupa/ui/components/shadcn/form';
import { TableCell, TableRow } from '@lupa/ui/components/shadcn/table';
import { cn } from '@lupa/ui/lib/utils';
import { clearErrorAndSetValue } from '@lupa/ui/utils/formikUtils';
import { DISCOUNT_TYPE } from '@lupa/utils/enums';

import { IconCertificate, IconTrash } from '@tabler/icons-react';

import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import SearchProducts from '../search/SearchProducts';
import type { UpsertBillingItemsFormType } from './UpsertBillingItemsForm';
import billingItemsDataUtils from './upsertBillingItemsFormUtils';

export type PrescribedProductField = UseFieldArrayReturn<
  UpsertBillingItemsFormType,
  'prescribed_products'
>['fields'][number];

interface UpsertBillingPrescribedProductFormProps {
  form: UseFormReturn<UpsertBillingItemsFormType>;
  product: {
    type: 'prescribed_product';
    idx: number;
    data: PrescribedProductField;
  };
  onDelete: (idx: number) => void;
  onPrescriptionClick: (index: number) => void;
}

export default function UpsertBillingPrescribedProductForm({
  form,
  product,
  onDelete,
  onPrescriptionClick,
}: UpsertBillingPrescribedProductFormProps) {
  return (
    <TableRow key={product.data.id}>
      <TableCell className='font-medium'>
        <div className='flex flex-col items-start gap-1'>
          <FormField
            control={form.control}
            name={`prescribed_products.${product.idx}.name` as const}
            render={({ field: { ref: _ref, ...fieldProps } }) => {
              return (
                <SearchProducts
                  {...fieldProps}
                  value={
                    fieldProps.value
                      ? {
                          id: fieldProps.value,
                          label: fieldProps.value,
                        }
                      : null
                  }
                  onChange={(v) => {
                    clearErrorAndSetValue(
                      form,
                      `prescribed_products.${product.idx}.name`,
                      v.label,
                    );
                  }}
                  onOptionSelected={(v) => {
                    clearErrorAndSetValue(
                      form,
                      `prescribed_products.${product.idx}`,
                      {
                        id: crypto.randomUUID(),
                        product_id: v.id,
                        name: v.label,
                        price: 0,
                        quantity: 1,
                        unit_price: 0,
                        vat_percentage: v.vat_percentage,
                        discount_type: DISCOUNT_TYPE.PERCENTAGE,
                        discount: 0,
                        created_at: new Date().toISOString(),
                      },
                    );
                  }}
                  freeSolo
                  required={false}
                  error={
                    form.formState.errors.prescribed_products?.[product.idx]
                      ?.name?.message
                  }
                  className='min-w-[300px]'
                />
              );
            }}
          />
          <Badge variant='outline'>Prescribed Product</Badge>
        </div>
      </TableCell>

      <TableCell>
        <FormField
          control={form.control}
          name={`prescribed_products.${product.idx}.quantity` as const}
          render={({ field: { ref: _ref, ...fieldProps } }) => {
            return (
              <NumberInput
                {...fieldProps}
                required={true}
                afterValueChange={(newQuantity) => {
                  billingItemsDataUtils.onProductQuantityChange({
                    form,
                    productIdx: product.idx,
                    newMeasureUnit: undefined,
                    newQuantity,
                  });
                }}
                error={
                  form.formState.errors.prescribed_products?.[product.idx]
                    ?.quantity?.message
                }
                className='w-[120px]'
              />
            );
          }}
        />
      </TableCell>

      <TableCell></TableCell>

      <TableCell></TableCell>

      <TableCell className='text-right'>
        <div className='flex flex-row justify-end gap-1'>
          <TooltipButton
            tooltip='Prescription'
            variant='ghost'
            size='icon'
            onClick={() => {
              onPrescriptionClick(product.idx);
            }}
          >
            <IconCertificate
              className={cn({
                'text-primary':
                  form.watch(
                    `prescribed_products.${product.idx}.prescription_id`,
                  ) != null,
              })}
            />
          </TooltipButton>

          <Button
            variant='destructiveGhost'
            size='icon'
            onClick={() => {
              onDelete(product.idx);
            }}
          >
            <IconTrash />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
}
