import React, { ComponentProps, useState } from 'react';

import AutocompleteInput from '@lupa/ui/components/form/AutocompleteInput';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';

type Product = NonNullable<
  TrpcRouterOutputs['products']['searchProducts']
>[number];

type SearchProductsProps<
  TFreeSolo extends boolean = false,
  TClearable extends boolean = false,
> = Omit<
  ComponentProps<typeof AutocompleteInput<Product, TFreeSolo, TClearable>>,
  'options'
>;

export default function SearchProducts<
  TFreeSolo extends boolean = false,
  TClearable extends boolean = false,
>({ ...props }: SearchProductsProps<TFreeSolo, TClearable>) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string | undefined>(undefined);

  const { data, isLoading } = trpc.products.searchProducts.useQuery(
    {
      query,
    },
    {
      enabled: open,
    },
  );

  return (
    <AutocompleteInput<Product, TFreeSolo, TClearable>
      {...props}
      options={data ?? []}
      loading={isLoading}
      onOpenChange={(o) => {
        setOpen(o);
      }}
      onCommandValueChange={(v) => {
        setQuery(v);
      }}
      shouldFilter={false}
    />
  );
}
