import React, { ComponentProps, useState } from 'react';

import AutocompleteInput from '@lupa/ui/components/form/AutocompleteInput';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';
import { formatCurrency } from '@lupa/work/utils/i18n';

type VisitType = NonNullable<
  TrpcRouterOutputs['store']['searchVisitTypes']
>[number];

type SearchVisitTypesProps<
  TFreeSolo extends boolean = false,
  TClearable extends boolean = false,
> = Omit<
  ComponentProps<typeof AutocompleteInput<VisitType, TFreeSolo, TClearable>>,
  'options'
>;

export default function SearchVisitTypes<
  TFreeSolo extends boolean = false,
  TClearable extends boolean = false,
>({ ...props }: SearchVisitTypesProps<TFreeSolo, TClearable>) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string | undefined>(undefined);

  const { data, isLoading } = trpc.store.searchVisitTypes.useQuery(
    {
      query,
    },
    {
      enabled: open,
    },
  );

  return (
    <AutocompleteInput<VisitType, TFreeSolo, TClearable>
      {...props}
      options={
        data?.map((d) => ({
          ...d,
          subtitle: formatCurrency(d.price),
        })) ?? []
      }
      loading={isLoading}
      onOpenChange={(o) => {
        setOpen(o);
      }}
      onCommandValueChange={(v) => {
        setQuery(v);
      }}
      shouldFilter={false}
    />
  );
}
