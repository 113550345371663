import { useMemo } from 'react';

import DialogSection from '@lupa/ui/components/DialogSection';
import CheckboxInput from '@lupa/ui/components/form/CheckboxInput';
import NumberWithOptionsInput from '@lupa/ui/components/form/NumberWithOptionsInput';
import { Button } from '@lupa/ui/components/shadcn/button';
import { Form, FormField } from '@lupa/ui/components/shadcn/form';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@lupa/ui/components/shadcn/table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@lupa/ui/components/shadcn/tooltip';
import { useDialog } from '@lupa/ui/hooks/use-dialog';
import { DISCOUNT_TYPE, PERMISSION_CATEGORY } from '@lupa/utils/enums';
import { MakeNonNullishFieldsNullable } from '@lupa/utils/types/utils';
import {
  BillingProductValidationSchemaType,
  CreateBillingItemsData,
} from '@lupa/utils/validators/invoiceValidators';
import { trpc } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import {
  getBillingInfoFromSearchedProduct,
  getBillingInfoFromSearchedService,
} from '@lupa/work/utils/billing-utils';
import {
  getIsVetStore,
  hasEmployeePermissions,
} from '@lupa/work/utils/store-utils';

import { IconCertificate } from '@tabler/icons-react';

import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { match } from 'ts-pattern';

import AppointmentUpsertPrescriptionDialog from '../appointments/AppointmentUpsertPrescriptionDialog';
import SearchInvoiceItems from './SearchInvoiceItems';
import UpsertBillingItemsSummaryForm from './UpsertBillingItemsSummaryForm';
import UpsertBillingPrescribedProductForm from './UpsertBillingPrescribedProductForm';
import UpsertBillingProductForm from './UpsertBillingProductForm';
import UpsertBillingServiceForm from './UpsertBillingServiceForm';

export type UpsertBillingItemsFormType =
  MakeNonNullishFieldsNullable<CreateBillingItemsData>;

interface UpsertBillingItemsFormProps {
  form: UseFormReturn<UpsertBillingItemsFormType>;
  extraFormFields?: React.ReactNode;
  shouldShowAddBundle?: boolean;
  prescription?:
    | {
        pet: { id: string; clientId: string };
        showPrescriptions: true;
      }
    | {
        showPrescriptions?: false;
        pet?: never;
      };
}

export default function UpsertBillingItemsForm({
  form,
  extraFormFields = null,
  shouldShowAddBundle = true,
  prescription,
}: UpsertBillingItemsFormProps) {
  const prescriptionDialog = useDialog<{
    product: BillingProductValidationSchemaType;
    petId: string;
    clientId: string;
    setProduct: (product: BillingProductValidationSchemaType) => void;
  }>();

  // Fetch health plan subscriptions for the pet and client to display possible
  // discounts for the selected items.
  const { data: availableAllowances } =
    trpc.healthPlans.getAvailableAllowances.useQuery(
      {
        petId: prescription?.pet?.id ?? '',
        clientId: prescription?.pet?.clientId ?? '',
      },
      {
        enabled:
          globalSingleton.currentStore.features?.health_plan_enabled &&
          prescription?.pet != null,
      },
    );

  const showPrescriptionDetails =
    prescription != null &&
    prescription.showPrescriptions === true &&
    getIsVetStore();

  const {
    fields: servicesFields,
    append: appendService,
    remove: removeService,
  } = useFieldArray({
    control: form.control,
    name: 'services',
  });

  const {
    fields: productsFields,
    append: appendProduct,
    remove: removeProduct,
  } = useFieldArray({
    control: form.control,
    name: 'products',
  });

  const {
    fields: prescribedProductsFields,
    append: appendPrescribedProduct,
    remove: removePrescribedProduct,
  } = useFieldArray({
    control: form.control,
    name: 'prescribed_products',
  });

  const items = useMemo(() => {
    return [
      ...servicesFields.map((service, idx) => ({
        type: 'service' as const,
        idx,
        data: service,
      })),
      ...productsFields.map((product, idx) => ({
        type: 'product' as const,
        idx,
        data: product,
      })),
      ...prescribedProductsFields.map((product, idx) => ({
        type: 'prescribed_product' as const,
        idx,
        data: product,
      })),
    ].sort((a, b) => {
      // TODO: Move this to be based on bundle_id for bundled lines
      return a.data.name.localeCompare(b.data.name);
    });
  }, [servicesFields, productsFields, prescribedProductsFields]);

  const hasDiscountsPermission = useMemo(
    () => hasEmployeePermissions(PERMISSION_CATEGORY.DISCOUNTS),
    [],
  );

  return (
    <>
      <Form {...form}>
        <div className='space-y-4'>
          <div className='flex flex-col gap-2'>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className='flex-grow'>Name</TableHead>
                  <TableHead className='w-[80px] text-center'>
                    Quantity
                  </TableHead>
                  <TableHead className='w-[80px] text-center'>
                    Discount
                  </TableHead>
                  <TableHead className='w-[80px] text-center'>Price</TableHead>
                  <TableHead className='w-[80px] text-right'>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {items.map((item) => {
                  return match(item)
                    .with({ type: 'service' }, (service) => (
                      <UpsertBillingServiceForm
                        key={service.data.id}
                        form={form}
                        service={service}
                        onDelete={removeService}
                        availableAllowances={
                          availableAllowances?.serviceAllowances ?? {}
                        }
                        hasDiscountsPermission={hasDiscountsPermission}
                      />
                    ))
                    .with({ type: 'product' }, (product) => (
                      <UpsertBillingProductForm
                        key={product.data.id}
                        form={form}
                        product={product}
                        onDelete={removeProduct}
                        showPrescriptionDetails={showPrescriptionDetails}
                        availableAllowances={
                          availableAllowances?.productAllowances ?? {}
                        }
                        onPrescriptionClick={() => {
                          if (showPrescriptionDetails) {
                            prescriptionDialog.handleOpen({
                              petId: prescription.pet.id,
                              clientId: prescription.pet.clientId,
                              product: form.getValues(
                                `products.${product.idx}`,
                              ),
                              setProduct: (updatedProduct) => {
                                form.setValue(
                                  `products.${product.idx}`,
                                  updatedProduct,
                                );
                              },
                            });
                          }
                        }}
                        hasDiscountsPermission={hasDiscountsPermission}
                      />
                    ))
                    .with({ type: 'prescribed_product' }, (product) => (
                      <UpsertBillingPrescribedProductForm
                        key={product.data.id}
                        form={form}
                        product={product}
                        onDelete={removePrescribedProduct}
                        onPrescriptionClick={() => {
                          if (showPrescriptionDetails) {
                            prescriptionDialog.handleOpen({
                              petId: prescription.pet.id,
                              clientId: prescription.pet.clientId,
                              product: form.getValues(
                                `prescribed_products.${product.idx}`,
                              ),
                              setProduct: (updatedProduct) => {
                                form.setValue(
                                  `prescribed_products.${product.idx}`,
                                  updatedProduct,
                                );
                              },
                            });
                          }
                        }}
                      />
                    ))
                    .exhaustive();
                })}
              </TableBody>
            </Table>
          </div>

          <div className='flex w-full flex-row items-center justify-center gap-2'>
            <SearchInvoiceItems
              shouldShowBundles={shouldShowAddBundle}
              onSelect={(selectedItem) => {
                match(selectedItem)
                  .with({ type: 'service' }, (service) => {
                    appendService(
                      getBillingInfoFromSearchedService(service.data),
                    );
                  })
                  .with({ type: 'product' }, (product) => {
                    appendProduct(
                      getBillingInfoFromSearchedProduct(product.data),
                    );
                  })
                  .with({ type: 'bundle' }, (bundle) => {
                    bundle.data.billing_services.forEach((service) => {
                      appendService({
                        id: crypto.randomUUID(),
                        service_id: service.service_id,
                        discount: service.discount,
                        discount_type: service.discount_type,
                        name: service.name,
                        price: service.price,
                        quantity: service.quantity,
                        unit_price: service.unit_price,
                        vat_percentage: service.vat_percentage,
                        service_detail: service.service_detail,
                      });
                    });

                    bundle.data.billing_products.forEach((product) => {
                      appendProduct({
                        id: crypto.randomUUID(),
                        product_id: product.product_id,
                        discount: product.discount,
                        discount_type: product.discount_type,
                        name: product.name,
                        price: product.price,
                        quantity: product.quantity,
                        unit_price: product.unit_price,
                        vat_percentage: product.vat_percentage,
                        product_detail: product.product_detail,
                      });
                    });
                  })
                  .exhaustive();
              }}
            />

            {showPrescriptionDetails && (
              <TooltipProvider>
                <Tooltip delayDuration={100}>
                  <TooltipTrigger asChild>
                    <Button
                      variant='outline'
                      size='sm'
                      onClick={() => {
                        appendPrescribedProduct({
                          id: crypto.randomUUID(),
                          name: '',
                          quantity: 1,
                          price: 0,
                          unit_price: 0,
                          discount: 0,
                          discount_type: DISCOUNT_TYPE.PERCENTAGE,
                          vat_percentage:
                            globalSingleton.currentStore.vat_percentage,
                          created_at: new Date().toISOString(),
                        });
                      }}
                    >
                      <IconCertificate />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    Add a prescribed only product to the invoice. Note that this
                    wont be billed.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>

          <div className='flex w-full justify-between'>
            <div>{extraFormFields}</div>

            <div className='flex flex-col gap-2'>
              <FormField
                control={form.control}
                name='apply_discount'
                render={({ field: { ref: _ref, ...fieldProps } }) => {
                  return (
                    <CheckboxInput
                      {...fieldProps}
                      label='Additional Discount'
                      afterValueChange={(checked) => {
                        if (!checked) {
                          form.setValue('discount_amount', 0);
                        }
                      }}
                      required={false}
                      error={form.formState.errors.apply_discount?.message}
                      disabled={!hasDiscountsPermission}
                      expandableField={
                        <NumberWithOptionsInput
                          value={{
                            amount: form.watch('discount_amount'),
                            option: form.watch('discount_type'),
                          }}
                          options={Object.values(DISCOUNT_TYPE).map((type) => ({
                            label: type,
                            value: type,
                          }))}
                          onChange={(discount) => {
                            form.setValue(
                              'discount_amount',
                              discount.amount ?? 0,
                            );
                            form.setValue(
                              'discount_type',
                              discount.option ?? DISCOUNT_TYPE.PERCENTAGE,
                            );
                          }}
                          onBlur={() => {
                            form.trigger('discount_amount');
                          }}
                          error={form.formState.errors.discount_amount?.message}
                          required={false}
                          className='w-full'
                          disabled={!hasDiscountsPermission}
                        />
                      }
                    />
                  );
                }}
              />

              <UpsertBillingItemsSummaryForm form={form} />
            </div>
          </div>
        </div>
      </Form>

      {prescriptionDialog.open && prescriptionDialog.data && (
        <DialogSection
          title='Prescription'
          open={prescriptionDialog.open}
          onClose={prescriptionDialog.handleClose}
          maxWidth='xl'
        >
          <AppointmentUpsertPrescriptionDialog
            product={prescriptionDialog.data.product}
            petId={prescriptionDialog.data.petId}
            clientId={prescriptionDialog.data.clientId}
            setProduct={prescriptionDialog.data.setProduct}
            handleClose={prescriptionDialog.handleClose}
          />
        </DialogSection>
      )}
    </>
  );
}
