import { useEffect, useMemo, useState } from 'react';

import { Checkbox } from '@lupa/ui/components/shadcn/checkbox';
import { DataTable } from '@lupa/ui/components/shadcn/data-table';
import { RESOURCE_PERMISSION } from '@lupa/utils/enums';
import { getResourcePermissionLabel } from '@lupa/work/utils/get-labels';

import { ColumnDef } from '@tanstack/react-table';

import SaveBottomBar from './SaveBottomBar';

interface RoleDefinitionTableProps {
  permissions: string[];
  roles: string[];
  onSave: (permissions: Record<string, Record<string, boolean>>) => void;
}

interface PermissionRow {
  permission: string;
  [key: string]: string | boolean; // For dynamic role columns
}

export default function RoleDefinitionTable({
  permissions,
  roles,
  onSave,
}: RoleDefinitionTableProps) {
  const [rolePermissions, setRolePermissions] = useState<
    Record<string, Record<string, boolean>>
  >({});

  useEffect(() => {
    setRolePermissions((prev) => {
      const updated = { ...prev };
      roles.forEach((role) => {
        if (!updated[role]) {
          updated[role] = {};
          permissions.forEach((permission) => {
            updated[role][permission] = false;
          });
        }
      });
      return updated;
    });
  }, [roles]);

  const [isDirty, setIsDirty] = useState(false);

  const handlePermissionChange = (
    role: string,
    permission: string,
    checked: boolean,
  ) => {
    setRolePermissions((prev) => ({
      ...prev,
      [role]: {
        ...prev[role],
        [permission]: checked,
      },
    }));
    setIsDirty(true);
  };

  const data: PermissionRow[] = useMemo(
    () =>
      permissions.map((permission) => ({
        permission,
        ...roles.reduce(
          (acc, role) => ({
            ...acc,
            [role]: rolePermissions[role]?.[permission] ?? false,
          }),
          {},
        ),
      })),
    [permissions, roles, rolePermissions],
  );

  const columns: ColumnDef<PermissionRow>[] = useMemo(
    () => [
      {
        id: 'permission',
        header: 'Permissions',
        cell: ({ row }) => (
          <div className='w-[180px]'>
            {getResourcePermissionLabel(
              row.original.permission as RESOURCE_PERMISSION,
            )}
          </div>
        ),
      },
      ...roles.map(
        (role): ColumnDef<PermissionRow> => ({
          id: role,
          header: () => <div className='text-center'>{role}</div>,
          cell: ({ row }) => (
            <div className='text-center'>
              <Checkbox
                checked={!!row.original[role]}
                onCheckedChange={(checked) =>
                  handlePermissionChange(
                    role,
                    row.original.permission,
                    !!checked,
                  )
                }
              />
            </div>
          ),
        }),
      ),
    ],
    [roles, handlePermissionChange],
  );

  const handleSave = () => {
    onSave(rolePermissions);
    setIsDirty(false);
  };

  return (
    <div className='pb-16'>
      <div className='relative overflow-x-auto'>
        <DataTable
          columns={columns}
          data={data}
          className='mt-2'
          rowClassName='border-b'
          theaderClassName='uppercase'
        />
      </div>
      <SaveBottomBar onSave={handleSave} isDirty={isDirty} />
    </div>
  );
}
