import { useMemo } from 'react';

import { getBillingInfoFromItems } from '@lupa/utils/billingUtils';
import {
  billingProductValidationSchema,
  billingServiceValidationSchema,
} from '@lupa/utils/validators/invoiceValidators';

import { UseFormReturn, useWatch } from 'react-hook-form';

import BillingSummary from './BillingSummary';
import type { UpsertBillingItemsFormType } from './UpsertBillingItemsForm';

interface UpsertBillingItemsSummaryFormProps {
  form: UseFormReturn<UpsertBillingItemsFormType>;
}

export default function UpsertBillingItemsSummaryForm({
  form,
}: UpsertBillingItemsSummaryFormProps) {
  const { services, products, apply_discount, discount_amount, discount_type } =
    useWatch({
      control: form.control,
    });

  const summaryData = useMemo(() => {
    const parsedServices = (services ?? [])
      .map((service) => billingServiceValidationSchema.safeParse(service))
      .filter((result) => result.success)
      .map((result) => result.data);

    const parsedProducts = (products ?? [])
      .map((product) => billingProductValidationSchema.safeParse(product))
      .filter((result) => result.success)
      .map((result) => result.data);

    return getBillingInfoFromItems({
      items: [...parsedServices, ...parsedProducts],
      hasGlobalDiscount: apply_discount,
      globalDiscount: discount_amount ?? undefined,
      globalDiscountType: discount_type ?? undefined,
    });
  }, [services, products, apply_discount, discount_amount, discount_type]);

  return <BillingSummary {...summaryData} />;
}
