import { cn } from '@lupa/ui/lib/utils';

interface FormHelperTextProps {
  error?: string;
  helperText?: string;
}

export default function FormHelperText({
  error,
  helperText,
}: FormHelperTextProps) {
  if (!error && !helperText) {
    return null;
  }

  return (
    <div className='flex flex-col gap-1'>
      <p
        className={cn('text-destructive visible text-xs', {
          invisible: !error,
        })}
        role='alert'
        aria-live='polite'
      >
        {error}
      </p>

      {helperText && (
        <p className='text-xs' role='status' aria-live='polite'>
          {helperText}
        </p>
      )}
    </div>
  );
}
