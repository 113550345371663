import { useState } from 'react';

import { Button } from '@lupa/ui/components/shadcn/button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@lupa/ui/components/shadcn/collapsible';

import { IconSelector } from '@tabler/icons-react';

import BillingSummaryRow from './BillingSummaryRow';

interface BillingSummaryProps {
  totalPrice: number;
  totalNetPreDiscount: number;
  totalDiscount: number;
  totalGross: number;
  totalVat: number;
}

export default function BillingSummary({
  totalPrice,
  totalNetPreDiscount,
  totalDiscount,
  totalGross,
  totalVat,
}: BillingSummaryProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className='w-[350px] space-y-2'
    >
      <div className='flex items-center justify-between space-x-4 px-4'>
        <h4 className='text-lg font-semibold'>Billing Summary</h4>

        <CollapsibleTrigger asChild>
          <Button variant='ghost' size='sm'>
            <IconSelector className='h-4 w-4' />
          </Button>
        </CollapsibleTrigger>
      </div>

      <CollapsibleContent className='space-y-2'>
        <BillingSummaryRow
          label='Net Total'
          sublabel='(pre-discount)'
          value={totalNetPreDiscount}
        />
        <BillingSummaryRow
          label='Discount'
          value={totalDiscount}
          dashInsteadOfZero
          isDiscount
        />
        <BillingSummaryRow
          label='Subtotal'
          sublabel='(excl. VAT)'
          value={totalGross}
        />
        <BillingSummaryRow label='VAT' value={totalVat} />
      </CollapsibleContent>

      <BillingSummaryRow
        label='Total Price'
        sublabel='(incl. VAT)'
        value={totalPrice}
        isTotal
      />
    </Collapsible>
  );
}
