import { useState } from 'react';

import SortableList from '@lupa/ui/components/SortableList';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@lupa/ui/components/shadcn/avatar';
import { Button } from '@lupa/ui/components/shadcn/button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@lupa/ui/components/shadcn/collapsible';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@lupa/ui/components/shadcn/popover';
import { Tabs, TabsList, TabsTrigger } from '@lupa/ui/components/shadcn/tabs';
import { capitalize } from '@lupa/ui/utils/stringUtils';
import { getInitials, stringToColor } from '@lupa/utils/stringUtils';
import { getImagePublicUrl } from '@lupa/work/utils/get-image-public-url';
import {
  CALENDAR_COLOUR_TYPE,
  CALENDAR_MODE_TYPE,
  CALENDAR_TIME_TYPE,
  CALENDAR_VIEW_TYPE,
} from '@lupa/work/utils/local-enums';

import { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconSelector } from '@tabler/icons-react';

import { useAtom, useSetAtom } from 'jotai';
import { SlidersHorizontalIcon } from 'lucide-react';

import {
  mainCalendarColourType,
  mainCalendarResourcesOrderAtom,
} from '../../atoms/atoms';
import type { CalendarResource } from './CalendarToolbar';

const getView = (
  viewModeType: CALENDAR_MODE_TYPE,
  viewTimeType: CALENDAR_TIME_TYPE,
) => {
  if (viewTimeType === CALENDAR_TIME_TYPE.WEEK) {
    return viewModeType === CALENDAR_MODE_TYPE.MERGED
      ? CALENDAR_VIEW_TYPE.TIME_GRID_WEEK
      : CALENDAR_VIEW_TYPE.RESOURCE_TIME_GRID_WEEK;
  }

  if (viewTimeType === CALENDAR_TIME_TYPE.DAY) {
    return viewModeType === CALENDAR_MODE_TYPE.MERGED
      ? CALENDAR_VIEW_TYPE.TIME_GRID_DAY
      : CALENDAR_VIEW_TYPE.RESOURCE_TIME_GRID_DAY;
  }

  if (viewTimeType === CALENDAR_TIME_TYPE.THREE_DAYS) {
    return viewModeType === CALENDAR_MODE_TYPE.MERGED
      ? CALENDAR_VIEW_TYPE.TIME_GRID_THREE_DAYS
      : CALENDAR_VIEW_TYPE.RESOURCE_TIME_GRID_THREE_DAYS;
  }

  return viewModeType === CALENDAR_MODE_TYPE.MERGED
    ? CALENDAR_VIEW_TYPE.RESOURCE_TIMELINE_WEEK
    : CALENDAR_VIEW_TYPE.RESOURCE_TIMELINE_WEEK;
};

interface CalendarToolbarSettingsProps {
  view: CALENDAR_VIEW_TYPE;
  onViewChange: (view: CALENDAR_VIEW_TYPE) => void;
  isThreeDaysView: boolean;
  allResources: CalendarResource[];
}

export default function CalendarToolbarSettings({
  view,
  onViewChange,
  isThreeDaysView,
  allResources,
}: CalendarToolbarSettingsProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [colourType, setColourType] = useAtom(mainCalendarColourType);
  const [viewModeType, setViewModeType] = useState<CALENDAR_MODE_TYPE>(
    view === CALENDAR_VIEW_TYPE.TIME_GRID_WEEK ||
      view === CALENDAR_VIEW_TYPE.TIME_GRID_THREE_DAYS
      ? CALENDAR_MODE_TYPE.MERGED
      : CALENDAR_MODE_TYPE.SPLIT,
  );
  const [viewTimeType, setViewTimeType] = useState<CALENDAR_TIME_TYPE>(
    view.includes('Timeline')
      ? CALENDAR_TIME_TYPE.TIMELINE
      : view.includes('Week')
        ? CALENDAR_TIME_TYPE.WEEK
        : view.includes('Three')
          ? CALENDAR_TIME_TYPE.THREE_DAYS
          : CALENDAR_TIME_TYPE.DAY,
  );
  const [isSortExpanded, setIsSortExpanded] = useState(false);
  const setResourcesOrder = useSetAtom(mainCalendarResourcesOrderAtom);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button size='sm' variant='secondary'>
          <SlidersHorizontalIcon />
          Settings
        </Button>
      </PopoverTrigger>
      <PopoverContent align={mdUp ? 'end' : 'center'}>
        <div className='flex flex-col gap-4'>
          {!isThreeDaysView && (
            <div>
              <h4 className='ml-1 pb-1 text-sm font-medium text-gray-800'>
                Time Range
              </h4>
              <Tabs
                value={viewTimeType}
                onValueChange={(value) => {
                  if (value === 'timeline' && viewModeType === 'merged') {
                    setViewModeType(CALENDAR_MODE_TYPE.SPLIT);
                  }

                  if (value === 'timeline' && colourType === 'employee') {
                    setColourType(CALENDAR_COLOUR_TYPE.CATEGORY);
                  }

                  setViewTimeType(value as CALENDAR_TIME_TYPE); // eslint-disable-line @typescript-eslint/consistent-type-assertions
                  onViewChange(
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    getView(viewModeType, value as CALENDAR_TIME_TYPE),
                  );
                }}
              >
                <TabsList>
                  <TabsTrigger value={CALENDAR_TIME_TYPE.DAY}>
                    {capitalize(CALENDAR_TIME_TYPE.DAY)}
                  </TabsTrigger>
                  {isThreeDaysView && (
                    <TabsTrigger value={CALENDAR_TIME_TYPE.THREE_DAYS}>
                      {capitalize(CALENDAR_TIME_TYPE.THREE_DAYS)}
                    </TabsTrigger>
                  )}
                  <TabsTrigger value={CALENDAR_TIME_TYPE.WEEK}>
                    {capitalize(CALENDAR_TIME_TYPE.WEEK)}
                  </TabsTrigger>
                  <TabsTrigger value={CALENDAR_TIME_TYPE.TIMELINE}>
                    Continuous
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          )}

          <div>
            <h4 className='ml-1 pb-1 text-sm font-medium text-gray-800'>
              Calendar Mode
            </h4>
            <Tabs
              value={viewModeType}
              onValueChange={(newValue) => {
                setViewModeType(newValue as CALENDAR_MODE_TYPE); // eslint-disable-line @typescript-eslint/consistent-type-assertions
                onViewChange(
                  getView(newValue as CALENDAR_MODE_TYPE, viewTimeType), // eslint-disable-line @typescript-eslint/consistent-type-assertions
                );
              }}
            >
              <TabsList>
                <TabsTrigger
                  value={CALENDAR_MODE_TYPE.MERGED}
                  disabled={viewTimeType === 'timeline'}
                >
                  {capitalize(CALENDAR_MODE_TYPE.MERGED)}
                </TabsTrigger>
                <TabsTrigger
                  value={CALENDAR_MODE_TYPE.SPLIT}
                  disabled={viewTimeType === 'timeline'}
                >
                  Individual
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>

          {!isThreeDaysView && (
            <div>
              <h4 className='ml-1 pb-1 text-sm font-medium text-gray-800'>
                Colour By
              </h4>
              <Tabs
                value={colourType}
                onValueChange={(value) => {
                  setColourType(value as CALENDAR_COLOUR_TYPE); // eslint-disable-line @typescript-eslint/consistent-type-assertions
                }}
              >
                <TabsList>
                  <TabsTrigger value={CALENDAR_COLOUR_TYPE.EMPLOYEE}>
                    {capitalize(CALENDAR_COLOUR_TYPE.EMPLOYEE)}
                  </TabsTrigger>
                  <TabsTrigger value={CALENDAR_COLOUR_TYPE.CATEGORY}>
                    {capitalize(CALENDAR_COLOUR_TYPE.CATEGORY)}
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          )}

          <Collapsible
            open={isSortExpanded}
            onOpenChange={setIsSortExpanded}
            className='space-y-2'
          >
            <div className='flex items-center justify-between space-x-4'>
              <h4 className='ml-1 pb-1 text-sm font-medium text-gray-800'>
                Order
              </h4>

              <CollapsibleTrigger asChild>
                <Button variant='ghost' size='sm'>
                  <IconSelector className='h-4 w-4' />
                </Button>
              </CollapsibleTrigger>
            </div>

            <CollapsibleContent className='max-h-[300px] space-y-2 overflow-y-auto'>
              <SortableList
                items={allResources
                  .sort((a, b) => a.order - b.order)
                  .map((resource) => ({
                    id: resource.id,
                    node: (
                      <div className='flex items-center gap-2'>
                        <Avatar className='size-8'>
                          <AvatarImage
                            src={getImagePublicUrl(resource.avatar_url)}
                            alt={resource.label}
                          />
                          <AvatarFallback
                            backgroundColor={stringToColor(
                              resource.label ?? '',
                            )}
                          >
                            {getInitials(resource.label)}
                          </AvatarFallback>
                        </Avatar>

                        <p className='text-sm font-medium'>{resource.label}</p>
                      </div>
                    ),
                  }))}
                onDragEnd={(dragResult) => {
                  if (!dragResult.destination) {
                    return;
                  }

                  const items = Array.from(allResources);
                  const [reorderedItem] = items.splice(
                    dragResult.source.index,
                    1,
                  );
                  items.splice(dragResult.destination.index, 0, reorderedItem);

                  const newResourcesOrder = items.reduce<
                    Record<string, number>
                  >((acc, resource, index) => {
                    acc[resource.id] = index;
                    return acc;
                  }, {});

                  setResourcesOrder(newResourcesOrder);
                }}
              />
            </CollapsibleContent>
          </Collapsible>
        </div>
      </PopoverContent>
    </Popover>
  );
}
