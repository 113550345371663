import { type ReactNode } from 'react';

import { Switch } from '@lupa/ui/components/shadcn/switch';
import { cn } from '@lupa/ui/lib/utils';

import { FormItem } from '../shadcn/form';
import FormHelperText from './FormHelperText';
import FormLabel from './FormLabel';
import InputBaseProps from './InputBaseProps';

interface CheckboxInputProps
  extends InputBaseProps<boolean | undefined | null, boolean> {
  expandableField?: ReactNode;
}

export default function CheckboxInput({
  value,
  onChange,
  afterValueChange,
  label,
  required,
  error,
  helperText,
  expandableField,
  hint,
  className,
  ...props
}: CheckboxInputProps) {
  const inputId = `${label}-switch`;
  const expandableFieldId = `${label}-expandable-field`;
  return (
    <FormItem className={cn('space-y-1', className)}>
      <div className='bg-background border-input relative flex w-full items-start gap-2 rounded-lg border p-4 shadow-sm shadow-black/5'>
        <Switch
          className='order-1 h-5 w-8 [&_span]:size-4 [&_span]:data-[state=checked]:translate-x-3 rtl:[&_span]:data-[state=checked]:-translate-x-3'
          checked={value ?? false}
          onCheckedChange={(newValue) => {
            onChange(newValue);
            afterValueChange?.(newValue);
          }}
          aria-controls={expandableFieldId}
          {...props}
          id={inputId}
        />

        <div className='grow'>
          <div className='mt-0.5 grid gap-2'>
            <FormLabel
              htmlFor={inputId}
              label={label}
              required={required}
              hint={hint}
            />
            <FormHelperText error={error} helperText={helperText} />
          </div>

          {/* Expandable field */}
          {expandableField && (
            <div
              role='region'
              id={expandableFieldId}
              aria-labelledby={inputId}
              className='grid transition-all ease-in-out data-[state=collapsed]:grid-rows-[0fr] data-[state=expanded]:grid-rows-[1fr] data-[state=collapsed]:opacity-0 data-[state=expanded]:opacity-100'
              data-state={value ? 'expanded' : 'collapsed'}
            >
              <div className='pointer-events-none -m-2 overflow-hidden p-2'>
                <div className='pointer-events-auto mt-3'>
                  {expandableField}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </FormItem>
  );
}
