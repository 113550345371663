import { useCallback, useMemo, useState } from 'react';

import { Button } from '@lupa/ui/components/shadcn/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@lupa/ui/components/shadcn/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@lupa/ui/components/shadcn/popover';
import { cn } from '@lupa/ui/lib/utils';
import { getInitials, stringToColor } from '@lupa/utils/stringUtils';
import { assertNonNullish } from '@lupa/utils/types/utils';

import { IconCheck, IconChevronDown, IconX } from '@tabler/icons-react';

import { CommandLoading } from 'cmdk';
import * as R from 'remeda';

import { Avatar, AvatarFallback, AvatarImage } from '../shadcn/avatar';
import { FormControl, FormItem } from '../shadcn/form';
import { Skeleton } from '../shadcn/skeleton';
import FormHelperText from './FormHelperText';
import FormLabel from './FormLabel';
import InputBaseProps from './InputBaseProps';

type AutocompleteBaseType = {
  id: string;
  label: string;
  subtitle?: string | null;
  avatar_url?: string | null;
};

/*
  This complicated type allows the AutocompleteInput to properly type the value
  which it gives back to the onChange handler.

  If freeSolo is true, it returns a simple value with id, label & optionally subtitle.
  If clearable is true, it can return null.
  Otherwise, it will return one of the options which is typed as TOption.
*/

type AutocompleteOutputType<
  TOption extends AutocompleteBaseType,
  TFreeSolo = boolean,
  TClearable = boolean,
> = TFreeSolo extends true
  ? TClearable extends true
    ? AutocompleteBaseType | null
    : AutocompleteBaseType
  : TClearable extends true
    ? TOption | null
    : TOption;

interface AutocompleteInputProps<
  TOption extends AutocompleteBaseType,
  TFreeSolo extends boolean = false,
  TClearable extends boolean = false,
> extends InputBaseProps<
    AutocompleteBaseType | null | undefined,
    AutocompleteOutputType<TOption, TFreeSolo, TClearable>
  > {
  options: TOption[];
  value: AutocompleteBaseType | null | undefined;
  groupBy?: keyof TOption;
  freeSolo?: TFreeSolo;
  clearable?: TClearable;
  loading?: boolean;
  searchPlaceholder?: string;
  selectPlaceholder?: string;
  emptyPlaceholder?: string;
  hint?: string;
  onOpenChange?: (open: boolean) => void;
  onCommandValueChange?: (value: string) => void;
  onOptionSelected?: (option: TOption) => void;
  extraButton?: { title: string; props?: React.ComponentProps<typeof Button> };
  isPopoverModal?: boolean;
  shouldFilter?: boolean;
  alwaysShowAvatarFallback?: boolean;
}

export default function AutocompleteInput<
  TOption extends AutocompleteBaseType,
  TFreeSolo extends boolean = false,
  TClearable extends boolean = false,
>({
  value,
  onChange,
  afterValueChange,
  onBlur,
  label,
  options,
  groupBy,
  required,
  error,
  helperText,
  disabled = false,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  freeSolo = false as TFreeSolo,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  clearable = false as TClearable,
  loading = false,
  searchPlaceholder = 'Search...',
  selectPlaceholder = 'Select option...',
  emptyPlaceholder = 'No options found.',
  className,
  hint,
  onOpenChange,
  onCommandValueChange,
  onOptionSelected,
  extraButton,
  containerClassName,
  isPopoverModal = false,
  shouldFilter = true,
  alwaysShowAvatarFallback = false,
}: AutocompleteInputProps<TOption, TFreeSolo, TClearable>) {
  const [open, setOpen] = useState<boolean>(false);
  const [commandValue, setCommandValue] = useState<string>();
  const [lastSelectedOption, setLastSelectedOption] = useState<
    TOption | undefined
  >();

  const castToAutocompleteBaseType = useCallback(
    (
      newValue: AutocompleteBaseType | null,
    ): AutocompleteOutputType<TOption, TFreeSolo, TClearable> => {
      if (clearable && newValue == null) {
        // @ts-expect-error TS doesn't understand that clearable means we can return null
        return null;
      }

      if (freeSolo) {
        // @ts-expect-error TS doesn't understand that freeSolo means we can return a string
        return newValue;
      }

      throw new Error('Invalid value');
    },
    [clearable, freeSolo],
  );

  const groupedOptions = useMemo<Record<string, TOption[]>>(() => {
    if (!groupBy) {
      return {
        group: options,
      };
    }

    // @ts-expect-error TODO: Fix this
    return R.groupBy(options, R.prop(groupBy));
  }, [options, groupBy]);

  const displayValue = useMemo<AutocompleteBaseType>(() => {
    // If there is a value, return the label of the option
    // If there is no matching option and autocomplete is enabled, return the value
    // Otherwise, return the select placeholder
    if (value) {
      if (value.label != null) {
        return value;
      }

      const matchingOption = options.find((option) => option.id === value.id);

      if (matchingOption != null) {
        return matchingOption;
      }

      if (lastSelectedOption != null && lastSelectedOption.id === value.id) {
        return lastSelectedOption;
      }

      if (freeSolo) {
        return value;
      }
    }

    return {
      id: selectPlaceholder,
      label: selectPlaceholder,
    };
  }, [value, freeSolo, options, selectPlaceholder, lastSelectedOption]);

  return (
    <FormItem className={cn('space-y-1', containerClassName)}>
      <FormLabel
        htmlFor={label}
        label={label}
        required={required}
        hint={hint}
      />

      <Popover
        open={open}
        modal={isPopoverModal && open}
        onOpenChange={(o) => {
          if (disabled) {
            return;
          }

          setOpen(o);
          onOpenChange?.(o);
          // Call onBlur on close to trigger validation
          if (!o) {
            onBlur();
          }
        }}
      >
        <PopoverTrigger asChild>
          <div className='flex flex-col gap-2'>
            <FormControl>
              <Button
                id={label}
                type='button'
                variant='outline'
                role='combobox'
                aria-expanded={open}
                disabled={disabled}
                className={cn(
                  'bg-background hover:bg-background focus-visible:border-ring focus-visible:outline-ring/20 h-9 w-full justify-between rounded-lg px-3 font-normal outline-offset-0 focus-visible:outline-[3px]',
                  !!error &&
                    'border-destructive/80 text-destructive focus:border-destructive/80 focus:ring-destructive/20',
                  className,
                )}
              >
                <span
                  className={cn('truncate', !value && 'text-muted-foreground')}
                >
                  {displayValue.label}
                </span>

                <div className='flex items-center gap-2'>
                  {clearable && value && (
                    <Button
                      asChild
                      size='icon'
                      type='button'
                      variant='ghost'
                      className='z-10 h-7 w-7 focus-visible:outline-none'
                      onClick={(e) => {
                        e.stopPropagation();
                        const newValue = castToAutocompleteBaseType(null);
                        onChange(newValue);
                        afterValueChange?.(newValue);
                      }}
                    >
                      <span>
                        <IconX
                          size={16}
                          strokeWidth={2}
                          className='text-muted-foreground/80 z-10 shrink-0'
                          aria-hidden='true'
                        />
                      </span>
                    </Button>
                  )}

                  <IconChevronDown
                    size={16}
                    strokeWidth={2}
                    className='text-muted-foreground/80 shrink-0'
                    aria-hidden='true'
                  />
                </div>
              </Button>
            </FormControl>
          </div>
        </PopoverTrigger>

        <PopoverContent
          className='w-full min-w-[var(--radix-popper-anchor-width)] p-0'
          align='start'
        >
          <Command
            shouldFilter={shouldFilter}
            filter={(value, search, keywords) => {
              const extendValue = value + ' ' + keywords?.join(' ');
              if (
                extendValue
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              )
                return 1;
              return 0;
            }}
          >
            <CommandInput
              placeholder={searchPlaceholder}
              onChangeCapture={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCommandValue(e.target.value);
                onCommandValueChange?.(e.target.value);
              }}
            />

            <CommandList>
              {loading && (
                <CommandLoading>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <Skeleton key={index} className='m-1 h-8 rounded-md' />
                  ))}
                </CommandLoading>
              )}

              <CommandEmpty>
                {freeSolo && commandValue ? (
                  <Button
                    variant='outline'
                    type='button'
                    size='sm'
                    className='w-[80%]'
                    onClick={() => {
                      const newValue = castToAutocompleteBaseType({
                        id: commandValue,
                        label: commandValue,
                      });

                      onChange(newValue);
                      afterValueChange?.(newValue);
                      setOpen(false);
                      onBlur();
                    }}
                  >
                    <span className='truncate'>{`Select "${commandValue}"`}</span>
                  </Button>
                ) : (
                  emptyPlaceholder
                )}
              </CommandEmpty>

              {Object.entries(groupedOptions).map(([group, options]) => (
                <CommandGroup key={group} heading={groupBy ? group : undefined}>
                  {options.map((option) => (
                    <CommandItem
                      key={option.id}
                      value={option.id}
                      onSelect={(currentValue) => {
                        const foundOption = options.find(
                          (option) => option.id === currentValue,
                        );

                        assertNonNullish(foundOption, 'Option not found');

                        if (freeSolo) {
                          const newValue =
                            castToAutocompleteBaseType(foundOption);
                          onChange(newValue);
                          afterValueChange?.(newValue);
                        } else {
                          onChange(foundOption);
                          afterValueChange?.(foundOption);
                        }

                        onOptionSelected?.(foundOption);
                        setLastSelectedOption(foundOption);
                        setOpen(false);
                        onBlur();
                      }}
                      keywords={option.label.split(' ')}
                    >
                      {(option.avatar_url != null ||
                        alwaysShowAvatarFallback) && (
                        <Avatar className='size-5 rounded'>
                          <AvatarImage src={option.avatar_url ?? undefined} />
                          <AvatarFallback
                            className='size-5 rounded text-xs'
                            style={{
                              backgroundColor: stringToColor(option.label),
                            }}
                          >
                            {getInitials(option.label)}
                          </AvatarFallback>
                        </Avatar>
                      )}

                      <div className='flex flex-col gap-0.5'>
                        {option.label}

                        {option.subtitle && (
                          <span className='text-muted-foreground text-xs'>
                            {option.subtitle}
                          </span>
                        )}
                      </div>
                      <IconCheck
                        className={cn(
                          'ml-auto',
                          value?.id === option.id ? 'opacity-100' : 'opacity-0',
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              ))}
            </CommandList>

            {extraButton && (
              <Button
                type='button'
                variant='outline'
                {...extraButton.props}
                className={cn(
                  'w-full rounded-t-none',
                  extraButton.props?.className,
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                  extraButton.props?.onClick?.(e);
                }}
              >
                {extraButton.title}
              </Button>
            )}
          </Command>
        </PopoverContent>
      </Popover>

      <FormHelperText error={error} helperText={helperText} />
    </FormItem>
  );
}
