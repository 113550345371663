'use client';

import { useState } from 'react';

import { Button } from '@lupa/ui/components/shadcn/button';
import { Input } from '@lupa/ui/components/shadcn/input';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@lupa/ui/components/shadcn/tabs';
import { RESOURCE_PERMISSION } from '@lupa/utils/enums';

import { PlusCircle } from 'lucide-react';

import AddRoleModal from './AddRoleModal';
import EmployeeRolesTable from './EmployeeRolesTable';
import RoleDefinitionTable from './RoleDefinitionTable';

const initialRoles = ['Vet', 'Nurse', 'Financial'];

const initialEmployees = [
  { id: 1, name: 'John Doe', roles: ['Vet', 'Financial'] },
  { id: 2, name: 'Jane Smith', roles: ['Nurse'] },
  { id: 3, name: 'Bob Johnson', roles: ['Financial'] },
];

export default function PermissionsSettings() {
  const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState(false);
  const [employeeFilter, setEmployeeFilter] = useState('');
  const [employees, setEmployees] = useState(initialEmployees);
  const [roles, setRoles] = useState(initialRoles);

  const filteredEmployees = employees.filter((employee) =>
    employee.name.toLowerCase().includes(employeeFilter.toLowerCase()),
  );

  const handleUpdateEmployee = (employeeId: number, updatedRoles: string[]) => {
    setEmployees((prevEmployees) =>
      prevEmployees.map((employee) =>
        employee.id === employeeId
          ? { ...employee, roles: updatedRoles }
          : employee,
      ),
    );
  };

  const handleSaveRolePermissions = (
    permissions: Record<string, Record<string, boolean>>,
  ) => {
    console.log('Saving role permissions:', permissions);
  };

  const handleAddRole = (roleName: string) => {
    if (!roles.includes(roleName)) {
      setRoles((prevRoles) => [...prevRoles, roleName]);
      setIsAddRoleModalOpen(false);
    }
  };

  return (
    <div className='container mx-auto p-4'>
      <h1 className='mb-4 text-2xl font-bold'>Roles Management</h1>
      <Tabs defaultValue='role-definition'>
        <TabsList>
          <TabsTrigger value='role-definition'>Role Definition</TabsTrigger>
          <TabsTrigger value='employee-roles'>Employee Roles</TabsTrigger>
        </TabsList>
        <TabsContent value='role-definition'>
          <div className='mb-4 flex items-center justify-between'>
            <h2 className='text-xl font-semibold'>Role Definition</h2>
            <Button onClick={() => setIsAddRoleModalOpen(true)}>
              <PlusCircle className='mr-2 h-4 w-4' /> Add New Role
            </Button>
          </div>
          <RoleDefinitionTable
            permissions={Object.values(RESOURCE_PERMISSION)}
            roles={roles}
            onSave={handleSaveRolePermissions}
          />
        </TabsContent>
        <TabsContent value='employee-roles'>
          <div className='mb-4 flex items-center justify-between'>
            <h2 className='text-xl font-semibold'>Employee Roles</h2>
            <Input
              placeholder='Filter employees...'
              value={employeeFilter}
              onChange={(e) => setEmployeeFilter(e.target.value)}
              className='max-w-xs'
            />
          </div>
          <EmployeeRolesTable
            employees={filteredEmployees}
            allRoles={roles}
            onUpdateEmployee={handleUpdateEmployee}
          />
        </TabsContent>
      </Tabs>
      <AddRoleModal
        isOpen={isAddRoleModalOpen}
        onClose={() => setIsAddRoleModalOpen(false)}
        onAddRole={handleAddRole}
      />
    </div>
  );
}
