import * as React from 'react';

import { Button } from '@lupa/ui/components/shadcn/button';
import { Calendar } from '@lupa/ui/components/shadcn/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@lupa/ui/components/shadcn/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@lupa/ui/components/shadcn/select';
import { cn } from '@lupa/ui/lib/utils';

import { IconCalendar } from '@tabler/icons-react';

import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns';
import { DateRange } from 'react-day-picker';
import { match } from 'ts-pattern';

interface DatePickerWithRangeProps
  extends React.HTMLAttributes<HTMLDivElement> {
  dates: DateRange | undefined;
  setDates: (date: { from: Date; to: Date }) => void;
}

export default function DatePickerWithRange({
  className,
  dates,
  setDates,
}: DatePickerWithRangeProps) {
  const [localDates, setLocalDates] = React.useState<DateRange | undefined>(
    dates,
  );
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setLocalDates(dates);
  }, [dates]);

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            id='date'
            variant={'outline'}
            className={cn(
              'w-[300px] justify-start text-left font-normal',
              !dates && 'text-muted-foreground',
            )}
          >
            <IconCalendar />
            {localDates?.from ? (
              localDates.to ? (
                <>
                  {format(localDates.from, 'LLL dd, y')} -{' '}
                  {format(localDates.to, 'LLL dd, y')}
                </>
              ) : (
                format(localDates.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align='start'
          className='flex w-auto flex-col space-y-2 p-2'
        >
          <Select
            onValueChange={(value) => {
              match(value)
                .with('today', () => {
                  setDates({
                    from: startOfDay(new Date()),
                    to: endOfDay(new Date()),
                  });
                })
                .with('last_week', () => {
                  setDates({
                    from: subDays(startOfWeek(new Date()), 7),
                    to: subDays(endOfWeek(new Date()), 7),
                  });
                })
                .with('current_week', () => {
                  setDates({
                    from: startOfWeek(new Date()),
                    to: endOfWeek(new Date()),
                  });
                })
                .with('last_month', () => {
                  setDates({
                    from: subMonths(startOfMonth(new Date()), 1),
                    to: subMonths(endOfMonth(new Date()), 1),
                  });
                })
                .with('current_month', () => {
                  setDates({
                    from: startOfMonth(new Date()),
                    to: endOfMonth(new Date()),
                  });
                })
                .otherwise(() => {
                  console.error('Invalid value', value);
                });

              setOpen(false);
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder='Select' />
            </SelectTrigger>
            <SelectContent position='popper'>
              <SelectItem value='today'>Today</SelectItem>
              <SelectItem value='last_week'>Last week</SelectItem>
              <SelectItem value='current_week'>Current week</SelectItem>
              <SelectItem value='last_month'>Last month</SelectItem>
              <SelectItem value='current_month'>Current month</SelectItem>
            </SelectContent>
          </Select>

          <div className='rounded-md border'>
            <Calendar
              initialFocus
              mode='range'
              defaultMonth={localDates?.from}
              selected={localDates}
              onSelect={(value) => {
                setLocalDates(value);
              }}
              numberOfMonths={2}
            />
          </div>

          <div className='flex justify-end gap-2'>
            <Button
              variant='outline'
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant='default'
              disabled={!localDates?.from || !localDates?.to}
              onClick={() => {
                if (!localDates) {
                  return;
                }

                if (localDates.from == null || localDates.to == null) {
                  return;
                }

                setDates({
                  from: localDates.from,
                  to: localDates.to,
                });

                setOpen(false);
              }}
              className={'shadow'}
            >
              Apply
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
