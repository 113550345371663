import { z } from 'zod';

import { PRESCRIPTION_STATUS } from '../enums';
import { zIsoDateString } from './utils';

export const employeeStubValidationSchema = z.object({
  id: z.string().uuid(),
  full_name: z.string(),
  avatar_url: z.string().nullable(),
  rcvs_registration_number: z.string().nullish(),
});

// Dispenses
export const prescriptionDispenseValidationSchema = z.object({
  id: z.string().uuid(),
  is_cancelled: z.boolean(),
  authorisation: z
    .object({
      given_by_employee: employeeStubValidationSchema,
      given_at: z.string().datetime({ offset: true }),
    })
    .nullable(),
  billing: z
    .object({
      billing_product_id: z.string().uuid(),
    })
    .nullable(),
});

export type PrescriptionDispense = z.infer<
  typeof prescriptionDispenseValidationSchema
>;

export const prescriptionDispenseActionsSchema = z
  .object({
    cancel: z.object({}),
    authorise: z.object({}),
    link_to_billing_product: z.object({
      id: z.string().uuid(),
    }),
  })
  .partial();

export type PrescriptionDispenseActions = z.infer<
  typeof prescriptionDispenseActionsSchema
>;

// Prescription
export const prescriptionTimeUnits = [
  'hours',
  'days',
  'weeks',
  'months',
  'years',
] as const;

const StructuredPrescriptionDosageSchema = z.object({
  type: z.literal('structured'),
  quantity: z.number().gt(0, 'Quantity must be greater than 0'),
  frequency: z.object({
    intervalNumber: z.number().min(1),
    intervalUnit: z.enum(prescriptionTimeUnits),
    timesPerInterval: z.number().min(1),
  }),
  startDate: zIsoDateString,
  duration: z.discriminatedUnion('type', [
    z.object({
      type: z.literal('one-off'),
    }),
    z.object({
      type: z.literal('for-duration'),
      durationNumber: z.number().min(1),
      durationUnit: z.enum(prescriptionTimeUnits),
    }),
    z.object({
      type: z.literal('until'),
      until: zIsoDateString,
    }),
    z.object({
      type: z.literal('ongoing'),
    }),
  ]),
  additionalInstructions: z.string().optional(),
});

const FreeformPrescriptionDosageSchema = z.object({
  type: z.literal('freeform'),
  instructions: z.string(),
});

export const PrescriptionDosageSchema = z.discriminatedUnion('type', [
  StructuredPrescriptionDosageSchema,
  FreeformPrescriptionDosageSchema,
]);

export type StructuredPrescriptionDosage = z.infer<
  typeof StructuredPrescriptionDosageSchema
>;
export type FreeformPrescriptionDosage = z.infer<
  typeof FreeformPrescriptionDosageSchema
>;
export type PrescriptionDosage = z.infer<typeof PrescriptionDosageSchema>;

export const prescriptionSchema = z.object({
  id: z.string().uuid(),
  pet_id: z.string().uuid(),
  client_id: z.string().uuid(),
  product_name: z.string(),
  is_prescribed_only: z.boolean(),
  prescriber_employee_id: z.string().uuid(),

  quantity: z
    .number({
      message: 'Quantity is required',
    })
    .gt(0, 'Quantity must be greater than 0'),
  unit: z.string({
    message: 'Unit is required',
  }),

  dosage_specification: PrescriptionDosageSchema,

  refill_limit: z.number().min(0, 'Refill limit must be at least 0'),
  refills_permitted_until: z.string().nullable(),

  status: z.nativeEnum(PRESCRIPTION_STATUS),

  next_refill_due: z.string().nullish(),
  source_product_id: z.string().uuid().nullish(),

  created_at: z.string().datetime({ offset: true }).optional(),
  qr_code_link: z.string().url().nullish(),
});

export const prescriptionUpsertSchema = z.intersection(
  z.object({
    id: z.string().uuid().optional(),
  }),
  prescriptionSchema.omit({ id: true }),
);

export type Prescription = z.infer<typeof prescriptionSchema>;
export type PrescriptionUpsert = z.infer<typeof prescriptionUpsertSchema>;

export const prescriptionAndDispensesUpsertSchema = z.object({
  prescription: prescriptionUpsertSchema,
  dispenses: z.object({
    modified: z.record(z.string().uuid(), prescriptionDispenseActionsSchema),
    created: z.array(prescriptionDispenseActionsSchema),
  }),
  formOptions: z.object({
    notifyReadyForPickup: z.boolean().nullable(),
  }),
});

export type PrescriptionAndDispensesUpsert = z.infer<
  typeof prescriptionAndDispensesUpsertSchema
>;
