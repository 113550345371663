import React, { useState } from 'react';

import { AutocompleteSearch } from '@lupa/ui/components/AutocompleteSearch';
import DividerSection from '@lupa/ui/components/DividerSection';
import FormLocalAutocompleteField from '@lupa/ui/components/FormLocalAutocompleteField';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from '@lupa/ui/components/StyledToggleButton';
import FormDateField from '@lupa/ui/components/form/FormDateField';
import FormHelperText from '@lupa/ui/components/form/FormHelperText';
import LupaFormikNumberInput from '@lupa/ui/components/form/LupaFormikNumberInput';
import { Input } from '@lupa/ui/components/shadcn/input';
import { Label } from '@lupa/ui/components/shadcn/label';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@lupa/ui/components/shadcn/tooltip';
import { cn } from '@lupa/ui/lib/utils';
import { StructuredPrescriptionDosage } from '@lupa/utils/validators/prescriptionValidators';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';
import { getEmployeeRoleLabel } from '@lupa/work/utils/get-labels';
import { zIsoDateString } from '@lupa/work/utils/time-utils';
import { formatUnit } from '@lupa/work/utils/units';

import {
  Alert,
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {
  IconCalendar,
  IconChevronDown,
  IconDog,
  IconEdit,
  IconInfoCircle,
  IconPill,
  IconStethoscope,
  IconUser,
} from '@tabler/icons-react';

import { addDays, format } from 'date-fns';
import { FormikProps } from 'formik';
import QRCode from 'react-qr-code';
import { match } from 'ts-pattern';

import FormSelectField from '../ui/form/FormSelectField';
import FormTextField from '../ui/form/FormTextField';
import { PrescriptionAndDispensesFormDataType } from './PrescriptionForm';

const ValueSection = ({
  icon: Icon,
  title,
  subtitle,
}: {
  icon: any; // TODO: fix this
  title: React.ReactNode;
  subtitle: string;
}) => (
  <Stack direction='row' alignItems='center' spacing={1}>
    <Avatar sx={{ bgcolor: 'grey.200', width: 24, height: 24 }}>
      <Icon size={16} />
    </Avatar>
    <Box>
      <Typography variant='body2' color='text.secondary'>
        {subtitle}
      </Typography>
      <Typography variant='subtitle1'>{title}</Typography>
    </Box>
  </Stack>
);

const DEFAULT_UNITS = [
  'unit(s)',
  'tablet(s)',
  'capsule(s)',
  'sachet(s)',
  'drop(s)',
  'pipette(s)',
  'vial(s)',
  'ml',
  'mg',
  'g',
  'kg',
  'l',
];

type PrescriberSelectionEmployee = Pick<
  TrpcRouterOutputs['employees']['searchEmployees'][0],
  'id' | 'full_name' | 'role'
>;

export default function PrescriptionFormPrescriptionSection({
  formik,
  pet,
  client,
  prescriber,
}: {
  formik: FormikProps<PrescriptionAndDispensesFormDataType>;
  pet: TrpcRouterOutputs['pets']['getPet'];
  client: TrpcRouterOutputs['clients']['getClient'];
  prescriber: TrpcRouterOutputs['employees']['getEmployee'];
}) {
  const { prescription } = formik.values;
  const [prescriberSelectionState, setPrescriberSelectionState] = useState<
    | {
        open: true;
        selection: PrescriberSelectionEmployee;
      }
    | { open: false }
  >({
    open: false,
  });

  return (
    <Stack direction='column' spacing={3}>
      <Stack direction='row' spacing={4}>
        <ValueSection icon={IconDog} title={pet.name} subtitle='Pet' />
        <ValueSection
          icon={IconUser}
          title={client.full_name}
          subtitle='Client'
        />
        <ValueSection
          icon={IconStethoscope}
          title={
            prescriberSelectionState.open ? (
              <AutocompleteSearch<{
                ItemType: PrescriberSelectionEmployee;
              }>
                trpcFunction={trpc.employees.searchEmployees}
                getOptionLabel={(option) =>
                  option.role
                    ? `${option.full_name} • ${getEmployeeRoleLabel(
                        option.role,
                      )}`
                    : option.full_name
                }
                value={prescriberSelectionState.selection}
                onSelect={(employee) => {
                  if (employee == null) {
                    return;
                  }

                  formik.setFieldValue(
                    'prescription.prescriber_employee_id',
                    employee?.id,
                  );
                  setPrescriberSelectionState({
                    open: false,
                  });
                }}
                size='small'
                initiallyOpen
                inputPropsSx={{
                  width: '300px',
                }}
              />
            ) : (
              <>
                {prescriber.full_name}
                <IconButton
                  onClick={() =>
                    setPrescriberSelectionState({
                      open: true,
                      selection: prescriber,
                    })
                  }
                >
                  <IconEdit size={16} />
                </IconButton>
              </>
            )
          }
          subtitle='Prescriber'
        />
      </Stack>
      <Stack direction='row' spacing={4}>
        {prescription.is_prescribed_only ? (
          <FormTextField
            name='prescription.product_name'
            label='Product'
            formik={formik}
          />
        ) : (
          <ValueSection
            icon={IconPill}
            title={prescription.product_name}
            subtitle='Drug'
          />
        )}

        <ValueSection
          icon={IconCalendar}
          title={format(
            prescription.created_at
              ? new Date(prescription.created_at)
              : new Date(),
            'MMM d, yyyy',
          )}
          subtitle='Created'
        />
      </Stack>
      <div className='flex justify-between gap-2'>
        <div className='flex w-full flex-col gap-1'>
          <Label htmlFor='qr_code_link'>
            Prescription QR Code
            <TooltipProvider>
              <Tooltip delayDuration={200}>
                <TooltipTrigger>
                  <IconInfoCircle
                    size={16}
                    strokeWidth={1.75}
                    className='-mb-0.5 ml-1 text-gray-400'
                  />
                </TooltipTrigger>
                <TooltipContent>
                  This QR code will appear on the written prescription as well
                  as the dosage label.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Label>
          <Input
            {...formik.getFieldProps('prescription.qr_code_link')}
            type='text'
            id='qr_code_link'
            placeholder='https://'
            value={prescription.qr_code_link ?? undefined}
            onChange={(e) => {
              const value = e.target.value === '' ? null : e.target.value;
              formik.setFieldValue('prescription.qr_code_link', value);
            }}
          />
          <FormHelperText error={formik.errors.prescription?.qr_code_link} />
        </div>
        <QRCode
          size={32}
          value={prescription.qr_code_link || ''}
          className={cn('visible mt-[22px]', {
            invisible: !prescription.qr_code_link,
          })}
        />
      </div>
      {prescription.status === 'cancelled' && (
        <Alert
          severity='error'
          sx={{ border: '1px solid', borderColor: 'error.light' }}
        >
          <Typography color='error.dark' fontWeight='500'>
            This prescription has been cancelled
          </Typography>
        </Alert>
      )}
      <DividerSection title='Amount to be supplied'>
        <Stack direction='row' spacing={2} alignItems='top'>
          <LupaFormikNumberInput
            name='prescription.quantity'
            label='Count'
            formik={formik}
            value={formik.values.prescription.quantity}
            size='small'
            isInt={false}
            showArrows
            sx={{
              width: 150,
            }}
          />

          <FormLocalAutocompleteField
            name='prescription.unit'
            label='Unit'
            textFieldProps={{
              size: 'small',
            }}
            freeSolo
            formik={formik}
            options={DEFAULT_UNITS}
            getOptionLabel={(option) => option}
            getOptionKey={(option) => option}
            sx={{ flexGrow: 1 }}
          />
        </Stack>
      </DividerSection>
      <DividerSection title='Dosage'>
        <Box>
          <StyledToggleButtonGroup
            value={prescription.dosage_specification.type}
            exclusive
            color='primary'
            onChange={async (_, value) => {
              await formik.setFieldValue(
                'prescription.dosage_specification',
                value === 'structured'
                  ? {
                      type: 'structured',
                      quantity: 1,
                      frequency: {
                        intervalNumber: 1,
                        intervalUnit: 'days',
                        timesPerInterval: 1,
                      },
                      duration: {
                        type: 'for-duration',
                        durationNumber: 7,
                        durationUnit: 'days',
                      },
                      startDate: zIsoDateString.parse(
                        format(new Date(), 'yyyy-MM-dd'),
                      ),
                      additionalInstructions: '',
                    }
                  : {
                      type: 'freeform',
                      instructions: '',
                    },
              );

              // Ensure we don't immediately highlight unentered fields.
              await formik.setTouched({
                ...formik.touched,
                prescription: {
                  ...formik.touched.prescription,
                  dosage_specification: {},
                },
              });
            }}
          >
            <StyledToggleButton value='structured'>
              Structured
            </StyledToggleButton>
            <StyledToggleButton value='freeform'>Freeform</StyledToggleButton>
          </StyledToggleButtonGroup>
        </Box>

        {prescription.dosage_specification.type === 'freeform' && (
          <FormTextField
            name='prescription.dosage_specification.instructions'
            label='Instructions'
            formik={formik}
            multiline
            minRows={2}
            placeholder='e.g. Give ONE tablet with food daily for 4 days'
          />
        )}

        {prescription.dosage_specification.type === 'structured' && (
          <>
            <Stack
              direction='row'
              gap={1.5}
              pl={1}
              alignItems='end'
              sx={{ marginTop: '0px !important' }}
            >
              <Typography variant='body2' fontWeight='500' pb='8px'>
                Give
              </Typography>
              <LupaFormikNumberInput
                name='prescription.dosage_specification.quantity'
                variant='standard'
                formik={formik}
                value={prescription.dosage_specification.quantity}
                size='small'
                isInt={false}
                sx={{
                  width: 60,
                }}
              />
              <Typography variant='body2' fontWeight='500' pb='8px'>
                {formatUnit({
                  unit: prescription.unit || 'unit(s)',
                  quantity: prescription.dosage_specification.quantity,
                })}
              </Typography>
              <FormSelectField
                name='prescription.dosage_specification.frequency.timesPerInterval'
                variant='standard'
                widthStyle='standard'
                formik={formik}
                options={[1, 2, 3, 4, 5]}
                getOptionLabel={(option) =>
                  [
                    null,
                    'every',
                    'twice every',
                    'three times every',
                    'four times every',
                    'five times every',
                  ][option] ?? `${option} times every`
                }
                getOptionValue={(option) => option}
              />
              <LupaFormikNumberInput
                name='prescription.dosage_specification.frequency.intervalNumber'
                variant='standard'
                value={
                  prescription.dosage_specification.frequency.intervalNumber
                }
                formik={formik}
                size='small'
                isInt={false}
                sx={{
                  width: 60,
                }}
              />
              <FormSelectField
                name='prescription.dosage_specification.frequency.intervalUnit'
                variant='standard'
                widthStyle='standard'
                formik={formik}
                options={['hours', 'days', 'weeks', 'months']}
                getOptionLabel={
                  prescription.dosage_specification.frequency.intervalNumber ===
                  1
                    ? (option) => option.replace(/s$/, '')
                    : (option) => option
                }
                getOptionValue={(option) => option}
              />
              <Select
                value={prescription.dosage_specification.duration.type}
                onChange={async (event) => {
                  await formik.setFieldValue(
                    'prescription.dosage_specification.duration',
                    match(event.target.value)
                      .returnType<StructuredPrescriptionDosage['duration']>()
                      .with('for-duration', () => ({
                        type: 'for-duration',
                        durationNumber: 7,
                        durationUnit: 'days',
                      }))
                      .with('until', () => ({
                        type: 'until',
                        until: zIsoDateString.parse(
                          format(addDays(new Date(), 7), 'yyyy-MM-dd'),
                        ),
                      }))
                      .with('ongoing', () => ({
                        type: 'ongoing',
                      }))
                      .with('one-off', () => ({
                        type: 'one-off',
                      }))
                      .otherwise(() => {
                        console.error(
                          'Invalid prescription duration type',
                          event.target.value,
                        );
                        return {
                          type: 'for-duration',
                          durationNumber: 7,
                          durationUnit: 'days',
                        };
                      }),
                  );
                }}
                variant='standard'
                IconComponent={(props) => (
                  <IconChevronDown {...props} style={{ marginTop: -4 }} />
                )}
              >
                <MenuItem value='for-duration'>for</MenuItem>
                <MenuItem value='until'>until</MenuItem>
                <MenuItem value='ongoing'>ongoing</MenuItem>
                <MenuItem value='one-off'>(one-off)</MenuItem>
              </Select>
              {prescription.dosage_specification.duration.type ===
                'for-duration' && (
                <>
                  <LupaFormikNumberInput
                    name='prescription.dosage_specification.duration.durationNumber'
                    variant='standard'
                    formik={formik}
                    value={
                      prescription.dosage_specification.duration.durationNumber
                    }
                    size='small'
                    isInt={false}
                    sx={{
                      width: 60,
                    }}
                  />
                  <FormSelectField
                    name='prescription.dosage_specification.duration.durationUnit'
                    variant='standard'
                    widthStyle='standard'
                    formik={formik}
                    options={['hours', 'days', 'weeks', 'months']}
                    getOptionLabel={
                      prescription.dosage_specification.duration
                        .durationNumber === 1
                        ? (option) => option.replace(/s$/, '')
                        : (option) => option
                    }
                    getOptionValue={(option) => option}
                  />
                </>
              )}
              {prescription.dosage_specification.duration.type === 'until' && (
                <FormDateField
                  name='prescription.dosage_specification.duration.until'
                  formik={formik}
                  textFieldProps={{
                    size: 'small',
                    variant: 'standard',
                  }}
                  dateFormat='isoDate'
                />
              )}
            </Stack>
            <Box pt={1} pb={0.5}>
              <FormDateField
                name='prescription.dosage_specification.startDate'
                label='Starting'
                textFieldProps={{
                  size: 'small',
                  variant: 'outlined',
                }}
                formik={formik}
                dateFormat='isoDate'
              />
            </Box>
            <FormTextField
              name='prescription.dosage_specification.additionalInstructions'
              label='Additional instructions'
              size='small'
              formik={formik}
              multiline
              minRows={1}
              placeholder='e.g. Take with food'
            />
          </>
        )}
      </DividerSection>
    </Stack>
  );
}
