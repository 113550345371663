import { BillingItem } from '@lupa/utils/billingUtils';
import {
  APPOINTMENT_TYPE,
  HEALTH_PLAN_ALLOWANCE_ITEM_TYPE,
  HEALTH_PLAN_ALLOWANCE_TYPE,
  PRODUCT_CATEGORY_TYPE,
} from '@lupa/utils/enums';
import {
  ALLOWANCES_UNLIMITED_QUANTITY,
  RemainingAllowanceQuantityDataType,
  getHealthPlanAllowanceItemTypeLabel,
} from '@lupa/utils/healthplanUtils';
import { getAppointmentCategoryLabel } from '@lupa/work/consts/storeCategories';
import { TrpcRouterOutputs } from '@lupa/work/lib/trpc';
import { getProductCategoryLabel } from '@lupa/work/utils/get-labels';

import { formatCurrency } from './i18n';

export type HealthPlanAllowance =
  TrpcRouterOutputs['healthPlans']['getAllHealthPlans'][number]['allowances'][number];
export type AllowanceItem =
  | {
      type:
        | HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_PRODUCT
        | HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_SERVICE;
      item: string;
    }
  | {
      type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.PRODUCT_CATEGORY;
      item: PRODUCT_CATEGORY_TYPE;
    }
  | {
      type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.SERVICE_CATEGORY;
      item: APPOINTMENT_TYPE;
    };

export function getAllowanceItemLabel({
  allowance,
}: {
  allowance: {
    item: string | null;
    service: { name: string } | null;
    product: { name: string } | null;
    item_type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE;
  } | null;
}) {
  if (allowance != null) {
    switch (allowance.item_type) {
      case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_PRODUCT:
        return allowance.product?.name;
      case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_SERVICE:
        return allowance.service?.name;
      case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.PRODUCT_CATEGORY:
        return allowance.item != null
          ? getProductCategoryLabel(allowance.item as PRODUCT_CATEGORY_TYPE)
          : '';
      case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.SERVICE_CATEGORY:
        return allowance.item != null
          ? getAppointmentCategoryLabel(allowance.item as APPOINTMENT_TYPE)
          : '';
      default:
        return 'Unknown Item Type';
    }
  }
  return 'Unknown Item';
}

export function getAllowanceNameLabel({
  allowance,
}: {
  allowance: {
    item: string | null;
    service: { name: string } | null;
    product: { name: string } | null;
    item_type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE;
  } | null;
}) {
  if (allowance == null) {
    return 'Allowance';
  }

  return `${getHealthPlanAllowanceItemTypeLabel(
    allowance.item_type,
  )}: ${getAllowanceItemLabel({ allowance })}`;
}

export function getAllowanceNewNameLabel({
  allowance,
}: {
  allowance: {
    item: string | null;
    service: { name: string } | null;
    product: { name: string } | null;
    item_type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE;
    config: {
      value: {
        type: HEALTH_PLAN_ALLOWANCE_TYPE;
        value: number;
      };
    };
  } | null;
}) {
  if (allowance == null) {
    return 'Allowance';
  }

  const typeLabel = getAllowanceNameLabel({ allowance });
  const valueLabel =
    allowance.config.value.type === HEALTH_PLAN_ALLOWANCE_TYPE.PERCENTAGE
      ? `${allowance.config.value.value}%`
      : formatCurrency(allowance.config.value.value);

  return `${typeLabel} (${valueLabel})`;
}

export function getAllowanceNameCompleteLabel({
  allowance,
}: {
  allowance: {
    item: string | null;
    service: { name: string } | null;
    product: { name: string } | null;
    item_type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE;
    remainingQuantity: number;
    config: {
      value: {
        type: HEALTH_PLAN_ALLOWANCE_TYPE;
        value: number;
      };
    };
  } | null;
}) {
  if (allowance == null) {
    return 'Allowance';
  }

  const typeLabel = getAllowanceNameLabel({ allowance });
  const quantityLabel =
    allowance.remainingQuantity === ALLOWANCES_UNLIMITED_QUANTITY
      ? 'Unlimited'
      : `${allowance.remainingQuantity} remaining`;
  const valueLabel =
    allowance.config.value.type === HEALTH_PLAN_ALLOWANCE_TYPE.PERCENTAGE
      ? `${allowance.config.value.value}%`
      : formatCurrency(allowance.config.value.value);

  return `${typeLabel} (${valueLabel}) | ${quantityLabel}`;
}

const calculateTotalRemainingAllowances = (
  allowances: Record<string, RemainingAllowanceQuantityDataType[]>,
): number => {
  return Object.values(allowances).reduce(
    (total, categoryAllowances) =>
      total +
      categoryAllowances.reduce(
        (sum, allowance) => sum + allowance.remainingQuantity,
        0,
      ),
    0,
  );
};

export const hasRemainingAllowances = (
  allowances: Record<string, RemainingAllowanceQuantityDataType[]>,
): boolean => {
  return calculateTotalRemainingAllowances(allowances) > 0;
};

export const hasAllowances = (
  item: BillingItem,
  availableAllowances: Record<string, RemainingAllowanceQuantityDataType[]>,
): boolean => {
  let categoryKey: string | null | undefined;
  let idKey: string | null | undefined;

  if ('product_detail' in item) {
    categoryKey = item.product_detail?.category;
    idKey = item.product_id;
  } else if ('service_detail' in item) {
    categoryKey = item.service_detail?.category;
    idKey = item.service_id;
  }

  const checkAllowances = (key: string | null | undefined): boolean =>
    Boolean(
      key && availableAllowances[key]?.some((a) => a.remainingQuantity > 0),
    );

  return checkAllowances(categoryKey) || checkAllowances(idKey);
};
