import React, { useMemo, useRef } from 'react';

import ImageStack from '@lupa/ui/components/ImageStack';
import Multiselect from '@lupa/ui/components/Multiselect';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@lupa/ui/components/shadcn/avatar';
import { Button, buttonVariants } from '@lupa/ui/components/shadcn/button';
import { Calendar } from '@lupa/ui/components/shadcn/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@lupa/ui/components/shadcn/popover';
import { cn } from '@lupa/ui/lib/utils';
import { getInitials, stringToColor } from '@lupa/utils/stringUtils';
import { getImagePublicUrl } from '@lupa/work/utils/get-image-public-url';
import { CALENDAR_VIEW_TYPE } from '@lupa/work/utils/local-enums';

import Typography from '@mui/material/Typography';

import { format } from 'date-fns';
import {
  Calendar1Icon,
  Check,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  X,
} from 'lucide-react';

import CalendarToolbarSettings from './CalendarToolbarSettings';

export type CalendarResource = {
  id: string;
  label: string;
  avatar_url: Nullable<string>;
  order: number;
  type: 'employee' | 'room';
};

type CalendarToolbarProps = {
  date: Date;
  isThreeDaysView: boolean;
  onDateNext: () => void;
  onDateToday: () => void;
  onDatePrev: () => void;
  onViewChange: (view: CALENDAR_VIEW_TYPE) => void;
  onDateChange: (date: Date) => void;
  allResources: CalendarResource[];
  selectedResourcesData: CalendarResource[];
  setSelectedResourcesIds: (
    resources: {
      id: string;
    }[],
  ) => void;
  view: CALENDAR_VIEW_TYPE;
  dateRange?: {
    startDate: string;
    endDate: string;
  };
};

export const CalendarToolbar = ({
  date,
  dateRange,
  isThreeDaysView,
  onDateNext,
  onDateToday,
  onDatePrev,
  onViewChange,
  onDateChange,
  allResources,
  selectedResourcesData,
  setSelectedResourcesIds,
  view,
  ...other
}: CalendarToolbarProps) => {
  const datePickerButtonRef = useRef<HTMLButtonElement>(null);

  const dateWeekDay = format(date, 'EEE');
  const dateDay = format(date, 'do');
  const endDateDate = dateRange?.endDate
    ? format(new Date(dateRange.endDate), 'do')
    : '';
  const dateMonth = format(date, 'MMM');
  const dateYear = format(date, 'y');
  const isToday = date.getDate() === new Date().getDate();

  const hasSelectedResources = selectedResourcesData.length > 0;
  const imageStackResources = useMemo(() => {
    return selectedResourcesData.map((resource) => ({
      id: resource.id,
      image_url: getImagePublicUrl(resource.avatar_url),
      name: resource.label,
    }));
  }, [selectedResourcesData]);

  return (
    <div className='mb-2 flex flex-col gap-2'>
      <div
        className='flex flex-col flex-wrap items-center justify-between gap-3 px-4 md:flex-row'
        {...other}
      >
        <div
          className='flex flex-col items-center gap-1 md:flex-row'
          data-testid='calendar-toolbar-buttons'
        >
          <Popover>
            <PopoverTrigger asChild>
              <Button
                ref={datePickerButtonRef}
                size='lg'
                variant='secondary'
                data-testid='button-calendar-date-picker'
                className='flex min-w-[350px] justify-between'
              >
                <span className='flex items-center gap-4'>
                  <Calendar1Icon
                    className={cn('-ml-2', !isToday && 'text-primary')}
                  />
                  <Typography
                    variant='h5'
                    data-testid='text-calendar-date'
                  >{`${dateWeekDay}, ${dateDay} ${
                    endDateDate ? ` - ${endDateDate}` : ''
                  }`}</Typography>
                </span>
                <span className='flex items-center gap-2'>
                  <Typography sx={{ fontWeight: 400 }} variant='h5'>
                    {`${dateMonth} ${dateYear}`}
                  </Typography>
                  <ChevronDownIcon className='-mr-2 ml-2' />
                </span>
              </Button>
            </PopoverTrigger>
            <PopoverContent className='w-auto'>
              <Calendar
                selected={date}
                mode='single'
                onSelect={(value) => {
                  onDateChange(value ?? new Date());
                }}
              />
            </PopoverContent>
          </Popover>

          <div className='flex items-center gap-2 pt-2 md:ml-2 md:gap-1 md:pt-0'>
            <Button
              size='icon'
              variant='secondary'
              onClick={onDatePrev}
              data-testid='button-calendar-prev'
            >
              <ChevronLeftIcon />
            </Button>
            <Button size='sm' variant='secondary' onClick={onDateToday}>
              Today
            </Button>
            <Button
              size='icon'
              variant='secondary'
              onClick={onDateNext}
              data-testid='button-calendar-next'
            >
              <ChevronRightIcon />
            </Button>
          </div>
        </div>

        <div className='flex items-center gap-2'>
          <Multiselect
            allOptions={allResources.map((resource) => ({
              id: resource.id,
              value: resource.label,
              label: resource.label,
              avatar_url: resource.avatar_url,
              order: resource.order,
              type: resource.type,
            }))}
            selectedOptions={selectedResourcesData.map((resource) => ({
              id: resource.id,
              value: resource.label,
              label: resource.label,
              avatar_url: getImagePublicUrl(resource.avatar_url),
              order: resource.order,
              type: resource.type,
            }))}
            setSelectedOptions={(options) => {
              setSelectedResourcesIds(
                options.map((option) => ({
                  id: option.id,
                })),
              );
            }}
            popoverContentProps={{
              align: 'end',
            }}
            trigger={({ open, setOpen }) => (
              <Button
                variant={hasSelectedResources ? 'hint' : 'secondary'}
                size='sm'
                aria-expanded={open}
                className={cn('min-w-64 justify-between', {
                  'hover:bg-primary/20': hasSelectedResources,
                })}
              >
                {hasSelectedResources ? (
                  <ImageStack avatarSize={7} items={imageStackResources} />
                ) : (
                  'Showing all calendars'
                )}
                <div className='flex items-center gap-2'>
                  {hasSelectedResources && (
                    <div
                      className={cn(
                        buttonVariants({ variant: 'ghost', size: 'icon' }),
                        'text-primary-lighter hover:text-primary-darker group h-4 w-4 shrink-0',
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedResourcesIds([]);
                        setOpen(false);
                      }}
                    >
                      <X />
                    </div>
                  )}
                  <ChevronDownIcon className='ml-2 h-4 w-4 shrink-0 opacity-50' />
                </div>
              </Button>
            )}
            renderItem={(option) => {
              return (
                <Button
                  variant='ghost'
                  size='sm'
                  className='flex w-full items-center justify-start gap-1'
                >
                  <Avatar className='mr-2 h-6 w-6 text-xs ring-2 ring-white'>
                    <AvatarImage
                      src={getImagePublicUrl(option.avatar_url)}
                      alt={option.label}
                    />
                    <AvatarFallback
                      backgroundColor={stringToColor(option.label ?? '')}
                    >
                      {getInitials(option.label)}
                    </AvatarFallback>
                  </Avatar>
                  {option.label}
                  <Check
                    className={cn(
                      'ml-auto h-4 w-4',
                      selectedResourcesData.find((o) => o.id === option.id)
                        ? 'opacity-100'
                        : 'opacity-0',
                    )}
                  />
                </Button>
              );
            }}
          />

          <CalendarToolbarSettings
            view={view}
            onViewChange={onViewChange}
            isThreeDaysView={isThreeDaysView}
            allResources={allResources}
          />
        </div>
      </div>
    </div>
  );
};
