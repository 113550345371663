import React, { useEffect, useMemo, useState } from 'react';

import { AutocompleteSearch } from '@lupa/ui/components/AutocompleteSearch';
import DialogSection from '@lupa/ui/components/DialogSection';
import DropdownMenuButton from '@lupa/ui/components/DropdownMenuButton';
import { useDialog } from '@lupa/ui/hooks/use-dialog';
import {
  getBillingInfoFromItem,
  getBillingInfoFromItems,
} from '@lupa/utils/billingUtils';
import { DISCOUNT_TYPE, PERMISSION_CATEGORY } from '@lupa/utils/enums';
import {
  RemainingAllowanceQuantityDataType,
  calculateAvailableAllowances,
} from '@lupa/utils/healthplanUtils';
import {
  BillingProductValidationSchemaType,
  BillingServiceValidationSchemaType,
} from '@lupa/utils/validators/invoiceValidators';
import AppointmentsBillingSummary from '@lupa/work/components/appointments/invoices/AppointmentsBillingSummary';
import AppointmentsInvoicePrescribedProductsList from '@lupa/work/components/appointments/invoices/AppointmentsInvoicePrescribedProductsList';
import AppointmentsInvoiceProductsList from '@lupa/work/components/appointments/invoices/AppointmentsInvoiceProductsList';
import AppointmentsInvoiceServicesList from '@lupa/work/components/appointments/invoices/AppointmentsInvoiceServicesList';
import CreateProductDialog from '@lupa/work/components/modals/CreateProductDialog';
import UpsertStoreServiceSettingsModal from '@lupa/work/components/modals/UpsertStoreServiceSettingsModal';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import {
  getBillingInfoFromSearchedProduct,
  getBillingInfoFromSearchedService,
} from '@lupa/work/utils/billing-utils';
import {
  getIsVetStore,
  hasEmployeePermissions,
} from '@lupa/work/utils/store-utils';

import { Button, ButtonGroup, Card, Stack, Typography } from '@mui/material';

import { FormikProps } from 'formik';
import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';

import AppointmentUpsertPrescriptionDialog from './AppointmentUpsertPrescriptionDialog';

export type AppointmentExtrasFormikValues = {
  apply_discount: boolean;
  discount: number;
  discount_type: DISCOUNT_TYPE;
  services: BillingServiceValidationSchemaType[];
  products: BillingProductValidationSchemaType[];
  prescribed_products: BillingProductValidationSchemaType[];
  totalPrice?: number;
};

type PetDetails = { id: string; clientId: string };

type AppointmentExtrasProps = {
  formik: FormikProps<AppointmentExtrasFormikValues>;
  pet?: PetDetails;
  shouldShowAddBundle?: boolean;
  shouldShowVat?: boolean;
  showPrescriptions?: boolean;
  showUnitPrice?: boolean;
  showGlobalDiscount?: boolean;
} & (
  | {
      // Pet is required when showPrescriptions is true - we need the pet details to help create
      // any new prescriptions.
      showPrescriptions: true;
      pet: PetDetails;
    }
  | { showPrescriptions?: false }
);

export default function AppointmentExtras({
  formik,
  pet,
  shouldShowAddBundle = true,
  shouldShowVat = false,
  showPrescriptions = false,
  showUnitPrice = false,
  showGlobalDiscount = true,
}: AppointmentExtrasProps) {
  const createServiceDialog = useDialog();
  const createProductDialog = useDialog();
  const prescriptionDialog = useDialog<{
    product: BillingProductValidationSchemaType;
    petId: string;
    clientId: string;
    setProduct: (product: BillingProductValidationSchemaType) => void;
  }>();

  // TODO: store & post state of dispense -> billing product IDs

  const { data: healthPlanSubscriptions } =
    trpc.healthPlans.getPetAndClientActiveSubscriptions.useQuery(
      {
        petId: pet?.id ?? '',
        clientId: pet?.clientId ?? '',
      },
      {
        enabled:
          globalSingleton.currentStore.features?.health_plan_enabled &&
          pet?.id != null &&
          pet?.clientId != null,
        gcTime: 0,
        staleTime: 0,
      },
    );

  const showPrescriptionDetails =
    pet != null && showPrescriptions && getIsVetStore();

  const [bundle, setBundle] = useState<
    TrpcRouterOutputs['store']['searchBundles'][0] | null
  >(null);
  const items = [...formik.values.services, ...formik.values.products];

  const hasGlobalDiscount = formik.values.apply_discount;
  const {
    totalNetPreDiscount,
    totalDiscount,
    totalGross,
    totalVat,
    totalPrice,
  } = getBillingInfoFromItems({
    items,
    hasGlobalDiscount,
    globalDiscount: formik.values.discount,
    globalDiscountType: formik.values.discount_type,
  });

  if (showGlobalDiscount === false && hasGlobalDiscount) {
    console.error(
      `showGlobalDiscount is false but hasGlobalDiscount is true. This is a bug - there shouldn't be a global discount in this context. Showing global discount anyway.`,
      {
        petId: pet?.id,
      },
    );
    showGlobalDiscount = true;
  }

  const hasDiscountsPermission = useMemo(() => {
    return hasEmployeePermissions(PERMISSION_CATEGORY.DISCOUNTS);
  }, []);
  const [availableServiceAllowances, setAvailableServiceAllowances] = useState<
    Record<string, RemainingAllowanceQuantityDataType[]>
  >({});
  const [availableProductAllowances, setAvailableProductAllowances] = useState<
    Record<string, RemainingAllowanceQuantityDataType[]>
  >({});

  useEffect(() => {
    if (healthPlanSubscriptions != null) {
      const { productAllowances, serviceAllowances } =
        calculateAvailableAllowances({
          clientSubscriptions:
            healthPlanSubscriptions.clientSubscriptions ?? [],
          petSubscriptions: healthPlanSubscriptions.petSubscriptions ?? [],
        });
      setAvailableProductAllowances(productAllowances);
      setAvailableServiceAllowances(serviceAllowances);
    } else {
      setAvailableProductAllowances({});
      setAvailableServiceAllowances({});
    }
  }, [healthPlanSubscriptions]);

  useEffect(() => {
    if (formik.values.totalPrice != null) {
      formik.setFieldValue('totalPrice', totalPrice);
    }
  }, [totalPrice]);

  useEffect(() => {
    for (let i = 0; i < formik.values.services.length; i++) {
      const { itemTotalPrice, itemDiscount } = getBillingInfoFromItem(
        formik.values.services[i],
      );

      formik.setFieldValue(`services[${i}].price`, itemTotalPrice);
      formik.setFieldValue(`services[${i}].amount_discount`, itemDiscount);
    }
  }, [formik.values.services]);

  useEffect(() => {
    for (let i = 0; i < formik.values.products.length; i++) {
      const { itemTotalPrice, itemDiscount } = getBillingInfoFromItem(
        formik.values.products[i],
      );

      formik.setFieldValue(`products[${i}].price`, itemTotalPrice);
      formik.setFieldValue(`products[${i}].amount_discount`, itemDiscount);

      const batches = formik.values.products[i].batches;

      if (batches != null && batches.length > 0) {
        formik.setFieldValue(
          `products[${i}].quantity`,
          batches.reduce((acc, batch) => acc + batch.quantity, 0),
        );
      }
    }
  }, [formik.values.products]);

  const handleGlobalDiscount = (newDiscount: number) => {
    formik.setValues(
      produce((draft) => {
        draft.discount = newDiscount;
      }),
    );
  };

  const handleGlobalDiscountChange = (
    event: React.SyntheticEvent,
    checked: boolean,
  ) => {
    const isDiscountApplied = checked;

    formik.setValues(
      produce((draft) => {
        draft.apply_discount = isDiscountApplied;
        if (!isDiscountApplied) {
          draft.discount = 0;
        }
      }),
    );
  };

  const handleGlobalDiscountTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newDiscountType: DISCOUNT_TYPE,
  ) => {
    if (newDiscountType) {
      formik.setValues(
        produce((draft) => {
          draft.discount = 0;
          draft.discount_type = newDiscountType;
        }),
      );
    }
  };

  return (
    <>
      <Stack direction='column' gap={2}>
        <Stack
          direction='row'
          spacing={2}
          alignItems='center'
          justifyContent='space-between'
          flexGrow={1}
        >
          <div>
            {shouldShowAddBundle && (
              <AutocompleteSearch<{
                ItemType: TrpcRouterOutputs['store']['searchBundles'][0];
              }>
                trpcFunction={trpc.store.searchBundles}
                label='Add Bundle'
                getOptionLabel={(option) => option.name}
                value={bundle}
                onSelect={(item) => {
                  setBundle(item);

                  if (item == null) {
                    return;
                  }

                  formik.setValues(
                    produce((draft) => {
                      item.billing_services.forEach((service) => {
                        draft.services.push({
                          id: uuidv4(),
                          service_id: service.service_id,
                          discount: service.discount,
                          discount_type: service.discount_type,
                          name: service.name,
                          price: service.price,
                          quantity: service.quantity,
                          unit_price: service.unit_price,
                          vat_percentage: service.vat_percentage,
                          service_detail: service.service_detail,
                        });
                      });

                      item.billing_products.forEach((product) => {
                        draft.products.push({
                          id: uuidv4(),
                          product_id: product.product_id,
                          discount: product.discount,
                          discount_type: product.discount_type,
                          name: product.name,
                          price: product.price,
                          quantity: product.quantity,
                          unit_price: product.unit_price,
                          vat_percentage: product.vat_percentage,
                          product_detail: product.product_detail,
                        });
                      });
                    }),
                  );
                }}
                sx={{ minWidth: 300, pt: 1 }}
              />
            )}
          </div>
        </Stack>

        <Stack direction='column' gap={1}>
          <Typography variant='h6' gutterBottom>
            Services
          </Typography>
          <Card>
            <AppointmentsInvoiceServicesList
              services={formik.values.services}
              onServiceChange={(index, service) => {
                formik.setValues(
                  produce((draft) => {
                    draft.services[index] = service;
                  }),
                );
              }}
              onServiceRemove={(index) => {
                formik.setValues(
                  produce((draft) => {
                    draft.services.splice(index, 1);
                  }),
                );
              }}
              showUnitPrice={showUnitPrice}
              shouldShowVat={shouldShowVat}
              hasDiscountsPermission={hasDiscountsPermission}
              availableServiceAllowances={availableServiceAllowances}
              setAvailableServiceAllowances={setAvailableServiceAllowances}
            />
          </Card>
        </Stack>

        {formik.values.products.length > 0 && (
          <Stack direction='column' gap={1}>
            <Typography variant='h6' gutterBottom>
              Products
            </Typography>
            <Card>
              <AppointmentsInvoiceProductsList
                products={formik.values.products}
                onProductChange={(index, product) => {
                  formik.setValues(
                    produce((draft) => {
                      draft.products[index] = product;
                    }),
                  );
                }}
                onProductRemove={(index) => {
                  formik.setValues(
                    produce((draft) => {
                      draft.products.splice(index, 1);
                    }),
                  );
                }}
                showUnitPrice={showUnitPrice}
                shouldShowVat={shouldShowVat}
                hasDiscountsPermission={hasDiscountsPermission}
                availableProductAllowances={availableProductAllowances}
                setAvailableProductAllowances={setAvailableProductAllowances}
                showPrescriptionDetails={showPrescriptionDetails}
                onPrescriptionClick={(index) => {
                  if (showPrescriptionDetails) {
                    prescriptionDialog.handleOpen({
                      petId: pet.id,
                      clientId: pet.clientId,
                      product: formik.values.products[index],
                      setProduct: (product) => {
                        formik.setValues(
                          produce((draft) => {
                            draft.products[index] = product;
                          }),
                        );
                      },
                    });
                  }
                }}
              />
            </Card>
          </Stack>
        )}

        {showPrescriptionDetails &&
          formik.values.prescribed_products?.length > 0 && (
            <Stack direction='column' gap={1}>
              <Typography variant='h6' gutterBottom>
                Prescribed Only
              </Typography>
              <Card>
                <AppointmentsInvoicePrescribedProductsList
                  products={formik.values.prescribed_products}
                  onProductChange={(index, prescribedProduct) => {
                    formik.setValues(
                      produce((draft) => {
                        draft.prescribed_products[index] = {
                          ...draft.prescribed_products[index],
                          ...prescribedProduct,
                        };
                      }),
                    );
                  }}
                  onProductRemove={(index) => {
                    formik.setValues(
                      produce((draft) => {
                        draft.prescribed_products.splice(index, 1);
                      }),
                    );
                  }}
                  onPrescriptionClick={(index) => {
                    prescriptionDialog.handleOpen({
                      petId: pet.id,
                      clientId: pet.clientId,
                      product: formik.values.prescribed_products[index],
                      setProduct: (product) => {
                        formik.setValues(
                          produce((draft) => {
                            draft.prescribed_products[index] = product;
                          }),
                        );
                      },
                    });
                  }}
                />
              </Card>
            </Stack>
          )}

        <Stack direction='row' justifyContent='center'>
          <ButtonGroup variant='contained'>
            <Button
              onClick={() => {
                formik.setValues(
                  produce((draft) => {
                    draft.services.push(
                      getBillingInfoFromSearchedService(null),
                    );
                  }),
                );
              }}
            >
              Add Service
            </Button>
            <Button
              onClick={() => {
                formik.setValues(
                  produce((draft) => {
                    draft.products.push(
                      getBillingInfoFromSearchedProduct(null),
                    );
                  }),
                );
              }}
            >
              Add Product
            </Button>
            <DropdownMenuButton
              options={[
                {
                  name: 'Add Prescribed Only Product',
                  onClick: () => {
                    formik.setValues(
                      produce((draft) => {
                        draft.prescribed_products.push({
                          id: uuidv4(),
                          name: '',
                          quantity: 1,
                          price: 0,
                          unit_price: 0,
                          discount: 0,
                          discount_type: DISCOUNT_TYPE.PERCENTAGE,
                          vat_percentage:
                            globalSingleton.currentStore.vat_percentage,
                        });
                      }),
                    );
                  },
                },
              ]}
            />
          </ButtonGroup>
        </Stack>

        <AppointmentsBillingSummary
          showGlobalDiscount={showGlobalDiscount}
          applyDiscount={formik.values.apply_discount}
          discountType={formik.values.discount_type}
          discountValue={formik.values.discount}
          hasDiscountsPermission={hasDiscountsPermission}
          totalNetPreDiscount={totalNetPreDiscount}
          totalDiscount={totalDiscount}
          totalGross={totalGross}
          totalVat={totalVat}
          totalPrice={totalPrice}
          onApplyDiscountChange={handleGlobalDiscountChange}
          onDiscountTypeChange={handleGlobalDiscountTypeChange}
          onDiscountValueChange={handleGlobalDiscount}
        />
      </Stack>

      {createServiceDialog.open && (
        <DialogSection
          title='Create Service'
          open={createServiceDialog.open}
          onClose={createServiceDialog.handleClose}
        >
          <UpsertStoreServiceSettingsModal
            onClose={createServiceDialog.handleClose}
          />
        </DialogSection>
      )}

      {createProductDialog.open && (
        <DialogSection
          title='Create Product'
          open={createProductDialog.open}
          onClose={createProductDialog.handleClose}
        >
          <CreateProductDialog onClose={createProductDialog.handleClose} />
        </DialogSection>
      )}

      {prescriptionDialog.open && prescriptionDialog.data && (
        <DialogSection
          title='Prescription'
          open={prescriptionDialog.open}
          onClose={prescriptionDialog.handleClose}
          maxWidth='xl'
        >
          <AppointmentUpsertPrescriptionDialog
            product={prescriptionDialog.data.product}
            petId={prescriptionDialog.data.petId}
            clientId={prescriptionDialog.data.clientId}
            setProduct={prescriptionDialog.data.setProduct}
            handleClose={prescriptionDialog.handleClose}
          />
        </DialogSection>
      )}
    </>
  );
}
