const mathUtils = {
  roundNumber: (value: number, decimals = 2): number =>
    Math.round(value * 10 ** decimals) / 10 ** decimals,

  roundCurrency: (value: number): number => mathUtils.roundNumber(value, 2),

  getGrowthPercentage: (oldValue: number, newValue: number): number => {
    if (oldValue === 0) {
      return 100;
    }

    return mathUtils.roundNumber(((newValue - oldValue) / oldValue) * 100);
  },

  toPercentage: (discount: Nullish<number>): number => {
    if (discount == null) {
      return 1;
    }

    return discount / 100;
  },

  getIncreaseFactor: (n: Nullish<number>): number => {
    return 1 + mathUtils.toPercentage(n);
  },

  getDiscountsValue: (
    value: number,
    discounts: Array<Nullish<number>>,
  ): number => {
    const discountValues = discounts.map((discount) => {
      return value * mathUtils.toPercentage(discount);
    });

    return discountValues.reduce((acc, discount) => {
      return acc + discount;
    }, 0);
  },

  toFixed<T extends number | undefined | null>(
    value: T,
    fractionDigits = 2,
  ): T {
    if (value == null) {
      return value;
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Number(value.toFixed(fractionDigits)) as T;
  },
};

export default mathUtils;
