import { useState } from 'react';

import { Form } from '@lupa/ui/components/shadcn/form';
import { cn } from '@lupa/ui/lib/utils';
import { DISCOUNT_TYPE } from '@lupa/utils/enums';
import { MakeNonNullishFieldsNullable } from '@lupa/utils/types/utils';
import {
  CreateAppointmentValidatorDataType,
  createAppointmentValidatorData,
} from '@lupa/utils/validators/appointmentValidators';
import { createBillingItemsSchema } from '@lupa/utils/validators/invoiceValidators';
import { invalidateAppointmentQueries } from '@lupa/work/api/mutations';
import { useRouter } from '@lupa/work/hooks/use-router';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';
import { paths } from '@lupa/work/paths';

import { zodResolver } from '@hookform/resolvers/zod';

import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { UpsertBillingItemsFormType } from '../../invoices/UpsertBillingItemsForm';
import NewAppointmentUpsertCalendarView from './NewAppointmentUpsertCalendarView';
import NewAppointmentUpsertConfirmSection from './NewAppointmentUpsertConfirmSection';
import NewAppointmentUpsertDetailsSection from './NewAppointmentUpsertDetailsSection';
import NewAppointmentUpsertInvoicingView from './NewAppointmentUpsertInvoicingView';

type Appointment = TrpcRouterOutputs['appointments']['getAppointment'];

export type NewAppointmentUpdateType =
  MakeNonNullishFieldsNullable<CreateAppointmentValidatorDataType>;

const useGetInitialValues = (
  appointment: Appointment,
): NewAppointmentUpdateType => {
  return {
    pet: appointment.pet,
    client: appointment.client,
    employee:
      appointment.employees.find((e) => e.is_main_employee)?.employee ?? null,
    appointment: {
      id: appointment.id,
      start: appointment.start,
      end: appointment.end,
      // @ts-ignore TODO: Fix this once we have better  MakeNonNullishFieldsNullable for embedded fields
      visit_type:
        appointment.visit_type_id && appointment.visit_type_name
          ? {
              id: appointment.visit_type_id,
              name: appointment.visit_type_name,
              category: appointment.visit_type_category,
            }
          : null,
      status: appointment.status,
      title: appointment.title,
      location: appointment.location,
      client_notes: appointment.client_notes,
      booking_notes: appointment.booking_notes,
      notes: appointment.notes,
      multi_id: null,
    },
    room: appointment.room
      ? {
          id: appointment.room.id,
          name: appointment.room.name,
        }
      : null,
    totalPrice: appointment.invoice.amount_due,
    apply_discount: appointment.apply_discount,
    discount: appointment.discount,
    discount_type: appointment.discount_type,
    services: [],
    products: [],
    prescribed_products: [],
    notifyUser: true,
    notificationReason: null,
  };
};

interface NewAppointmentUpdateProps {
  appointment: Appointment;
  handleClose?: () => void;
  isNotification?: boolean;
}

export default function NewAppointmentUpdate({
  appointment,
  handleClose,
  isNotification,
}: NewAppointmentUpdateProps) {
  const [activeStep, setActiveStep] = useState(0);
  const initialValues = useGetInitialValues(appointment);
  const router = useRouter();

  const form = useForm<NewAppointmentUpdateType>({
    resolver: zodResolver(createAppointmentValidatorData),
    defaultValues: initialValues,
    mode: 'onTouched',
  });

  const billingItemsForm = useForm<UpsertBillingItemsFormType>({
    resolver: zodResolver(createBillingItemsSchema),
    defaultValues: {
      apply_discount: false,
      discount_type: DISCOUNT_TYPE.PERCENTAGE,
      discount_amount: 0,
    },
    mode: 'onTouched',
  });

  const upsertAppointmentMutation =
    trpc.appointments.updateAppointment.useMutation({
      onSuccess: ({ updatedAppointmentId }) => {
        invalidateAppointmentQueries(updatedAppointmentId, isNotification);

        toast.success('Appointment updated');
        form.reset();

        router.replace(paths.calendar.index);
        handleClose?.();
      },
      onError: () => {
        toast.error('Something went wrong!');
        handleClose?.();
      },
    });

  const onSubmit = async (data: NewAppointmentUpdateType) => {
    const parsedAppointmentData =
      createAppointmentValidatorData.safeParse(data);
    const parsedBillingData = createBillingItemsSchema.safeParse(
      billingItemsForm.getValues(),
    );

    if (!parsedAppointmentData.success) {
      console.error(parsedAppointmentData.error.errors);
    }

    if (!parsedBillingData.success) {
      console.error(parsedBillingData.error.errors);
    }

    if (parsedAppointmentData.success && parsedBillingData.success) {
      await upsertAppointmentMutation.mutateAsync({
        appointmentId: appointment.id,
        data: {
          ...parsedAppointmentData.data,
          ...parsedBillingData.data,
        },
      });
    }
  };

  return (
    <Form {...form}>
      <div className='grid h-full grid-cols-3 gap-1'>
        <div className='h-full rounded-md bg-slate-800 p-2'>
          <div className='mb-2 flex flex-row items-center justify-between gap-2'>
            <div className='bg-primary h-2 w-full rounded-md'></div>
            <div
              className={cn('h-2 w-full rounded-md bg-slate-700', {
                'bg-primary': activeStep === 1,
              })}
            ></div>
          </div>

          {activeStep === 0 && (
            <NewAppointmentUpsertDetailsSection
              form={form}
              action='update'
              setActiveStep={(step) => {
                setActiveStep(step);
              }}
            />
          )}

          {activeStep === 1 && (
            <NewAppointmentUpsertConfirmSection
              form={form}
              setActiveStep={setActiveStep}
              initialValues={initialValues}
              onConfirm={form.handleSubmit(onSubmit)}
            />
          )}
        </div>

        <div className='col-span-2 h-full p-2'>
          {activeStep === 0 && (
            <NewAppointmentUpsertCalendarView form={form} isThreeDaysView />
          )}

          {activeStep === 1 && (
            <NewAppointmentUpsertInvoicingView
              form={form}
              billingItemsForm={billingItemsForm}
            />
          )}
        </div>
      </div>
    </Form>
  );
}
