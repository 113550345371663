// Concat<['a', 'b', 'c']> = 'abc'
type Concat<T extends string[]> = T extends [
  infer F extends string,
  ...infer R extends string[],
]
  ? `${F}${Concat<R>}`
  : '';

// staticConcat('a', 'b', 'c') = 'abc', but typescript knows the result has type `"abc"`!
export function staticConcat<T extends string[]>(...strings: T): Concat<T> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return strings.join('') as Concat<T>;
}

/**
 * Formats a list of names into a grammatically correct string, optionally adding a verb suffix.
 *
 * @example
 * formatNames({ names: ['Alice', 'Bob'], verbSuffix: { singular: 'is', plural: 'are' } })
 * // Returns: 'Alice and Bob are'
 *
 * @example
 * formatNames({ names: ['Alice', 'Bob', 'Charlie'] })
 * // Returns: 'Alice, Bob and Charlie'
 */
export const formatNames = ({
  names,
  verbSuffix,
}: {
  names: string[];
  verbSuffix?: { singular: string; plural: string };
}): string | null => {
  if (names.length === 0) {
    return null;
  }
  const allNames = new Intl.ListFormat('en-US', {
    style: 'long',
    type: 'conjunction',
  }).format(names);

  const suffix = verbSuffix
    ? ` ${names.length > 1 ? verbSuffix.plural : verbSuffix.singular}`
    : '';
  return allNames + suffix;
};

/**
 * Splits a string by a given regular expression.
 *
 * @param input - The input string to split.
 * @param regex - The regular expression to split the string by.
 * @returns An object containing the parts of the split string, or null if no match is found.
 *
 * @example
 * const result = splitStringByRegex("Hello, world!", /, /);
 * // Returns: { inputBeforeMatch: "Hello", match: ", ", inputAfterMatch: "world!" }
 */
export function splitStringByRegex(input: string, regex: RegExp) {
  const match = regex.exec(input);
  if (match == null) {
    return null;
  }

  const inputBeforeMatch = input.slice(0, match.index);
  const inputAfterMatch = input.slice(match.index + match[0].length);

  return { inputBeforeMatch, match: match[0], inputAfterMatch };
}

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    // Adjust the color to be lighter
    const lightValue = Math.floor((value + 255) / 2);
    color += `00${lightValue.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function getInitials(name?: string) {
  if (!name) return '';
  return name
    .split(' ')
    .map((word) => word[0])
    .filter((char) => isNaN(Number(char)))
    .join('')
    .slice(0, 2)
    .toUpperCase();
}

export function getFlagEmoji(iso2: string) {
  const codePoints = iso2
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
