import { useState } from 'react';

import { Button } from '@lupa/ui/components/shadcn/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@lupa/ui/components/shadcn/dialog';
import { Input } from '@lupa/ui/components/shadcn/input';

interface AddRoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddRole: (roleName: string) => void;
}

export default function AddRoleModal({
  isOpen,
  onClose,
  onAddRole,
}: AddRoleModalProps) {
  const [newRoleName, setNewRoleName] = useState('');

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add New Role</DialogTitle>
        </DialogHeader>
        <Input
          value={newRoleName}
          onChange={(e) => setNewRoleName(e.target.value)}
          placeholder='Enter role name'
          className='mb-4'
        />
        <DialogFooter>
          <Button type='button' variant='outline' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' onClick={() => onAddRole(newRoleName)}>
            Add Role
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
