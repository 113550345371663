import React from 'react';

import { cn } from '@lupa/ui/lib/utils';

import { IconCalendar, IconX } from '@tabler/icons-react';

import { format } from 'date-fns';
import type { DayPicker } from 'react-day-picker';

import TimePicker from '../TimePicker';
import { Button } from '../shadcn/button';
import { Calendar } from '../shadcn/calendar';
import { FormItem } from '../shadcn/form';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/popover';
import FormHelperText from './FormHelperText';
import FormLabel from './FormLabel';
import InputBaseProps from './InputBaseProps';

type Granularity = 'day' | 'hour' | 'minute' | 'second';

interface DateInputProps<TClearable extends boolean>
  extends InputBaseProps<
    string | undefined | null,
    TClearable extends true ? string | null : string
  > {
  calendarDisabled?: React.ComponentProps<typeof DayPicker>['disabled'];
  clearable?: TClearable;
  /**
   * Determines the smallest unit that is displayed in the datetime picker.
   * Default is 'second'.
   * */
  granularity?: Granularity;
}

export default function DateInput<TClearable extends boolean = false>({
  className,
  placeholder = 'Pick a date',
  error,
  required,
  label,
  helperText,
  hint,
  value,
  onChange,
  afterValueChange,
  disabled,
  calendarDisabled,
  clearable,
  granularity = 'day',
  ...props
}: DateInputProps<TClearable>) {
  return (
    <FormItem className='space-y-1'>
      <FormLabel label={label} required={required} hint={hint} />

      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            className={cn(
              'h-9 w-[100%] pl-3 text-left font-normal',
              !value && 'text-muted-foreground',
              className,
            )}
          >
            {value ? (
              format(value, granularity === 'day' ? 'PPP' : 'PPP HH:mm')
            ) : (
              <span>{placeholder}</span>
            )}

            <div className='ml-auto flex items-center gap-1'>
              {clearable && value && (
                <Button
                  asChild
                  size='icon'
                  variant='ghost'
                  className='z-10 h-7 w-7 focus-visible:outline-none'
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(
                      null as TClearable extends true ? string | null : string,
                    );
                    afterValueChange?.(
                      null as TClearable extends true ? string | null : string,
                    );
                  }}
                >
                  <span>
                    <IconX
                      size={16}
                      strokeWidth={2}
                      className='text-muted-foreground/80 z-10 shrink-0'
                      aria-hidden='true'
                    />
                  </span>
                </Button>
              )}

              <IconCalendar className='h-4 w-4 opacity-50' />
            </div>
          </Button>
        </PopoverTrigger>

        <PopoverContent className='w-auto p-0' align='start'>
          <Calendar
            mode='single'
            selected={value ? new Date(value) : undefined}
            captionLayout='dropdown-buttons'
            fromYear={1960}
            toYear={2060}
            onSelect={(date) => {
              if (!date || isNaN(date.getTime())) {
                return;
              }

              if (granularity === 'day' || value == null) {
                onChange(date.toISOString());
                afterValueChange?.(date.toISOString());
                return;
              }

              // Merge date and time
              const mergedDate = new Date(date.toISOString());
              mergedDate.setHours(new Date(value).getHours());
              mergedDate.setMinutes(new Date(value).getMinutes());

              onChange(mergedDate.toISOString());
              afterValueChange?.(mergedDate.toISOString());
            }}
            initialFocus
            disabled={disabled || calendarDisabled}
            {...props}
          />

          {granularity !== 'day' && (
            <div className='border-border border-t p-3'>
              <TimePicker
                onChange={(date) => {
                  if (!date || isNaN(date.getTime())) {
                    return;
                  }

                  onChange(date.toISOString());
                  afterValueChange?.(date.toISOString());
                }}
                date={value ? new Date(value) : undefined}
                granularity={granularity}
              />
            </div>
          )}
        </PopoverContent>
      </Popover>

      <FormHelperText error={error} helperText={helperText} />
    </FormItem>
  );
}
