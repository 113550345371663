import { useMemo } from 'react';

import AutocompleteInput from '@lupa/ui/components/form/AutocompleteInput';
import NumberInput from '@lupa/ui/components/form/NumberInput';
import { Button } from '@lupa/ui/components/shadcn/button';
import { FormField } from '@lupa/ui/components/shadcn/form';
import { TableCell, TableRow } from '@lupa/ui/components/shadcn/table';
import {
  RemainingAllowanceQuantityDataType,
  getAvailableAllowancesForItem,
} from '@lupa/utils/healthplanUtils';
import { getAllowanceNewNameLabel } from '@lupa/work/utils/healthplan-utils';

import { IconPlus, IconTrash } from '@tabler/icons-react';

import { UseFormReturn } from 'react-hook-form';
import { match } from 'ts-pattern';

import type { UpsertBillingItemsFormType } from './UpsertBillingItemsForm';
import type { ProductField } from './UpsertBillingProductForm';
import type { ServiceField } from './UpsertBillingServiceForm';
import billingItemsDataUtils from './upsertBillingItemsFormUtils';

interface UpsertBillingItemAllowanceUsageFormProps {
  form: UseFormReturn<UpsertBillingItemsFormType>;
  identifier:
    | {
        type: 'service';
        idx: number;
        data: ServiceField;
      }
    | {
        type: 'product';
        idx: number;
        data: ProductField;
      };
  availableAllowances: Record<string, RemainingAllowanceQuantityDataType[]>;
}

export default function UpsertBillingItemAllowanceUsageForm({
  form,
  identifier,
  availableAllowances,
}: UpsertBillingItemAllowanceUsageFormProps) {
  const allowancesOptions = useMemo(() => {
    const itemId = match(identifier)
      .with({ type: 'service' }, (service) => service.data.service_id)
      .with({ type: 'product' }, (product) => product.data.product_id)
      .exhaustive();

    const itemCategory = match(identifier)
      .with(
        { type: 'service' },
        (service) => service.data.service_detail?.category,
      )
      .with(
        { type: 'product' },
        (product) => product.data.product_detail?.category,
      )
      .exhaustive();

    return getAvailableAllowancesForItem({
      itemId,
      itemCategory,
      availableAllowances,
    });
  }, [identifier, availableAllowances]);

  const itemAllowances = match(identifier)
    .with(
      { type: 'service' },
      () => form.watch(`services.${identifier.idx}.allowance_usage`) ?? [],
    )
    .with(
      { type: 'product' },
      () => form.watch(`products.${identifier.idx}.allowance_usage`) ?? [],
    )
    .exhaustive();

  if (allowancesOptions.length === 0) {
    return null;
  }

  return (
    <TableRow className='!border-t-2 border-t-white'>
      <TableCell colSpan={12} className='px-8'>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-row items-center justify-between'>
            <p className='text-muted-foreground font-semibold'>Allowances</p>
            <Button
              variant='outline'
              size='sm'
              disabled={itemAllowances.length >= 1}
              onClick={() => {
                const nextOptionAllowance = allowancesOptions[0];

                billingItemsDataUtils.onItemAllowanceSelected({
                  form,
                  identifier,
                  newAllowance: nextOptionAllowance,
                });
              }}
            >
              <IconPlus />
              Add Allowance
            </Button>
          </div>
        </div>

        {itemAllowances.map((serviceAllowance, idx) => (
          <div
            key={`${serviceAllowance.id}-${idx}`}
            className='flex flex-row gap-2'
          >
            <AutocompleteInput
              value={
                serviceAllowance.allowance
                  ? {
                      id: serviceAllowance.allowance_id,
                      label: getAllowanceNewNameLabel({
                        allowance: serviceAllowance.allowance,
                      }),
                    }
                  : null
              }
              onChange={(value) => {
                const selectedAllowance = allowancesOptions.find(
                  (option) => option.allowance_id === value?.id,
                );

                if (selectedAllowance == null) {
                  return;
                }

                billingItemsDataUtils.onItemAllowanceSelected({
                  form,
                  identifier,
                  newAllowance: selectedAllowance,
                });
              }}
              onBlur={() => {
                match(identifier)
                  .with({ type: 'service' }, () =>
                    form.trigger(
                      `services.${identifier.idx}.allowance_usage.${idx}.allowance_id`,
                    ),
                  )
                  .with({ type: 'product' }, () =>
                    form.trigger(
                      `products.${identifier.idx}.allowance_usage.${idx}.allowance_id`,
                    ),
                  )
                  .exhaustive();
              }}
              options={allowancesOptions.map((option) => ({
                id: option.allowance_id,
                label: getAllowanceNewNameLabel({ allowance: option }),
                subtitle: `Remaining: ${option.remainingQuantity}`,
              }))}
              required={true}
              error={match(identifier)
                .with(
                  { type: 'service' },
                  () =>
                    form.formState.errors.services?.[identifier.idx]
                      ?.allowance_usage?.[idx]?.message,
                )
                .with(
                  { type: 'product' },
                  () =>
                    form.formState.errors.products?.[identifier.idx]
                      ?.allowance_usage?.[idx]?.message,
                )
                .exhaustive()}
            />

            <FormField
              control={form.control}
              name={match(identifier)
                .with(
                  { type: 'service' },
                  () =>
                    `services.${identifier.idx}.allowance_usage.${idx}.quantity` as const,
                )
                .with(
                  { type: 'product' },
                  () =>
                    `products.${identifier.idx}.allowance_usage.${idx}.quantity` as const,
                )
                .exhaustive()}
              render={({ field: { ref: _ref, ...fieldProps } }) => {
                return (
                  <NumberInput
                    {...fieldProps}
                    onChange={(newQuantity) => {
                      billingItemsDataUtils.onItemAllowanceQuantityChange({
                        form,
                        identifier,
                        newQuantity: newQuantity ?? 0,
                      });
                    }}
                    required={true}
                    error={match(identifier)
                      .with(
                        { type: 'service' },
                        () =>
                          form.formState.errors.services?.[identifier.idx]
                            ?.allowance_usage?.[idx]?.quantity?.message,
                      )
                      .with(
                        { type: 'product' },
                        () =>
                          form.formState.errors.products?.[identifier.idx]
                            ?.allowance_usage?.[idx]?.quantity?.message,
                      )
                      .exhaustive()}
                    className='w-[120px]'
                    isInteger={true}
                    minValue={0}
                    maxValue={billingItemsDataUtils.calculateItemAllowanceLimit(
                      {
                        form,
                        identifier,
                        allowancesOptions,
                        allowanceId: serviceAllowance.allowance_id,
                      },
                    )}
                  />
                );
              }}
            />

            <Button
              variant='destructiveGhost'
              size='icon'
              onClick={() => {
                billingItemsDataUtils.onItemAllowanceRemoved({
                  form,
                  identifier,
                });
              }}
            >
              <IconTrash />
            </Button>
          </div>
        ))}
      </TableCell>
    </TableRow>
  );
}
