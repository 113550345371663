import { Button } from '@lupa/ui/components/shadcn/button';

import { SaveIcon } from 'lucide-react';

interface SaveBottomBarProps {
  onSave: () => void;
  isDirty: boolean;
}

export default function SaveBottomBar({ onSave, isDirty }: SaveBottomBarProps) {
  return (
    <div className='bg-background border-border fixed bottom-0 left-0 right-0 flex justify-end border-t p-4'>
      <Button onClick={onSave} disabled={!isDirty}>
        <SaveIcon className='mr-2 h-4 w-4' />
        Save Changes
      </Button>
    </div>
  );
}
