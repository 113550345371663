import { z } from 'zod';

const CompanyBaseSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  parent_company_id: z.string().uuid().nullable(),
});

const StoreSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
});

export enum EnterpriseSelectionType {
  COMPANY = 'company',
  STORE = 'store',
}

export type StoreNode = z.infer<typeof StoreSchema>;

export type CompanyNode = z.infer<typeof CompanyBaseSchema> & {
  childrenCompanies: CompanyNode[];
  stores: StoreNode[];
};

const CompanyNodeSchema: z.ZodType<CompanyNode> = CompanyBaseSchema.extend({
  childrenCompanies: z.lazy(() => CompanyNodeSchema.array()),
  stores: z.array(StoreSchema),
});

export const GetCompanyTreeResponseSchema = CompanyNodeSchema.nullable();

export type EnterpriseTree = z.infer<typeof CompanyNodeSchema>;
export type GetCompanyTreeResponse = z.infer<
  typeof GetCompanyTreeResponseSchema
>;
