import React, { ComponentProps, useState } from 'react';

import AutocompleteInput from '@lupa/ui/components/form/AutocompleteInput';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';

type Employee = NonNullable<
  TrpcRouterOutputs['employees']['searchEmployees']
>[number];

type SearchEmployeesProps<
  TFreeSolo extends boolean = false,
  TClearable extends boolean = false,
> = Omit<
  ComponentProps<typeof AutocompleteInput<Employee, TFreeSolo, TClearable>>,
  'options'
>;

export default function SearchEmployees<
  TFreeSolo extends boolean = false,
  TClearable extends boolean = false,
>({ ...props }: SearchEmployeesProps<TFreeSolo, TClearable>) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string | undefined>(undefined);

  const { data, isLoading } = trpc.employees.searchEmployees.useQuery(
    {
      query,
    },
    {
      enabled: open,
    },
  );

  return (
    <AutocompleteInput<Employee, TFreeSolo, TClearable>
      {...props}
      options={data ?? []}
      loading={isLoading}
      onOpenChange={(o) => {
        setOpen(o);
      }}
      onCommandValueChange={(v) => {
        setQuery(v);
      }}
      alwaysShowAvatarFallback={true}
    />
  );
}
