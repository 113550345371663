import LoadingButton from './LoadingButton';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './shadcn/tooltip';

interface TooltipButtonProps
  extends Omit<React.ComponentProps<typeof LoadingButton>, 'loading'> {
  tooltip: string;
  loading?: boolean;
}

export default function TooltipButton({
  tooltip,
  loading = false,
  ...props
}: TooltipButtonProps) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <LoadingButton loading={loading} {...props} />
        </TooltipTrigger>

        <TooltipContent>
          <p className='max-w-xs text-xs'>{tooltip}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
