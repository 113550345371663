import React from 'react';

import { cn } from '@lupa/ui/lib/utils';

import { ChevronDown } from 'lucide-react';
import { Input, NumberField } from 'react-aria-components';

import FormHelperText from './FormHelperText';
import FormLabel from './FormLabel';
import InputBaseProps from './InputBaseProps';

interface NumberWithOptionsInputProps<TOption extends string>
  extends InputBaseProps<
    | { amount: number | null | undefined; option: TOption | null | undefined }
    | undefined
    | null,
    { amount: number | null; option: TOption | undefined }
  > {
  options: {
    label: string;
    value: TOption;
  }[];
  inlineStartAddOn?: React.ReactNode;
  inlineEndAddOn?: React.ReactNode;
  minValue?: number;
  maxValue?: number;
}

export default function NumberWithOptionsInput<TOption extends string>({
  error,
  required,
  label,
  helperText,
  value,
  onChange,
  afterValueChange,
  disabled,
  inlineStartAddOn,
  inlineEndAddOn,
  hint,
  options,
  minValue,
  maxValue,
  className,
  ...props
}: NumberWithOptionsInputProps<TOption>) {
  return (
    <NumberField
      value={value?.amount ?? Number.NaN}
      onChange={(newValue) => {
        const newValueCast = Number.isNaN(newValue) ? null : newValue;
        const newDiscount = {
          amount: newValueCast,
          option: value?.option ?? undefined,
        };

        onChange(newDiscount);
        afterValueChange?.(newDiscount);
      }}
      isDisabled={disabled}
      minValue={minValue}
      maxValue={maxValue}
    >
      <div className='flex rounded-lg shadow-sm shadow-black/5'>
        <FormLabel label={label} required={required} hint={hint} />

        <Input
          className={cn(
            'border-input focus-visible:border-ring focus-visible:ring-ring/20 h-9 w-12 appearance-none items-center justify-center rounded-none rounded-s-lg border border-e-0 text-center text-sm transition-shadow focus-visible:outline-none focus-visible:ring-[3px] disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50',
            inlineStartAddOn && 'peer ps-8',
            inlineEndAddOn && 'peer pe-8',
            !!error &&
              'border-destructive/80 focus-visible:border-destructive/80 focus-visible:ring-destructive/20',
            className,
          )}
          {...props}
        />

        <div className='relative inline-flex'>
          <select
            value={value?.option ?? undefined}
            disabled={disabled}
            onChange={(e) => {
              const newDiscount = {
                amount: value?.amount ?? null,
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                option: e.target.value as TOption,
              };
              onChange(newDiscount);
            }}
            className='border-input bg-background text-muted-foreground hover:bg-accent hover:text-accent-foreground focus-visible:border-ring focus-visible:text-foreground focus-visible:ring-ring/20 peer inline-flex h-full appearance-none items-center rounded-none rounded-e-lg border pe-8 ps-2 text-sm transition-shadow focus:z-10 focus-visible:outline-none focus-visible:ring-[3px] disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50'
            aria-label='Domain suffix'
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <span className='text-muted-foreground/80 pointer-events-none absolute inset-y-0 end-0 z-10 flex h-full w-9 items-center justify-center peer-disabled:opacity-50'>
            <ChevronDown
              size={16}
              strokeWidth={2}
              aria-hidden='true'
              role='img'
            />
          </span>
        </div>

        {inlineStartAddOn && (
          <span className='text-muted-foreground pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-3 text-sm peer-disabled:opacity-50'>
            {inlineStartAddOn}
          </span>
        )}

        {inlineEndAddOn && (
          <span className='text-muted-foreground pointer-events-none absolute inset-y-0 end-0 flex items-center justify-center pe-3 text-sm peer-disabled:opacity-50'>
            {inlineEndAddOn}
          </span>
        )}
      </div>

      <FormHelperText error={error} helperText={helperText} />
    </NumberField>
  );
}
