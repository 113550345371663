import React, { Fragment, PropsWithChildren, ReactNode } from 'react';

import LoadingButton from '@lupa/ui/components/LoadingButton';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@lupa/ui/components/shadcn/accordion';
import { Card, CardContent } from '@lupa/ui/components/shadcn/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@lupa/ui/components/shadcn/dropdown-menu';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@lupa/ui/components/shadcn/tooltip';
import { cn } from '@lupa/ui/lib/utils';

import { useAtom } from 'jotai';
import { atomFamily, atomWithStorage } from 'jotai/utils';

import { Button } from './shadcn/button';

type ActionsSlotFunctionProps = {
  isOpen: string; // This is a string because it's used as a key in the shadcn/radix-ui accordion
  setIsOpen: (s: string) => void;
};
type ActionsSlotFunction<T> = ({
  isOpen,
  setIsOpen,
}: ActionsSlotFunctionProps) => T;
type CollapsibleCardProps = PropsWithChildren<{
  id?: string;
  title: ReactNode;
  actions?: ReactNode | ActionsSlotFunction<ReactNode>;
  storageKey: string;
  className?: string;
}>;

// Create atom family outside the component
const collapsibleCardAtomFamily = atomFamily((key: string) =>
  atomWithStorage(`lupa_collapsibleCard_${key}`, key, undefined, {
    getOnInit: true,
  }),
);

const CollapsibleCard = ({
  title,
  actions,
  storageKey = 'collapsibleCard',
  className,
  children,
  ...rest
}: CollapsibleCardProps) => {
  const [isOpen, setIsOpen] = useAtom(collapsibleCardAtomFamily(storageKey));

  const renderActions = () => {
    if (typeof actions === 'function') {
      return actions({ isOpen, setIsOpen });
    }
    return actions;
  };

  return (
    <Card className='rounded-2xl border-0' {...rest}>
      <CardContent className={cn('p-0', className)}>
        <Accordion
          defaultValue={isOpen}
          value={isOpen}
          onValueChange={setIsOpen}
          type='single'
          collapsible
        >
          <AccordionItem
            value={storageKey}
            className='overflow-visible border-none'
          >
            <AccordionTrigger
              className='px-6 hover:no-underline'
              actions={renderActions()}
            >
              <div className='flex w-full items-center justify-between pr-4'>
                {typeof title !== 'string' ? (
                  title
                ) : (
                  <div>
                    <h6 className='text-lg font-semibold'>{title}</h6>
                    <div className='bg-primary/50 -mt-1 h-1 w-20 rounded'></div>
                  </div>
                )}
              </div>
            </AccordionTrigger>
            <AccordionContent className='p-6'>{children}</AccordionContent>
          </AccordionItem>
        </Accordion>
      </CardContent>
    </Card>
  );
};

type CardAction = {
  icon?: ReactNode;
  title?: string;
  tooltip?: string;
  onClick: VoidFunction | (() => Promise<void>);
  attributes?: Record<string, unknown>;
};
type CollapsibleCardWithIconProps = Omit<CollapsibleCardProps, 'actions'> & {
  actions: CardAction[] | ActionsSlotFunction<CardAction[]>;
};
export const CollapsibleCardWithAction = ({
  actions,
  children,
  ...rest
}: CollapsibleCardWithIconProps) => {
  const renderActionsList = ({
    isOpen,
    setIsOpen,
  }: ActionsSlotFunctionProps) => {
    if (typeof actions === 'function') {
      return actions({ isOpen, setIsOpen });
    }
    return actions;
  };

  return (
    <CollapsibleCard
      {...rest}
      actions={({ isOpen, setIsOpen }) => (
        <>
          {renderActionsList({ isOpen, setIsOpen }).map((action) => {
            return (
              <Fragment key={action.title || action.tooltip}>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <LoadingButton
                        variant='secondary'
                        size='sm'
                        onClick={async (e) => {
                          e.stopPropagation();
                          await action.onClick();
                        }}
                        {...action.attributes}
                      >
                        {action.icon}
                        {action.title}
                      </LoadingButton>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{action.tooltip}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Fragment>
            );
          })}
        </>
      )}
    >
      {children}
    </CollapsibleCard>
  );
};

type CollapsibleCardWithDropdownProps = Omit<
  CollapsibleCardProps,
  'actions'
> & {
  trigger?: ReactNode | ActionsSlotFunction<ReactNode>;
  actions: CardAction[] | ActionsSlotFunction<CardAction[]>;
};
export const CollapsibleCardWithDropdown = ({
  trigger,
  actions,
  ...rest
}: CollapsibleCardWithDropdownProps) => {
  const renderActionsList = ({
    isOpen,
    setIsOpen,
  }: ActionsSlotFunctionProps) => {
    if (typeof actions === 'function') {
      return actions({ isOpen, setIsOpen });
    }
    return actions;
  };

  const renderTrigger = ({ isOpen, setIsOpen }: ActionsSlotFunctionProps) => {
    if (typeof trigger === 'function') {
      return trigger({ isOpen, setIsOpen });
    }
    if (typeof trigger === 'string') {
      return (
        <Button variant='secondary' size='sm'>
          {trigger}
        </Button>
      );
    }
    return trigger;
  };

  return (
    <CollapsibleCard
      {...rest}
      actions={({ isOpen, setIsOpen }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              {renderTrigger({ isOpen, setIsOpen }) ?? <>Actions</>}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {renderActionsList({ isOpen, setIsOpen }).map((action) => {
                return (
                  <DropdownMenuItem key={action.title} onClick={action.onClick}>
                    {action.icon}
                    {action.title}
                  </DropdownMenuItem>
                );
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      }}
    ></CollapsibleCard>
  );
};

export default CollapsibleCard;
