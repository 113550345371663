import NumberInput from '@lupa/ui/components/form/NumberInput';
import NumberWithOptionsInput from '@lupa/ui/components/form/NumberWithOptionsInput';
import { Badge } from '@lupa/ui/components/shadcn/badge';
import { Button } from '@lupa/ui/components/shadcn/button';
import { FormField } from '@lupa/ui/components/shadcn/form';
import { TableCell, TableRow } from '@lupa/ui/components/shadcn/table';
import { DISCOUNT_TYPE } from '@lupa/utils/enums';
import { RemainingAllowanceQuantityDataType } from '@lupa/utils/healthplanUtils';
import { formatCurrency } from '@lupa/work/utils/i18n';

import { IconTrash } from '@tabler/icons-react';

import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import UpsertBillingItemAllowanceUsageForm from './UpsertBillingItemAllowanceUsageForm';
import type { UpsertBillingItemsFormType } from './UpsertBillingItemsForm';
import billingItemsDataUtils from './upsertBillingItemsFormUtils';

export type ServiceField = UseFieldArrayReturn<
  UpsertBillingItemsFormType,
  'services'
>['fields'][number];

interface UpsertBillingServiceFormProps {
  form: UseFormReturn<UpsertBillingItemsFormType>;
  service: {
    type: 'service';
    idx: number;
    data: ServiceField;
  };
  onDelete: (idx: number) => void;
  availableAllowances: Record<string, RemainingAllowanceQuantityDataType[]>;
  hasDiscountsPermission: boolean;
}

export default function UpsertBillingServiceForm({
  form,
  service,
  onDelete,
  availableAllowances,
  hasDiscountsPermission,
}: UpsertBillingServiceFormProps) {
  const watchServiceAllowanceUsage = form.watch(
    `services.${service.idx}.allowance_usage`,
  );

  return (
    <>
      <TableRow key={service.data.id}>
        <TableCell className='font-medium'>
          <div className='flex flex-col items-start'>
            <p>{service.data.name}</p>
            <Badge variant='outline'>Service</Badge>
          </div>
        </TableCell>

        <TableCell>
          <FormField
            control={form.control}
            name={`services.${service.idx}.quantity` as const}
            render={({ field: { ref: _ref, ...fieldProps } }) => {
              return (
                <NumberInput
                  {...fieldProps}
                  required={true}
                  afterValueChange={(newQuantity) => {
                    billingItemsDataUtils.onServiceQuantityChange({
                      form,
                      serviceIdx: service.idx,
                      newQuantity,
                    });
                  }}
                  error={
                    form.formState.errors.services?.[service.idx]?.quantity
                      ?.message
                  }
                  className='w-[120px]'
                />
              );
            }}
          />
        </TableCell>

        <TableCell>
          <NumberWithOptionsInput
            value={{
              amount: form.watch(`services.${service.idx}.discount` as const),
              option: form.watch(
                `services.${service.idx}.discount_type` as const,
              ),
            }}
            options={Object.values(DISCOUNT_TYPE).map((type) => ({
              label: type,
              value: type,
            }))}
            onChange={(newDiscount) => {
              billingItemsDataUtils.onServiceDiscountChange({
                form,
                serviceIdx: service.idx,
                newDiscount,
              });
            }}
            onBlur={() => {
              form.trigger(`services.${service.idx}.discount`);
            }}
            error={
              form.formState.errors.services?.[service.idx]?.discount?.message
            }
            required={true}
            disabled={
              !hasDiscountsPermission ||
              (watchServiceAllowanceUsage?.length != null &&
                watchServiceAllowanceUsage?.length > 0)
            }
          />
        </TableCell>

        <TableCell className='text-center'>
          <p>
            {formatCurrency(
              form.watch(`services.${service.idx}.price` as const),
            )}
          </p>
        </TableCell>

        <TableCell className='text-right'>
          <Button
            variant='destructiveGhost'
            size='icon'
            onClick={() => {
              onDelete(service.idx);
            }}
          >
            <IconTrash />
          </Button>
        </TableCell>
      </TableRow>

      <UpsertBillingItemAllowanceUsageForm
        form={form}
        identifier={service}
        availableAllowances={availableAllowances}
      />
    </>
  );
}
