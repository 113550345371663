import React, { useEffect, useState } from 'react';

import LoadingButton from '@lupa/ui/components/LoadingButton';
import StyledSplitPills from '@lupa/ui/components/StyledSplitPills';
import { Button } from '@lupa/ui/components/shadcn/button';
import {
  APPOINTMENT_NOTIFICATION_REASON,
  APPOINTMENT_STATUS,
} from '@lupa/utils/enums';
import AppointmentNotification from '@lupa/work/components/appointments/AppointmentNotification';
import RichTextEditor from '@lupa/work/components/editors/RichTextEditor';
import { StatusPillDropdown } from '@lupa/work/components/ui/DropdownStatusPill';

import { Avatar } from '@mui/material';
import { IconCalendarMonth, IconClock, IconPaw } from '@tabler/icons-react';

import { format } from 'date-fns';
import { UseFormReturn } from 'react-hook-form';
import { match } from 'ts-pattern';

import type { NewAppointmentCreateType } from './NewAppointmentCreate';

type NewAppointmentUpsertConfirmSectionProps = {
  form: UseFormReturn<NewAppointmentCreateType>;
  setActiveStep: (step: number) => void;
  initialValues: NewAppointmentCreateType;
  onConfirm: () => void;
};

export default function NewAppointmentUpsertConfirmSection({
  form,
  setActiveStep,
  initialValues,
  onConfirm,
}: NewAppointmentUpsertConfirmSectionProps) {
  const [notifyUserChangedManually, setNotifyUserChangedManually] =
    useState(false);

  const watchAppointment = form.watch('appointment');
  const watchNotificationReason = form.watch('notificationReason');
  const watchNotifyUser = form.watch('notifyUser');
  const watchAppointmentStatus = form.watch('appointment.status');

  useEffect(() => {
    const currentStart = watchAppointment?.start;
    const currentStatus = watchAppointment?.status;

    if (!currentStart || !currentStatus) {
      return;
    }

    // For some status changes, we might need to notify the user
    if (
      (initialValues.appointment?.status !== APPOINTMENT_STATUS.CONFIRMED &&
        currentStatus === APPOINTMENT_STATUS.CONFIRMED) ||
      (initialValues.appointment?.status !== APPOINTMENT_STATUS.CANCELLED &&
        currentStatus === APPOINTMENT_STATUS.CANCELLED) ||
      (initialValues.appointment?.status !== APPOINTMENT_STATUS.NO_SHOW &&
        currentStatus === APPOINTMENT_STATUS.NO_SHOW)
    ) {
      const newNotificationReason = match(currentStatus)
        .with(
          APPOINTMENT_STATUS.CONFIRMED,
          () => APPOINTMENT_NOTIFICATION_REASON.CONFIRMED,
        )
        .with(
          APPOINTMENT_STATUS.CANCELLED,
          () => APPOINTMENT_NOTIFICATION_REASON.CANCELLED,
        )
        .with(
          APPOINTMENT_STATUS.NO_SHOW,
          () => APPOINTMENT_NOTIFICATION_REASON.NO_SHOW,
        )
        .exhaustive();
      form.setValue('notificationReason', newNotificationReason);
      if (!notifyUserChangedManually) {
        form.setValue('notifyUser', true);
      }

      return;
    }
    if (
      // For time changes for confirmed appointments, we might need to notify the user
      initialValues.appointment?.start.toString() !== currentStart.toString() &&
      currentStatus === APPOINTMENT_STATUS.CONFIRMED
    ) {
      form.setValue('notificationReason', APPOINTMENT_NOTIFICATION_REASON.TIME);

      if (!notifyUserChangedManually) {
        form.setValue('notifyUser', true);
      }

      return;
    }

    form.setValue('notificationReason', null);
    form.setValue('notifyUser', false);
  }, [watchAppointment?.start, watchAppointment?.status]);

  useEffect(() => {
    const currentStatus = watchAppointment?.status;

    if (currentStatus === APPOINTMENT_STATUS.REQUESTED) {
      // By default at this stage we assume that the appointment will be confirmed
      form.setValue('appointment.status', APPOINTMENT_STATUS.CONFIRMED);
    }
  }, [watchAppointment?.status]);

  return (
    <div className='flex flex-grow flex-col gap-4'>
      <div className='flex flex-col gap-2 rounded-lg bg-white/10 p-2'>
        <h6 className='font-medium text-white'>
          {form.getValues('appointment.title')}
        </h6>

        <div className='flex flex-row gap-1'>
          <StyledSplitPills style={{ justifyContent: 'center', width: '50%' }}>
            <div className='flex flex-row items-center gap-1'>
              <IconCalendarMonth />
              <p>
                {format(
                  new Date(form.getValues('appointment.start')),
                  'EEE, d MMM',
                )}
              </p>
            </div>

            <div className='flex flex-row items-center gap-1'>
              <IconClock />
              <p>
                {`${format(
                  new Date(form.getValues('appointment.start')),
                  'HH:mm',
                )} - ${format(
                  new Date(form.getValues('appointment.end')),
                  'HH:mm',
                )}`}
              </p>
            </div>
          </StyledSplitPills>

          <StyledSplitPills style={{ justifyContent: 'center', width: '50%' }}>
            <div className='flex flex-row items-center gap-1'>
              <IconPaw />
              <p>
                {form.getValues('newPet')?.name ?? form.getValues('pet')?.name}
              </p>
            </div>

            <div className='flex flex-row items-center gap-1'>
              <Avatar src={undefined} sx={{ width: 24, height: 24 }}>
                {form.getValues('employee')?.full_name?.[0]}
              </Avatar>
              <p>{form.getValues('employee')?.full_name ?? 'No preference'}</p>
            </div>
          </StyledSplitPills>
        </div>
      </div>

      <div className='rounded-lg bg-white p-2'>
        <RichTextEditor
          value={form.getValues('appointment.booking_notes')}
          onChange={(value) => {
            form.setValue('appointment.booking_notes', value);
          }}
          placeholder='Internal Booking Notes - e.g. "Pet is aggressive, please be careful"'
          sx={{ height: 200, overflow: 'auto' }}
        />
      </div>

      {watchNotificationReason && (
        <AppointmentNotification
          reason={watchNotificationReason ?? undefined}
          notifyUser={watchNotifyUser ?? false}
          onNotifyUserChanged={(value) => {
            setNotifyUserChangedManually(true);
            form.setValue('notifyUser', value);
          }}
        />
      )}

      <div className='flex flex-row items-center justify-between gap-1 rounded-lg bg-white p-2'>
        <p>Appointment Status</p>

        <StatusPillDropdown
          status={watchAppointmentStatus}
          type='appointment'
          setStatus={(newStatus) => {
            form.setValue('appointment.status', newStatus);
          }}
        />
      </div>

      <div className='flex flex-row items-center justify-end gap-1'>
        <Button
          variant='outline'
          onClick={() => {
            setActiveStep(0);
          }}
        >
          Back
        </Button>

        <LoadingButton
          color='primary'
          loading={form.formState.isSubmitting}
          variant='default'
          onClick={onConfirm}
          data-testid='button-confirm-appointment'
        >
          Confirm
        </LoadingButton>
      </div>
    </div>
  );
}
