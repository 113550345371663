import NumberInput from '@lupa/ui/components/form/NumberInput';
import NumberWithOptionsInput from '@lupa/ui/components/form/NumberWithOptionsInput';
import { Button } from '@lupa/ui/components/shadcn/button';
import { FormField } from '@lupa/ui/components/shadcn/form';
import { TableCell, TableRow } from '@lupa/ui/components/shadcn/table';

import { IconPlus, IconTrash } from '@tabler/icons-react';

import { format } from 'date-fns';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import SearchProductBatches from '../search/SearchProductBatches';
import type { UpsertBillingItemsFormType } from './UpsertBillingItemsForm';
import billingItemsDataUtils from './upsertBillingItemsFormUtils';

type ProductField = UseFieldArrayReturn<
  UpsertBillingItemsFormType,
  'products'
>['fields'][number];

interface UpsertBillingProductBatchesFormProps {
  form: UseFormReturn<UpsertBillingItemsFormType>;
  product: {
    type: 'product';
    idx: number;
    data: ProductField;
  };
}

export default function UpsertBillingProductBatchesForm({
  form,
  product,
}: UpsertBillingProductBatchesFormProps) {
  if (product.data.batches == null) {
    return null;
  }

  const batches = form.watch(`products.${product.idx}.batches`) ?? [];

  return (
    <TableRow className='!border-t-2 border-t-white'>
      <TableCell colSpan={12} className='px-8'>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-row items-center justify-between'>
            <p className='text-muted-foreground font-semibold'>Batches</p>
            <Button
              variant='outline'
              size='sm'
              onClick={() => {
                billingItemsDataUtils.onProductAddBatch({
                  form,
                  productIdx: product.idx,
                  productId: product.data.id,
                });
              }}
            >
              <IconPlus />
              Add Batch
            </Button>
          </div>

          {batches.map((batch, idx) => (
            <div key={`${batch.id}-${idx}`} className='flex flex-row gap-2'>
              <FormField
                control={form.control}
                name={
                  `products.${product.idx}.batches.${idx}.batch_number` as const
                }
                render={({ field: { ref: _ref, ...fieldProps } }) => {
                  return (
                    <SearchProductBatches
                      {...fieldProps}
                      value={
                        fieldProps.value
                          ? {
                              id: fieldProps.value,
                              label: fieldProps.value,
                            }
                          : null
                      }
                      productId={product.data.product_id ?? ''}
                      onChange={(item) => {
                        billingItemsDataUtils.onProductBatchSelected({
                          form,
                          productIdx: product.idx,
                          batchIdx: idx,
                          productId: product.data.product_id,
                          item,
                        });
                      }}
                      required={true}
                      error={
                        form.formState.errors.products?.[product.idx]
                          ?.batches?.[idx]?.batch_number?.message
                      }
                      className='w-[180px]'
                      helperText={
                        batch.expiry_date
                          ? `Expiry date: ${format(
                              new Date(batch.expiry_date),
                              'dd/MM/yyyy',
                            )}`
                          : undefined
                      }
                    />
                  );
                }}
              />

              {form.watch(`products.${product.idx}.has_subunit`) ? (
                <NumberWithOptionsInput
                  value={{
                    amount: form.watch(
                      `products.${product.idx}.batches.${idx}.quantity` as const,
                    ),
                    option: form.watch(
                      `products.${product.idx}.measure_unit` as const,
                    ),
                  }}
                  options={[
                    product.data.unit ?? '',
                    product.data.subunit ?? '',
                  ].map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  onChange={(measureUnit) => {
                    billingItemsDataUtils.onProductBatchQuantityChange({
                      form,
                      productIdx: product.idx,
                      batchIdx: idx,
                      newMeasureUnit: measureUnit.option,
                      newQuantity: measureUnit.amount,
                    });
                  }}
                  onBlur={() => {
                    form.trigger(`products.${product.idx}.measure_unit`);
                  }}
                  error={
                    form.formState.errors.products?.[product.idx]?.measure_unit
                      ?.message
                  }
                  required={true}
                />
              ) : (
                <FormField
                  control={form.control}
                  name={`products.${product.idx}.quantity` as const}
                  render={({ field: { ref: _ref, ...fieldProps } }) => {
                    return (
                      <NumberInput
                        {...fieldProps}
                        required={true}
                        afterValueChange={(newQuantity) => {
                          billingItemsDataUtils.onProductBatchQuantityChange({
                            form,
                            productIdx: product.idx,
                            batchIdx: idx,
                            newMeasureUnit: undefined,
                            newQuantity,
                          });
                        }}
                        error={
                          form.formState.errors.products?.[product.idx]
                            ?.quantity?.message
                        }
                        className='w-[120px]'
                      />
                    );
                  }}
                />
              )}

              <Button
                variant='destructiveGhost'
                size='icon'
                onClick={() => {
                  billingItemsDataUtils.onProductRemoveBatch({
                    form,
                    productIdx: product.idx,
                    batchIdx: idx,
                  });
                }}
              >
                <IconTrash />
              </Button>
            </div>
          ))}
        </div>
      </TableCell>
    </TableRow>
  );
}
