import { isAfter, subMonths, subYears } from 'date-fns';
import { z } from 'zod';

import { BillingItem } from './billingUtils';
import {
  DISCOUNT_TYPE,
  HEALTH_PLAN_ALLOWANCE_ITEM_TYPE,
  HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE,
  HEALTH_PLAN_ALLOWANCE_STATUS,
  HEALTH_PLAN_ALLOWANCE_TYPE,
  HEALTH_PLAN_SUBSCRIBER_TYPE,
} from './enums';
import {
  AllowanceUsageDataType,
  BillingProductValidationSchemaType,
  BillingServiceValidationSchemaType,
} from './validators/invoiceValidators';

export const ALLOWANCES_UNLIMITED_QUANTITY = 9999999999999;

export const BillableItemDetailSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type BillableItemDetail = z.infer<typeof BillableItemDetailSchema>;

export type RemainingAllowanceQuantityDataType = {
  name: string | null;
  allowance_id: string;
  health_plan_id: string;
  subscription_id: string;
  subscriber_type: string;
  config: {
    value: {
      type: HEALTH_PLAN_ALLOWANCE_TYPE;
      value: number;
    };
    limitPerPeriod: number;
    period: HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE;
  };
  item: string | null;
  service: BillableItemDetail | null;
  product: BillableItemDetail | null;
  item_type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE;
  remainingQuantity: number;
};

export const isWithinPeriod = (
  date: string,
  period: HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE,
): boolean => {
  const currentDate = new Date();
  const usageDate = new Date(date);
  switch (period) {
    case HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE.MONTHLY:
      return isAfter(usageDate, subMonths(currentDate, 1));
    case HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE.ANNUALLY:
      return isAfter(usageDate, subYears(currentDate, 1));
    case HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE.LIFETIME:
      return true;
    default:
      return true; // For other period types, assume it's within the period
  }
};

type Subscription = {
  id: string;
  subscriber_type: HEALTH_PLAN_SUBSCRIBER_TYPE;
  allowance_usages: {
    allowance_id: string;
    quantity: number;
    usage_date: string;
  }[];
  health_plan: {
    allowances: {
      id: string;
      name: string | null;
      health_plan_id: string;
      item: string | null;
      item_type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE;
      service: {
        id: string;
        name: string;
      } | null;
      product: {
        id: string;
        name: string;
      } | null;
      allowance_status: HEALTH_PLAN_ALLOWANCE_STATUS;
      config: {
        value: {
          type: HEALTH_PLAN_ALLOWANCE_TYPE;
          value: number;
        };
        limitPerPeriod: number;
        period: HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE;
      };
    }[];
  };
};

export function calculateAvailableAllowances({
  clientSubscriptions,
  petSubscriptions,
}: {
  clientSubscriptions: Subscription[];
  petSubscriptions: Subscription[];
}): {
  productAllowances: Record<string, RemainingAllowanceQuantityDataType[]>;
  serviceAllowances: Record<string, RemainingAllowanceQuantityDataType[]>;
} {
  const allSubscriptions = [
    ...(petSubscriptions ?? []),
    ...(clientSubscriptions ?? []),
  ];

  const allowanceUsageMap: Record<string, number> = {};

  allSubscriptions.forEach((subscription) => {
    subscription.allowance_usages.forEach((usage) => {
      const { allowance_id, quantity, usage_date } = usage;
      const allowance = subscription.health_plan?.allowances.find(
        (a) => a.id === allowance_id,
      );

      if (allowance && isWithinPeriod(usage_date, allowance.config.period)) {
        allowanceUsageMap[allowance_id] =
          (allowanceUsageMap[allowance_id] || 0) + quantity;
      }
    });
  });

  const allowanceProductTypeMap: Record<
    string,
    RemainingAllowanceQuantityDataType[]
  > = {};
  const allowanceServiceTypeMap: Record<
    string,
    RemainingAllowanceQuantityDataType[]
  > = {};

  allSubscriptions.forEach((subscription) => {
    if (subscription.health_plan) {
      const activeAllowances = subscription.health_plan.allowances.filter(
        (allowance) =>
          allowance.allowance_status === HEALTH_PLAN_ALLOWANCE_STATUS.ACTIVE,
      );

      activeAllowances.forEach((allowance) => {
        const usedQuantity = allowanceUsageMap[allowance.id] || 0;
        const remainingAllowance: RemainingAllowanceQuantityDataType = {
          name: allowance.name,
          allowance_id: allowance.id,
          health_plan_id: allowance.health_plan_id,
          subscription_id: subscription.id,
          subscriber_type: subscription.subscriber_type,
          config: allowance.config,
          item: allowance.item,
          service: allowance.service,
          product: allowance.product,
          item_type: allowance.item_type,
          remainingQuantity:
            allowance.config.limitPerPeriod === ALLOWANCES_UNLIMITED_QUANTITY
              ? allowance.config.limitPerPeriod
              : Math.max(0, allowance.config.limitPerPeriod - usedQuantity),
        };
        if (
          allowance.item_type ===
            HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_PRODUCT &&
          allowance.product != null
        ) {
          allowanceProductTypeMap[allowance.product.id] =
            allowanceProductTypeMap[allowance.product.id] || [];
          allowanceProductTypeMap[allowance.product.id].push(
            remainingAllowance,
          );
        } else if (
          allowance.item_type ===
            HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.PRODUCT_CATEGORY &&
          allowance.item != null
        ) {
          allowanceProductTypeMap[allowance.item] =
            allowanceProductTypeMap[allowance.item] || [];
          allowanceProductTypeMap[allowance.item].push(remainingAllowance);
        } else if (
          allowance.item_type ===
            HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_SERVICE &&
          allowance.service != null
        ) {
          allowanceServiceTypeMap[allowance.service.id] =
            allowanceServiceTypeMap[allowance.service.id] || [];
          allowanceServiceTypeMap[allowance.service.id].push(
            remainingAllowance,
          );
        } else if (
          allowance.item_type ===
            HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.SERVICE_CATEGORY &&
          allowance.item != null
        ) {
          allowanceServiceTypeMap[allowance.item] =
            allowanceServiceTypeMap[allowance.item] || [];
          allowanceServiceTypeMap[allowance.item].push(remainingAllowance);
        }
      });
    }
  });

  return {
    productAllowances: allowanceProductTypeMap,
    serviceAllowances: allowanceServiceTypeMap,
  };
}

export const calculateAllowanceDiscount = (
  product: BillingProductValidationSchemaType,
  allowanceUsed: AllowanceUsageDataType | null,
) => {
  if (!allowanceUsed) {
    return {
      allowance_usage: [],
      discount: 0,
      discount_type: DISCOUNT_TYPE.PERCENTAGE,
    };
  }

  const discount = getAllowanceDiscountForProduct({
    allowanceUsage: allowanceUsed,
    billingProduct: product,
  });

  return {
    allowance_usage: [allowanceUsed],
    discount: discount.value,
    discount_type: discount.type,
  };
};

export const getAllowanceDiscountForProduct = ({
  allowanceUsage,
  billingProduct,
}: {
  allowanceUsage: AllowanceUsageDataType;
  billingProduct: BillingProductValidationSchemaType;
}): {
  value: number;
  type: DISCOUNT_TYPE;
} => {
  if (!allowanceUsage.allowance) {
    return { value: 0, type: DISCOUNT_TYPE.PERCENTAGE };
  }

  // Calculate if the dispensing fee should be included in the discount.
  // This only happens when the billing-product quantity is covered by the allowance.
  // Example1: 2 units of product, 1 covered by allowance, dispensing fee is needed to ship 1 product.
  // Example2: 2 units of product, both covered by allowance, no dispensing fee as everything is free.
  const shouldIncludeDispensingFee =
    billingProduct.quantity <= allowanceUsage.quantity;

  const dispensingFeeAmount =
    shouldIncludeDispensingFee && billingProduct.dispensing_fee != null
      ? billingProduct.dispensing_fee
      : 0;

  const { type: allowanceType, value: allowanceDiscountAmount } =
    allowanceUsage.allowance.config.value;

  switch (allowanceType) {
    case HEALTH_PLAN_ALLOWANCE_TYPE.PERCENTAGE: {
      // For percentage discounts:
      // 1. Calculate total cost (allowance-quantity * unit price + dispensing-fee)
      // 2. Apply the percentage discount
      const totalDiscountableAmount =
        allowanceUsage.quantity * billingProduct.unit_price +
        dispensingFeeAmount;
      return {
        value: (totalDiscountableAmount / 100.0) * allowanceDiscountAmount,
        type: DISCOUNT_TYPE.TOTAL,
      };
    }

    case HEALTH_PLAN_ALLOWANCE_TYPE.FIXED_AMOUNT:
      return {
        value: allowanceDiscountAmount * allowanceUsage.quantity,
        type: DISCOUNT_TYPE.TOTAL,
      };

    default:
      return { value: 0, type: DISCOUNT_TYPE.PERCENTAGE };
  }
};

export const getAllowanceDiscountForService = ({
  allowanceUsage,
  billingService,
}: {
  allowanceUsage: AllowanceUsageDataType;
  billingService: BillingServiceValidationSchemaType;
}) => {
  if (!allowanceUsage.allowance) {
    return { value: 0, type: DISCOUNT_TYPE.PERCENTAGE };
  }

  const { type: allowanceType, value: allowanceDiscountAmount } =
    allowanceUsage.allowance.config.value;

  switch (allowanceType) {
    case HEALTH_PLAN_ALLOWANCE_TYPE.PERCENTAGE: {
      // For percentage discounts:
      // 1. Calculate total cost (allowance-quantity * unit price + dispensing-fee)
      // 2. Apply the percentage discount
      const totalDiscountableAmount =
        allowanceUsage.quantity * billingService.unit_price;
      return {
        value: (totalDiscountableAmount / 100.0) * allowanceDiscountAmount,
        type: DISCOUNT_TYPE.TOTAL,
      };
    }

    case HEALTH_PLAN_ALLOWANCE_TYPE.FIXED_AMOUNT:
      return {
        value: allowanceDiscountAmount * allowanceUsage.quantity,
        type: DISCOUNT_TYPE.TOTAL,
      };

    default:
      return { value: 0, type: DISCOUNT_TYPE.PERCENTAGE };
  }
};

export const hasValidAllowanceUsage = ({
  billingItem,
  isHealthPlanEnabled = false,
}: {
  billingItem: BillingItem;
  isHealthPlanEnabled?: boolean;
}) => {
  return (
    isHealthPlanEnabled && billingItem.allowance_usage?.[0]?.allowance != null
  );
};

export const getAvailableAllowancesForItem = ({
  itemId,
  itemCategory,
  availableAllowances,
}: {
  itemId: Nullish<string>;
  itemCategory: Nullish<string>;
  availableAllowances: Record<string, RemainingAllowanceQuantityDataType[]>;
}) => {
  const allowances: RemainingAllowanceQuantityDataType[] = [];

  for (const key in availableAllowances) {
    if (key === itemId || key === itemCategory) {
      allowances.push(...(availableAllowances[key] || []));
    }
  }

  return allowances;
};

export const getHealthPlanAllowanceItemTypeLabel = (
  type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE,
) => {
  switch (type) {
    case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.PRODUCT_CATEGORY:
      return 'Product Category';
    case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.SERVICE_CATEGORY:
      return 'Service Category';
    case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_PRODUCT:
      return 'Individual Product';
    case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_SERVICE:
      return 'Individual Service';
    default:
      return type;
  }
};
