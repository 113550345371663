import { type ReactNode, useState } from 'react';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@lupa/ui/components/shadcn/alert-dialog';

import { createCallable } from 'react-call';

import LoadingButton from './LoadingButton';

export interface AreYouSureDialogProps {
  mutation: () => Promise<void>;
  title?: ReactNode;
  description?: ReactNode;
  destructive?: boolean;
}

export const AreYouSureDialog = createCallable<AreYouSureDialogProps, boolean>(
  ({ call, mutation, title, description, destructive = true }) => {
    const [loading, setLoading] = useState(false);

    return (
      <AlertDialog
        open={!call.ended}
        onOpenChange={(o) => {
          call.end(!o);
        }}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{title}</AlertDialogTitle>
            <AlertDialogDescription>{description}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <LoadingButton
              loading={loading}
              onClick={async () => {
                setLoading(true);
                await mutation();
                setLoading(false);
                call.end(true);
              }}
              variant={destructive ? 'destructive' : 'default'}
            >
              Continue
            </LoadingButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  },
);
