import { DISCOUNT_TYPE } from '@lupa/utils/enums';
import {
  BillingProductValidationSchemaType,
  BillingServiceValidationSchemaType,
} from '@lupa/utils/validators/invoiceValidators';

import { P, match } from 'ts-pattern';

export type BillingItem =
  | BillingProductValidationSchemaType
  | BillingServiceValidationSchemaType;

const roundNumber = (number: number, digits = 2): number =>
  Math.round(number * 10 ** digits) / 10 ** digits;

const getItemDiscount = (
  itemNetPrice: number,
  discount?: number,
  discountType?: DISCOUNT_TYPE,
): number => {
  if (!discount || !discountType) {
    return 0;
  }

  return discountType === DISCOUNT_TYPE.PERCENTAGE
    ? itemNetPrice * (discount / 100)
    : discount;
};

export const getItemsWeightedVat = (
  items: Array<Pick<BillingItem, 'vat_percentage' | 'price'>>,
): number => {
  let billingItemsVatAmount = 0;
  let billingItemsTotalPreTaxPrice = 0;

  items.forEach((item) => {
    const vatRate = item.vat_percentage / 100;
    const preTaxPrice = item.price / (1 + vatRate);
    const vatAmount = item.price - preTaxPrice;

    billingItemsTotalPreTaxPrice += preTaxPrice;
    billingItemsVatAmount += vatAmount;
  });

  return billingItemsVatAmount / billingItemsTotalPreTaxPrice;
};

export const getBillingInfoFromItem = (
  item: Pick<
    BillingItem & { dispensing_fee?: number },
    'unit_price' | 'quantity' | 'discount' | 'discount_type' | 'dispensing_fee'
  >,
) => {
  const itemUnitPricePostTax = item.unit_price;
  const itemPricePostTax = itemUnitPricePostTax * item.quantity;

  const itemFees = match(item)
    .with({ dispensing_fee: P.number }, (item) => item.dispensing_fee)
    .otherwise(() => 0);

  const preDiscountTotalPrice = itemPricePostTax + itemFees;

  const itemDiscount = getItemDiscount(
    preDiscountTotalPrice,
    item.discount,
    item.discount_type,
  );

  const itemPricePostDiscount = itemPricePostTax - itemDiscount;

  const itemTotalPrice = roundNumber(itemPricePostDiscount + itemFees);

  return {
    itemUnitPricePostTax,
    preDiscountTotalPrice,
    itemDiscount,
    itemTotalPrice,
  };
};

export const getBillingInfoFromItems = ({
  items,
  hasGlobalDiscount = false,
  globalDiscount = 0,
  globalDiscountType = DISCOUNT_TYPE.TOTAL,
}: {
  items: Array<
    Pick<
      BillingItem & { dispensing_fee?: number },
      | 'unit_price'
      | 'quantity'
      | 'discount'
      | 'discount_type'
      | 'dispensing_fee'
      | 'vat_percentage'
      | 'price'
    >
  >;
  hasGlobalDiscount?: boolean | null;
  globalDiscount?: number;
  globalDiscountType?: DISCOUNT_TYPE;
}) => {
  let totalNetPreDiscount = 0;
  let totalDiscount = 0;
  let totalPrice = 0;

  items.forEach((item) => {
    const { itemDiscount, itemTotalPrice, preDiscountTotalPrice } =
      getBillingInfoFromItem(item);
    totalDiscount += itemDiscount;
    totalPrice += itemTotalPrice;
    totalNetPreDiscount += preDiscountTotalPrice;
  });

  if (hasGlobalDiscount && globalDiscount) {
    const globalDiscountAmount =
      globalDiscountType === DISCOUNT_TYPE.PERCENTAGE
        ? totalPrice * (globalDiscount / 100)
        : globalDiscount;

    totalDiscount += globalDiscountAmount;
    totalPrice -= globalDiscountAmount;
  }

  const weightedVatPreGlobalDiscount = getItemsWeightedVat(items);
  const totalGross = totalPrice / (1 + weightedVatPreGlobalDiscount);

  const totalVat = totalPrice - totalGross;

  return {
    totalNetPreDiscount,
    totalDiscount,
    totalGross,
    totalVat,
    totalPrice,
  };
};

export const getNewUnitPriceFromFinalPrice = (
  finalPrice: number,
  item: BillingItem,
) => {
  const itemFees = match(item)
    .with({ dispensing_fee: P.number }, (item) => item.dispensing_fee)
    .otherwise(() => 0);

  let discountAmount: number;

  if (!item.discount || !item.discount_type) {
    discountAmount = 0;
  } else if (item.discount_type === DISCOUNT_TYPE.TOTAL) {
    discountAmount = item.discount;
  } else if (item.discount_type === DISCOUNT_TYPE.PERCENTAGE) {
    discountAmount = finalPrice / ((100 - item.discount) / 100) - finalPrice;
  } else {
    throw new Error('Unsupported discount type.');
  }

  const finalPriceWithoutFees = finalPrice - itemFees;

  const finalPricesPreDiscount = finalPriceWithoutFees + discountAmount;

  return finalPricesPreDiscount / item.quantity;
};

export const getItemPriceDetails = ({
  item,
}: {
  item: { price: number; vat_percentage: number; discount_percentage: number };
}): {
  vatAmount: number;
  preTaxPrice: number;
} => {
  const priceAfterDiscount = item.price * (1 - item.discount_percentage / 100);
  const vatRate = item.vat_percentage / 100;
  const preTaxPrice = priceAfterDiscount / (1 + vatRate);
  const vatAmount = priceAfterDiscount - preTaxPrice;

  return { vatAmount, preTaxPrice };
};
