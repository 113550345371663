import z from 'zod';

import {
  ORDER_ITEM_STATUS,
  PRODUCTS_SUPPLIER,
  PRODUCT_CATEGORY_TYPE,
  PRODUCT_MEDICAL_CATEGORY_TYPE,
} from '../enums';
import { zTimestampz } from './utils';

const baseProductValidatorData = z.object({
  category: z.nativeEnum(PRODUCT_CATEGORY_TYPE, {
    message: 'Category is required',
  }),
  medical_category: z
    .nativeEnum(PRODUCT_MEDICAL_CATEGORY_TYPE, {
      message: 'Medical Category is required',
    })
    .nullable(),
  name: z
    .string({
      message: 'Name is required',
    })
    .max(255, { message: 'Name must be shorter than 255 characters' })
    .min(1, 'Name is required'),
  item_code: z
    .string()
    .max(255, { message: 'Item code must be shorter than 255 characters' })
    .nullable(),
  description: z
    .string()
    .max(5000, {
      message: 'Description must be shorter than 5000 characters',
    })
    .nullable(),

  supplier: z.string().nullable(),
  supplier_product: z
    .object({
      id: z.string(),
      code: z.string(),
      description: z.string(),
    })
    .nullable(),

  has_subunit: z.boolean().nullable(),
  unit: z.string().nullable(),
  subunit_multiplier: z.number().nullable(),
  subunit: z.string().nullable(),

  has_batches: z.boolean(),
  quantity: z.number({
    message: 'Quantity is required',
  }),
  measure_unit: z.string().nullable(),
  batches: z
    .array(
      z.object({
        id: z.string().uuid(),
        batch_number: z.string().max(255),
        expiry_date: zTimestampz().nullable(),
        quantity: z
          .number({
            message: 'Quantity must be at least 1',
          })
          .min(1, 'Quantity must be at least 1'),
      }),
    )
    .nullable(),

  price: z
    .number({
      message: 'Price must be a positive number',
    })
    .min(0, 'Price must be a positive number'),
  vat_code: z.string({
    message: 'VAT Code is required',
  }),
  procurement_cost: z
    .number()
    .min(0, 'Procurement cost must be a positive number'),
  vat_percentage: z
    .number({ message: 'VAT must be a positive number' })
    .min(0, 'VAT percentage must be a positive number'),
  margin: z.number().min(-100, 'Margin must be more than -100').nullable(),
  has_advanced_discounts: z.boolean(),
  discount_1: z.number().min(-100),
  discount_2: z.number().min(-100),
  discount_3: z.number().min(-100),

  has_fees: z.boolean().nullable(),
  dispensing_fee: z.number().min(0),

  minimum_stock_level: z.number().min(0),
  optimal_stock_level: z.number().min(0),

  qr_code_link: z.string().url().nullish(),
});

export const insertProductValidatorData = baseProductValidatorData.superRefine(
  (val, ctx) => {
    if (val.has_subunit) {
      if (!val.unit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Unit is required when "Has Subunits" is selected',
          path: ['unit'],
        });
      }
      if (!val.subunit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Subunit is required when "Has Subunits" is selected',
          path: ['subunit'],
        });
      }
      if (!val.subunit_multiplier || val.subunit_multiplier < 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Subunit multiplier must be greater than or equal to 1 when "Has Subunits" is selected',
          path: ['subunit_multiplier'],
        });
      }
      if (!val.measure_unit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Measure unit is required when "Has Subunits" is selected',
          path: ['measure_unit'],
        });
      }
    } else {
      if (val.subunit_multiplier) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Subunit multiplier must be empty when "Has Subunits" is not selected',
          path: ['has_subunit'],
        });
      }

      if (val.measure_unit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Measure unit must be empty when "Has Subunits" is not selected',
          path: ['has_subunit'],
        });
      }

      if (val.subunit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Subunit must be empty when "Has Subunits" is not selected',
          path: ['has_subunit'],
        });
      }

      if (val.unit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Unit must be empty when "Has Subunits" is not selected',
          path: ['has_subunit'],
        });
      }
    }

    if (!val.has_batches) {
      if (val.batches && val.batches.length > 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Batches must be empty when "Has Batches" is not selected',
          path: ['has_batches'],
        });
      }
    } else {
      if (!val.batches || val.batches.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'At least one batch is required when "Has Batches" is selected',
          path: ['batches'],
        });
      }
    }

    if (val.measure_unit) {
      if (
        val.has_subunit &&
        val.measure_unit !== val.unit &&
        val.measure_unit !== val.subunit
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Measure unit must be the same as the product unit or subunit',
          path: ['measure_unit'],
        });
      } else if (!val.has_subunit && val.measure_unit !== val.unit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Measure unit must be the same as the product unit',
          path: ['measure_unit'],
        });
      }
    } else {
      if (val.unit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Unit must be empty when "Has Subunits" is not selected',
          path: ['has_subunit'],
        });
      }

      if (val.subunit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Subunit must be empty when "Has Subunits" is not selected',
          path: ['has_subunit'],
        });
      }
    }

    if (val.has_fees) {
      if (val.dispensing_fee == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Dispensing fee is required when "Has Fees" is selected',
          path: ['dispensing_fee'],
        });
      }
    } else {
      if (val.dispensing_fee != null && val.dispensing_fee !== 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Dispensing fee must be empty when "Has Fees" is not selected',
          path: ['has_fees'],
        });
      }
    }
  },
);

export type InsertProductValidatorDataType = z.infer<
  typeof insertProductValidatorData
>;

export const updateProductValidatorData = baseProductValidatorData.partial();

export type UpdateProductValidatorDataType = z.infer<
  typeof updateProductValidatorData
>;

export const placeProductOrderValidatorData = z.object({
  supplier: z.nativeEnum(PRODUCTS_SUPPLIER),
  products: z
    .array(
      z.object({
        id: z.string(),
        code: z.string(),
        cost: z.number(),
        quantity: z.number().min(1, 'Quantity must be at least 1'),
      }),
    )
    .min(1, 'At least one product is required'),
});

export type PlaceProductOrderValidatorDataType = z.infer<
  typeof placeProductOrderValidatorData
>;

export const processProductOrderValidatorData = z.object({
  description: z.string().min(1, 'Required'),
  status: z.nativeEnum(ORDER_ITEM_STATUS),
  product: z.object({
    id: z.string().min(1, 'Required'),
    name: z.string().min(1, 'Required'),
  }),
  quantity: z.number(),
  batches: z.array(
    z.object({
      number: z.string(),
      expiry_date: z.string().nullable(),
      quantity: z.number(),
    }),
  ),
});

export type ProcessProductOrderValidatorDataType = z.infer<
  typeof processProductOrderValidatorData
>;
