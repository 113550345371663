import { Label } from '@lupa/ui/components/shadcn/label';

import { HintTooltip } from '../HintTooltip';

interface FormLabelProps {
  label?: string;
  required?: boolean;
  hint?: string;
  htmlFor?: string;
}

export default function FormLabel({
  label,
  required,
  hint,
  htmlFor,
}: FormLabelProps) {
  if (label === undefined) {
    return null;
  }

  return (
    <div className='flex items-center'>
      <Label htmlFor={htmlFor}>
        {label} {required && <span className='text-destructive'>*</span>} {}
      </Label>

      {hint && <HintTooltip hint={hint} />}
    </div>
  );
}
